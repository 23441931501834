import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
});

export default function DotsMobileStepper(props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div>
    <h3 style={{background: "#fafafa", margin: 0, padding: "10px 0px", textAlign: "center"}}>{props.activeStep < props.patient.FormData.filter((e) => e.active).length ? props.patient.FormData.filter((e) => e.active)[props.activeStep].name : null}</h3>
    <MobileStepper
      variant="dots"
      steps={props.patient.FormData.filter((e) => e.active).length}
      position="static"
      activeStep={props.activeStep}
      className={classes.root}
      nextButton={
        <Button size="small" onClick={props.handleNext} disabled={props.activeStep === props.patient.FormData.filter((e) => e.active).length}>
          Next
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </Button>
      }
      backButton={
        <Button size="small" onClick={props.handleBack} disabled={props.activeStep === 0}>
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          Back
        </Button>
      }
    />
    </div>
  );
}
