import React, {Component} from 'react';
import '../App.css';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Icon from '@mdi/react'
import { mdiDelete, mdiPlus } from '@mdi/js';
import {romanize} from "../utils.js"

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    marginTop: "16px",
    marginBottom: "8px"
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
    },
  },
  fullHeightCard: {
		height: "100%",
	},
  halfWidth: {
    width: "50%"
  },
  quartWidth:{
    width: "25%"
  },
  threeQuartWidth:{
    width: "75%"
  },
  commentWidth:{
    marginTop: "8px",
    width: "calc(100% - 69px)"
  },
  delButton:{
    marginTop: "8px",
    marginRight: "4px",
    height: "40px"
  },
  otherBox:{
    marginTop: "5px",
    marginBottom: "8px",
    width: "calc(100% - 50px)",
    marginLeft: "0px"
  }
}));

function CoughAssistInfo(props) {
  const classes = useStyles();

  return (
    <div style={{padding: "20px"}}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card className={classes.fullHeightCard}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Cough Assist Information
              </Typography>


              <Grid container spacing={3}>
                <Grid item xs={6}>
              <TextField fullWidth margin="normal" variant="outlined" label="Inspiratory Duration" name="inspDuration" error={props.checkFieldValidity(9, "inspDuration").valid && props.highestStep > 9}
              value={props.patient.FormData[9].requiredFields.inspDuration} onChange={(e) => props.modifyForm(9, e)} />
              <TextField fullWidth margin="normal" variant="outlined" label="Inspiratory Pressure" name="inspPressure" error={props.checkFieldValidity(9, "inspPressure").valid && props.highestStep > 9}
              value={props.patient.FormData[9].requiredFields.inspPressure} onChange={(e) => props.modifyForm(9, e)} />
              <TextField fullWidth margin="normal" variant="outlined" label="Expiratory Duration" name="expDuration" error={props.checkFieldValidity(9, "expDuration").valid && props.highestStep > 9}
              value={props.patient.FormData[9].requiredFields.expDuration} onChange={(e) => props.modifyForm(9, e)} />
              <TextField fullWidth margin="normal" variant="outlined" label="Expiratory Pressure" name="expPressure" error={props.checkFieldValidity(9, "expPressure").valid && props.highestStep > 9}
              value={props.patient.FormData[9].requiredFields.expPressure} onChange={(e) => props.modifyForm(9, e)} />

              </Grid>
              <Grid item xs={6}>

              <TextField fullWidth margin="normal" variant="outlined" label="Pause Duration" name="pauseDuration" error={props.checkFieldValidity(9, "pauseDuration").valid && props.highestStep > 9}
              value={props.patient.FormData[9].requiredFields.pauseDuration} onChange={(e) => props.modifyForm(9, e)} />
              <TextField fullWidth margin="normal" variant="outlined" label="Usage Frequency" name="usageFrequency" value={props.patient.FormData[9].requiredFields.usageFrequency} onChange={(e) => props.modifyForm(9, e)} />

              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel>Cycle Breath Count</InputLabel>
                <Select name="cycleBreathCount" value={props.patient.FormData[9].requiredFields.cycleBreathCount} onChange={(e) => props.modifyForm(9, e)} label="Cycle Breath Count" >
                  {[1,2,3,4,5,6,7,8,9,10].map((e, i) => <MenuItem value={e}>{e}</MenuItem>)}
                </Select>
              </FormControl>
              <br/>
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel>Cycle Quantity</InputLabel>
                <Select name="cycleQuantity" value={props.patient.FormData[9].requiredFields.cycleQuantity} onChange={(e) => props.modifyForm(9, e)} label="Cycle Quantity" >
                  {[1,2,3,4,5,6,7,8,9,10].map((e, i) => <MenuItem value={e}>{e}</MenuItem>)}
                </Select>
              </FormControl>
              </Grid>
              </Grid>

              <Typography color="textSecondary" gutterBottom>
                Justification
              </Typography>

              <Grid container spacing={1}>
                {Object.entries(props.patient.FormData[9].requiredFields.justifications).map((e, i) => <Grid item xs={12}>
                {e[0] != "Other" ?
                <FormControlLabel control={
                  <Checkbox name={e} color="primary" checked={props.patient.FormData[9].requiredFields.justifications[i]} onClick={() => {
                    var just = props.patient.FormData[9].requiredFields.justifications;
                    just[e[0]] = !just[e[0]];
                    props.modifyForm(9, just, "justifications")
                  }} />} label={e}/>
                :
                <FormControlLabel control={
                  <div>
                  <Checkbox name={e} color="primary" checked={props.patient.FormData[9].requiredFields.justifications[e[0]] != ""} />
                  <TextField className={classes.otherBox} margin="normal" label="" placeholder="Other" name="otherEquipment" className={classes.otherBox} value={props.patient.FormData[9].requiredFields.justifications[i]} onChange={(f) => {
                    var just = props.patient.FormData[9].requiredFields.justifications;
                    just[e[0]] = f.target.value;
                    props.modifyForm(9, just, "justifications")}}/>
                    </div>} label=""/>
                }
                </Grid>)}
              </Grid>


            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
        <Card className={classes.fullHeightCard}>
        <CardContent style={{position: "relative"}}>
          <Typography color="textSecondary" gutterBottom>
            Comments
          </Typography>
          <Button style={{position: "absolute", right: "17px", top: "10px"}} variant="contained" color="primary" onClick={(e) => props.addRemoveComment(e, 9, "add")}><Icon path={mdiPlus} size={1}/></Button>
          <p className="recommended"><b>Related Equipment & Supply Items may Include: </b>
          <br/>A7010 - 6' CAD Aerosol Tubing
          <br/>A9900/A7015/A7034 - Mask
          <br/>Bacteria/Virus Filter</p>


          {props.patient.FormData[9].requiredFields.comments.map((e, i) =>
            <div style={{width: "100%"}}>
            <Button className={classes.delButton} variant="contained" color="secondary" onClick={(e) => props.addRemoveComment(i, 9, "remove")}><Icon path={mdiDelete} size={1}/></Button>
            <TextField className={classes.commentWidth} multiline rowsMax={4} size="small" margin="normal" variant="outlined" label={"Comment " + romanize(i + 1)} name={i.toString()} value={e} onChange={(e) => props.addRemoveComment(e, 9, "modify")} />
            </div>
          )}

        </CardContent>
        </Card>
        </Grid>



      </Grid>
    </div>
  );

}

export default CoughAssistInfo;
