import React, {Component} from 'react';
import '../App.css';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Icon from '@mdi/react'
import { mdiDelete, mdiPlus } from '@mdi/js';
import {romanize, unCamelCase} from "../utils.js"
import {tubeOrderNumbers, catheterSizes} from '../enums.js'

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      width: '25ch',
    },
  },
  fullHeightCard: {
		height: "100%",
	},
  halfWidth: {
    width: "50%"
  },
  quartWidth:{
    width: "25%"
  },
  threeQuartWidth:{
    width: "75%"
  },
  commentWidth:{
    marginTop: "8px",
    width: "calc(100% - 69px)"
  },
  delButton:{
    marginTop: "8px",
    marginRight: "4px",
    height: "40px"
  }
}));

function TracheostomyInfo(props) {
  const classes = useStyles();

  const fieldFilter = (fieldSet, currentField, tube) => {
    var fieldSetCopy = JSON.parse(JSON.stringify(fieldSet));
    var fieldArr = Object.keys(props.patient.FormData[2].requiredFields.tubes[tube]);
    for(var x = 0; x < fieldArr.length; x++){
      if(fieldArr[x] != currentField && props.patient.FormData[2].requiredFields.tubes[tube][fieldArr[x]] != "" && props.patient.FormData[2].requiredFields.tubes[tube][fieldArr[x]] != "orderNumber"){
        fieldSetCopy = fieldSetCopy.filter((e) => e[fieldArr[x]] == props.patient.FormData[2].requiredFields.tubes[tube][fieldArr[x]])
      }
    }
    fieldSetCopy.unshift({orderNumber: "", application: "", model: "", distal: "", cannula: "", specialLength: "", cuff: "", fenestration: "", flange: "", size: ""})
    return fieldSetCopy
  }

  return (
    <div style={{padding: "20px"}}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card className={classes.fullHeightCard}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Tracheostomy Information
              </Typography>

              <FormControlLabel control={
                <Checkbox color="primary" checked={props.patient.FormData[2].heatedConfig} onClick={(e) => props.modifyForm(2, "checkbox", "heatedConfig")} />} label="Heated Config Needed"/>
              <FormControlLabel control={
                <Checkbox color="primary" checked={props.patient.FormData[2].useMepilex} onClick={(e) => props.modifyForm(2, "checkbox", "useMepilex")} />} label="Use Mepilex"/>

                {props.patient.FormData[2].primaryTubeType == 1 || props.patient.FormData[2].secondaryTubeType == 1 ?
                  <TextField fullWidth margin="normal" variant="outlined" label="Bivona Tubes per Month" name="bivonaTubes" error={props.checkFieldValidity(2, "bivonaTubes").valid && props.highestStep > 2}
                  value={props.patient.FormData[2].requiredFields.bivonaTubes} onChange={(e) => props.modifyForm(2, e)} />
                : null}

                {props.patient.FormData[2].useMepilex ?
                  <TextField fullWidth margin="normal" variant="outlined" label="Meplex per Month" name="meplexQuantity" error={props.checkFieldValidity(2, "meplexQuantity").valid && props.highestStep > 2}
                  value={props.patient.FormData[2].requiredFields.meplexQuantity} onChange={(e) => props.modifyForm(2, e)} />
                : null}

                <FormControl fullWidth variant="outlined" className={classes.formControl}>
                  <InputLabel>Catheter Size</InputLabel>
                  <Select name="catheterSize" error={props.checkFieldValidity(2, "catheterSize").valid && props.highestStep > 2}
                  value={props.patient.FormData[2].requiredFields.catheterSize} onChange={(e) => props.modifyForm(2, e)} label="Catheter Size" >
                    {catheterSizes.map((e) => <MenuItem value={e}>{e}</MenuItem>)}
                  </Select>
                </FormControl>

            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.fullHeightCard}>
            <CardContent style={{position: "relative"}}>
              <Typography color="textSecondary" gutterBottom>
                Comments
              </Typography>
              <Button style={{position: "absolute", right: "17px", top: "10px"}} variant="contained" color="primary" onClick={(e) => props.addRemoveComment(e, 2, "add")}><Icon path={mdiPlus} size={1}/></Button>
              <p className="recommended"><b>Related Equipment & Supply Items may Include: </b>
              <br/>E1399 - Suction Machine Auto Adapter
              <br/>A9900 - Suction Machine Bacteria Filters
              <br/>A4624 - Tracheal Suction Catheters
              <br/>A7002 - Suction Machine Tubing
              <br/>A7000 - Suction Machine Cannister
              <br/>A4628 - Oropharyngeal Suction Catheter (Lil Suckers)</p>

              {props.patient.FormData[2].requiredFields.comments.map((e, i) =>
                <div style={{width: "100%"}}>
                <Button className={classes.delButton} variant="contained" color="secondary" onClick={(e) => props.addRemoveComment(i, 2, "remove")}><Icon path={mdiDelete} size={1}/></Button>
                <TextField className={classes.commentWidth} multiline rowsMax={4} size="small" margin="normal" variant="outlined" label={"Comment " + romanize(i + 1)} name={i.toString()} value={e} onChange={(e) => props.addRemoveComment(e, 2, "modify")} />
                </div>
              )}


            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
        <Card className={classes.fullHeightCard}>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Primary Tube
            </Typography>

            <Grid container spacing={3}>
            <Grid item xs={6}>
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <InputLabel>Tube Type</InputLabel>
              <Select name="primaryTubeType" error={props.checkFieldValidity(2, "primaryTubeType").valid && props.highestStep > 2}
              value={props.patient.FormData[2].requiredFields.primaryTubeType} onChange={(e) => props.modifyTrach(0, e, "primaryTubeType")} label="Tube Type" >
                <MenuItem value={0}>Tracoe</MenuItem>
                <MenuItem value={1}>Bivona</MenuItem>
                <MenuItem value={2}>Shiley</MenuItem>
              </Select>
            </FormControl>
            </Grid>

            {props.patient.FormData[2].requiredFields.primaryTubeType != 0 ? <Grid item xs={6}>
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <InputLabel>Order Number</InputLabel>
              <Select name={"orderNumberPrimary"} error={props.checkFieldValidity(2, "orderNumberPrimary").valid && props.highestStep > 2}
              value={props.patient.FormData[2].requiredFields.orderNumberPrimary} label={"orderNumberPrimary"} onChange={(j) => props.modifyTrach(0, j, "orderNumberPrimary")}>
                {[...new Set(fieldFilter(tubeOrderNumbers[props.patient.FormData[2].requiredFields.primaryTubeType], "orderNumber", 0).map(item => item["orderNumber"]))].map((f) => <MenuItem value={f}>{f}</MenuItem>)}
              </Select>
            </FormControl>
            </Grid> : null}

            {
              Object.keys(props.patient.FormData[2].requiredFields.tubes[0]).map((e, i) => {
                var selectFilter = [...new Set(fieldFilter(tubeOrderNumbers[props.patient.FormData[2].requiredFields.primaryTubeType], e, 0).map(item => item[e]))];
                if(selectFilter.length > 1){
                  return(
                    <Grid item xs={6}>
                    <FormControl fullWidth variant="outlined" className={classes.formControl}>
                      <InputLabel>{unCamelCase(e)}</InputLabel>
                      <Select disabled={selectFilter.length < 2}  name={e} error={false/*props.checkFieldValidity(2, e).valid && props.highestStep > 2*/}
                      value={props.patient.FormData[2].requiredFields.tubes[0][e]} label={e} onChange={(j) => props.modifyTrach(0, j, e)}>
                        {selectFilter.map((f) => <MenuItem value={f}>{f}</MenuItem>)}
                      </Select>
                    </FormControl>
                    </Grid>
                  )
                }
                else{
                  return (null)
                }
              })
            }

            </Grid>

          </CardContent>
        </Card>
        </Grid>

        <Grid item xs={12} md={6}>
        <Card className={classes.fullHeightCard}>
          <CardContent style={{position: "relative"}}>
            <Typography color="textSecondary" gutterBottom>
              Secondary Tube
            </Typography>


            <FormControlLabel style={{position: "absolute", right: "17px", top: "5px"}} control={
              <Checkbox color="primary" checked={props.patient.FormData[2].requiredFields.useSecondary} onClick={(e) => props.modifyForm(2, "checkbox", "useSecondary")} />} label="Enable Backup Tube"/>


            <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
            {props.patient.FormData[2].requiredFields.useSecondary ?
              <div>
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel>Tube Type</InputLabel>
                <Select name="secondaryTubeType" error={props.checkFieldValidity(2, "secondaryTubeType").valid && props.highestStep > 2}
                value={props.patient.FormData[2].requiredFields.secondaryTubeType} onChange={(e) => props.modifyTrach(1, e, "secondaryTubeType")} label="Tube Type" >
                  <MenuItem value={0}>Tracoe</MenuItem>
                  <MenuItem value={1}>Bivona</MenuItem>
                  <MenuItem value={2}>Shiley</MenuItem>
                </Select>
              </FormControl>

              </div> : null}
              </Grid>

              {props.patient.FormData[2].requiredFields.useSecondary && props.patient.FormData[2].requiredFields.secondaryTubeType != 0 ? <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel>Order Number</InputLabel>
                <Select name={"orderNumberSecondary"} error={props.checkFieldValidity(2, "orderNumberSecondary").valid && props.highestStep > 2}
                value={props.patient.FormData[2].requiredFields.orderNumberSecondary} label={"orderNumberSecondary"} onChange={(j) => props.modifyTrach(1, j, "orderNumberSecondary")}>
                  {[...new Set(fieldFilter(tubeOrderNumbers[props.patient.FormData[2].requiredFields.secondaryTubeType], "orderNumber", 1).map(item => item["orderNumber"]))].map((f) => <MenuItem value={f}>{f}</MenuItem>)}
                </Select>
              </FormControl>
              </Grid> : null}

              {
                Object.keys(props.patient.FormData[2].requiredFields.tubes[1]).map((e, i) => {
                  var selectFilter = [...new Set(fieldFilter(tubeOrderNumbers[props.patient.FormData[2].requiredFields.secondaryTubeType], e, 1).map(item => item[e]))];
                  if(selectFilter.length > 1){
                    return(
                      <Grid item xs={6}>
                      <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <InputLabel>{unCamelCase(e)}</InputLabel>
                        <Select disabled={selectFilter.length < 2}  name={e} error={false/*props.checkFieldValidity(2, e).valid && props.highestStep > 2*/}
                        value={props.patient.FormData[2].requiredFields.tubes[1][e]} label={e} onChange={(j) => props.modifyTrach(1, j, e)}>
                          {selectFilter.map((f) => <MenuItem value={f}>{f}</MenuItem>)}
                        </Select>
                      </FormControl>
                      </Grid>
                    )
                  }
                  else{
                    return (null)
                  }
                })
              }
              </Grid>


          </CardContent>
        </Card>
        </Grid>


      </Grid>
    </div>
  );

}

export default TracheostomyInfo;
