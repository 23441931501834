import React, {useEffect} from 'react';
import '../App.css';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker} from '@material-ui/pickers';
import {prognosese, practicionerList, romanize, getAge, clinicianList} from '../utils.js';

const useStyles = makeStyles((theme) => ({
  formControl: {
    //margin: theme.spacing(1),
    marginTop:"8px",
    minWidth: 120,
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  fullHeightCard: {
		height: "100%",
	},
  halfWidth: {
    width: "50%"
  },
  quartWidth:{
    width: "25%"
  },
  threeQuartWidth:{
    width: "75%"
  },
  commentWidth:{
    marginTop: "8px",
    width: "calc(100% - 69px)"
  },
  delButton:{
    marginTop: "8px",
    marginRight: "4px",
    height: "40px"
  },
  otherBox:{
    marginTop: "5px",
    marginBottom: "8px",
    width: "calc(100% - 50px)",
    marginLeft: "-14px"
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function PatientInfo(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  const checkAge = () => {
    var e = {target: {name: "Apnea Monitor Info"}}
    console.log("APNEA")
    if(props.patient.FormData[0].requiredFields.patientAge > 2 && !props.patient.FormData[6].active){
      setOpen(true);
    }
    props.addRemoveEquipment(e)
  }

  useEffect(() => {
    props.modifyForm(0, getAge(props.patient.FormData[0].requiredFields.patientBirth), "patientAge")
  },[props.patient.FormData[0].requiredFields.patientBirth])

  return (
    <div style={{padding: "20px"}}>

    <div className={classes.root}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning">
          Please Note: It is likely that Alabama Medicaid will deny an Apnea Monitor Prior Authorization for a patient 'greater than the age of two' (Three years old or greater) without a Patient-Specific Apnea Monitor Letter of Medical Necessity.
        </Alert>
      </Snackbar>
    </div>

      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Card className={classes.fullHeightCard}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Physician Information
              </Typography>
              <form className={classes.root} noValidate autoComplete="off">

              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                  <InputLabel id="select-outlined-label">Physician</InputLabel>
                  <Select
                    labelId="select-outlined-label"
                    id="select-outlined"
                    value={props.patient.FormData[0].requiredFields.physicianIndex}
                    name="physicianIndex"
                    onChange={(e) => props.modifyPhysician(e)}
                    label="Physician"
                  >
                    {practicionerList.map((e, i) =>
                      <MenuItem key={i} value={i}>{e.name}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              {props.patient.FormData[0].requiredFields.physicianIndex == 0 ?
                <TextField fullWidth margin="normal" name="physicianName" onChange={(e) => props.modifyPhysician(e)}
                error={props.checkFieldValidity(0, "physicianName").valid && props.highestStep > 0}
                variant="outlined" label="Name" value={props.patient.FormData[0].requiredFields.physicianName} /> : null}
              <TextField fullWidth
                id="standard-multiline-flexible"
                multiline
                variant="outlined"
                label="Address"
                rowsMax={4}
                error={props.checkFieldValidity(0, "physicianAddress").valid && props.highestStep > 0}
                disabled={props.patient.FormData[0].requiredFields.physicianIndex != 0}
                name="physicianAddress"
                onChange={(e) => props.modifyPhysician(e)}
                value={props.patient.FormData[0].requiredFields.physicianAddress}
              />
              <TextField fullWidth margin="normal" disabled={props.patient.FormData[0].requiredFields.physicianIndex != 0}onChange={(e) => props.modifyPhysician(e)} name="physicianNPI"
               error={props.checkFieldValidity(0, "physicianNPI").valid && props.highestStep > 0}
               variant="outlined" label="NPI" value={props.patient.FormData[0].requiredFields.physicianNPI} />
              <TextField fullWidth margin="normal" disabled={props.patient.FormData[0].requiredFields.physicianIndex != 0}onChange={(e) => props.modifyPhysician(e)} name="physicianPhone"
               error={props.checkFieldValidity(0, "physicianPhone").valid && props.highestStep > 0}
               variant="outlined" label="Phone" value={props.patient.FormData[0].requiredFields.physicianPhone}/>
              <TextField fullWidth margin="normal" disabled={props.patient.FormData[0].requiredFields.physicianIndex != 0}onChange={(e) => props.modifyPhysician(e)} name="physicianFax"
               variant="outlined" label="Fax" value={props.patient.FormData[0].requiredFields.physicianFax} />
               <FormControl fullWidth variant="outlined" className={classes.formControl}>
                   <InputLabel id="select-outlined-label">Clinician</InputLabel>
                   <Select
                     labelId="select-outlined-label"
                     id="select-outlined"
                     value={props.patient.FormData[0].requiredFields.clinician}
                     name="clinician"
                     onChange={(e) => props.modifyForm(0, e)}
                     label="Clinician"
                   >
                     {clinicianList.map((e, i) =>
                       <MenuItem key={i} value={e}>{e}</MenuItem>
                     )}
                   </Select>
                 </FormControl>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={9}>
        <Card className={classes.fullHeightCard}>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Patient Information
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField className={classes.quartWidth} margin="normal" variant="outlined" label="Suffix" name="patientSuffix" error={props.checkFieldValidity(0, "patientSuffix").valid && props.highestStep > 0}
                value={props.patient.FormData[0].requiredFields.patientSuffix} onChange={(e) => props.modifyForm(0, e)} />
                <TextField className={classes.threeQuartWidth} margin="normal" variant="outlined" label="Last Name" name="patientLastName" error={props.checkFieldValidity(0, "patientLastName").valid && props.highestStep > 0}
                value={props.patient.FormData[0].requiredFields.patientLastName} onChange={(e) => props.modifyForm(0, e)} />
                <TextField className={classes.halfWidth} margin="normal" variant="outlined" label="First Name" name="patientFirstName" error={props.checkFieldValidity(0, "patientFirstName").valid && props.highestStep > 0}
                value={props.patient.FormData[0].requiredFields.patientFirstName} onChange={(e) => props.modifyForm(0, e)}/>
                <TextField className={classes.halfWidth} margin="normal" variant="outlined" label="Middle Name" name="patientMiddleName" error={props.checkFieldValidity(0, "patientMiddleName").valid && props.highestStep > 0}
                value={props.patient.FormData[0].requiredFields.patientMiddleName} onChange={(e) => props.modifyForm(0, e)}/>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker disableToolbar variant="inline" fullWidth format="MM/dd/yyyy" margin="normal" label="Date of Birth" error={props.checkFieldValidity(0, "patientBirth").valid && props.highestStep > 0}
                value={props.patient.FormData[0].requiredFields.patientBirth} style={{marginTop: "24px"}}
                  onChange={(e) => props.modifyForm(0, e, "patientBirth")} KeyboardButtonProps={{'aria-label': 'change date',}}/>
                </MuiPickersUtilsProvider>
                <TextField fullWidth margin="normal" disabled variant="outlined" label="Age" value={props.patient.FormData[0].requiredFields.patientAge} />
                <TextField fullWidth margin="normal" variant="outlined" label="MR" name="patientMR" error={props.checkFieldValidity(0, "patientMR").valid && props.highestStep > 0}
                value={props.patient.FormData[0].requiredFields.patientMR} onChange={(e) => props.modifyForm(0, e)} />
                <TextField fullWidth margin="normal" variant="outlined" label="BTID" name="patientBTID" error={props.checkFieldValidity(0, "patientBTID").valid && props.highestStep > 0}
                value={props.patient.FormData[0].requiredFields.patientBTID} onChange={(e) => props.modifyForm(0, e)} />
                <TextField fullWidth margin="normal" variant="outlined" label="Phone" name="patientPhone" error={props.checkFieldValidity(0, "patientPhone").valid && props.highestStep > 0}
                value={props.patient.FormData[0].requiredFields.patientPhone} onChange={(e) => props.modifyForm(0, e)} />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField fullWidth margin="normal" variant="outlined" label="Address Line 1" name="patientAddress1" error={props.checkFieldValidity(0, "patientAddress1").valid && props.highestStep > 0}
                value={props.patient.FormData[0].requiredFields.patientAddress1} onChange={(e) => props.modifyForm(0, e)} />
                <TextField fullWidth margin="normal" variant="outlined" label="Address Line 2" name="patientAddress2" value={props.patient.FormData[0].requiredFields.patientAddress2} onChange={(e) => props.modifyForm(0, e)} />
                <TextField fullWidth margin="normal" variant="outlined" label="City" name="patientCity" error={props.checkFieldValidity(0, "patientCity").valid && props.highestStep > 0}
                value={props.patient.FormData[0].requiredFields.patientCity} onChange={(e) => props.modifyForm(0, e)} />
                <TextField fullWidth margin="normal" variant="outlined" label="County" name="patientCounty" error={props.checkFieldValidity(0, "patientCounty").valid && props.highestStep > 0}
                value={props.patient.FormData[0].requiredFields.patientCounty} onChange={(e) => props.modifyForm(0, e)} />
                <TextField fullWidth margin="normal" variant="outlined" label="State" name="patientState" error={props.checkFieldValidity(0, "patientState").valid && props.highestStep > 0}
                value={props.patient.FormData[0].requiredFields.patientState} onChange={(e) => props.modifyForm(0, e)} />
                <TextField fullWidth margin="normal" variant="outlined" label="Postal Code" name="patientPostalCode" error={props.checkFieldValidity(0, "patientPostalCode").valid && props.highestStep > 0}
                value={props.patient.FormData[0].requiredFields.patientPostalCode} onChange={(e) => props.modifyForm(0, e)} />
                <TextField fullWidth margin="normal" variant="outlined" label="Country" name="patientCountry" error={props.checkFieldValidity(0, "patientCountry").valid && props.highestStep > 0}
                value={props.patient.FormData[0].requiredFields.patientCountry} onChange={(e) => props.modifyForm(0, e)} />

              </Grid>
            </Grid>

          </CardContent>
        </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card className={classes.fullHeightCard}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Payor Info
              </Typography>
              <TextField fullWidth margin="normal" variant="outlined" label="Primary Policy/Payor:" name="payorPrimary" error={props.checkFieldValidity(0, "payorPrimary").valid && props.highestStep > 0}
              value={props.patient.FormData[0].requiredFields.payorPrimary} onChange={(e) => props.modifyForm(0, e)} />
              <TextField fullWidth margin="normal" variant="outlined" label="Secondary Policy/Payor:" name="payorSecondary" value={props.patient.FormData[0].requiredFields.payorSecondary} onChange={(e) => props.modifyForm(0, e)} />
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel>Cert Type</InputLabel>
                <Select name="payorCertification" value={props.patient.FormData[0].requiredFields.payorCertification} onChange={(e) => props.modifyForm(0, e, "payorCertification")} label="Cert Type" >
                  <MenuItem value={"Initial"}>Initial</MenuItem>
                  <MenuItem value={"Recertification"}>Recertification</MenuItem>
                  <MenuItem value={"Restart & Recertification"}>Restart & Recertification</MenuItem>
                  <MenuItem value={"Initial & Recertification"}>Initial & Recertification</MenuItem>
                  <MenuItem value={"Repair Certification"}>Repair Certification</MenuItem>
                </Select>
              </FormControl>
              <TextField fullWidth margin="normal" variant="outlined" label="Length Of Need (99=∞):" name="payorLengthOfNeed" error={props.checkFieldValidity(0, "payorLengthOfNeed").valid && props.highestStep > 0}
              value={props.patient.FormData[0].requiredFields.payorLengthOfNeed} onChange={(e) => props.modifyForm(0, e)} />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker disableToolbar variant="inline" className={classes.halfWidth} format="MM/dd/yyyy" margin="normal" label="Certificate From" error={props.checkFieldValidity(0, "payorCertFrom").valid && props.highestStep > 0}
              value={props.patient.FormData[0].requiredFields.payorCertFrom} style={{marginTop: "24px"}}
                onChange={(e) => props.modifyForm(0, e, "payorCertFrom")} KeyboardButtonProps={{'aria-label': 'change date',}}/>
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker disableToolbar variant="inline" className={classes.halfWidth} format="MM/dd/yyyy" margin="normal" label="Certificate To" error={props.checkFieldValidity(0, "payorCertTo").valid && props.highestStep > 0}
              value={props.patient.FormData[0].requiredFields.payorCertTo} style={{marginTop: "24px"}}
                onChange={(e) => props.modifyForm(0, e, "payorCertTo")} KeyboardButtonProps={{'aria-label': 'change date',}}/>
              </MuiPickersUtilsProvider>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.fullHeightCard}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Diagnosis
              </Typography>
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel>Prognosis</InputLabel>
                <Select name="patientPrognosis" value={props.patient.FormData[0].requiredFields.patientPrognosis} onChange={(e) => props.modifyForm(0, e, "patientPrognosis")} label="Prognosis" >
                  <MenuItem value={"Poor"}>Poor</MenuItem>
                  <MenuItem value={"Fair"}>Fair</MenuItem>
                  <MenuItem value={"Good"}>Good</MenuItem>
                </Select>
              </FormControl>

              {props.patient.FormData[0].requiredFields.patientDiagnosis.map((e, i) => {
                return(<TextField fullWidth margin="normal" key={i} variant="outlined" label={"Diagnosis " + romanize(i + 1)} onChange={(evt) => {
                  var diagnosis = props.patient.FormData[0].requiredFields.patientDiagnosis;
                  diagnosis[i] = evt.target.value;
                  props.modifyForm(0, diagnosis, "patientDiagnosis")
                }} value={e} />)
              })}
              <Grid container spacing={3}>
                <Grid item xs={3}>

                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>


        <Grid item xs={12}>
          <Card className={classes.fullHeightCard}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Prescribed Equipment
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                <FormControlLabel control={
                  <Checkbox name="Ventilator Info" color="primary" checked={props.patient.FormData[1].active} onClick={props.addRemoveEquipment} />} label="Ventilator"/>
                <br/>
                <FormControlLabel control={
                  <Checkbox name="Tracheostomy Info" color="primary" checked={props.patient.FormData[2].active} onClick={props.addRemoveEquipment} />} label="Tracheostomy/HME"/>
                <br/>
                <FormControlLabel control={
                  <Checkbox name="Suction Machine Info" color="primary" checked={props.patient.FormData[3].active} onClick={props.addRemoveEquipment} />} label="Suction Machine"/>
                </Grid>
                <Grid item xs={12} md={3}>
                <FormControlLabel control={
                  <Checkbox name="Nebulizer Info" color="primary" checked={props.patient.FormData[4].active} onClick={props.addRemoveEquipment} />} label="Nebulizer"/>
                <br/>
                <FormControlLabel control={
                  <Checkbox name="Enteral Info" color="primary" checked={props.patient.FormData[5].active} onClick={props.addRemoveEquipment} />} label="Enteral"/>
                <br/>
                <FormControlLabel control={
                  <Checkbox name="Apnea Monitor Info" color="primary" checked={props.patient.FormData[6].active} onClick={checkAge} />} label="Apnea Monitor"/>
                </Grid>
                <Grid item xs={12} md={3}>
                <FormControlLabel control={
                  <Checkbox name="Oxygen Info" color="primary" checked={props.patient.FormData[7].active} onClick={props.addRemoveEquipment} />} label="Oxygen"/>
                <br/>
                <FormControlLabel control={
                  <Checkbox name="Oximeter/Probes Info" color="primary" checked={props.patient.FormData[8].active} onClick={props.addRemoveEquipment} />} label="Oximeter/Probes"/>
                <br/>
                <FormControlLabel control={
                  <Checkbox name="Cough Assist Info" color="primary" checked={props.patient.FormData[9].active} onClick={props.addRemoveEquipment} />} label="Cough Assist"/>
                </Grid>
                <Grid item xs={12} md={3}>
                <FormControlLabel control={
                  <Checkbox name="CPAP Info" color="primary" checked={props.patient.FormData[10].active} onClick={props.addRemoveEquipment} />} label="CPAP"/>
                <br/>
                <FormControlLabel control={
                  <Checkbox name="BIPAP Info" color="primary" checked={props.patient.FormData[11].active} onClick={props.addRemoveEquipment} />} label="BIPAP"/>
                <br/>
                <FormControlLabel control={
                  <Checkbox name="Other Info" color="primary" checked={props.patient.FormData[12].active} />} label=""/>
                  <TextField margin="normal" label="" placeholder="Other" name="Other Info" className={classes.otherBox} value={props.patient.FormData[0].requiredFields.otherEquipment} onChange={(e) => {
                    props.modifyForm(0, e.target.value, "otherEquipment");
                    if(e.target.value != "" && !props.patient.FormData[12].active){
                      props.addRemoveEquipment(e)
                    }
                    else if(e.target.value == "" && props.patient.FormData[12].active){
                      props.addRemoveEquipment(e)
                    }
                  }}/>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>


      </Grid>
    </div>
  );

}

export default PatientInfo;
