import React from 'react';
import './App.css';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import GoogleMapReact from 'google-map-react';
import MediaQuery from 'react-responsive'

const Pin = ({ text }) =>
<div>
<div className="marker"/>
<span className="beacon"/>
</div>;

function Contact() {


    return (
      <div className="formBack" style={{backgroundImage: 'url("https://images.pexels.com/photos/40568/medical-appointment-doctor-healthcare-40568.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260")',
      backgroundSize: "cover", padding: "20px",minHeight: "calc(100vh - 154px)"}}>
        <MediaQuery minDeviceWidth={960}>
        <br/><br/><br/>
        <Paper>
        <div style={{display: "flex", width: "100%", height: "60vh"}}>
          <div style={{padding: "5px 10px", width: "20%"}}>
              <h1 style={{marginTop: "5px", fontWeight: "200"}}>Contact Us</h1>
              <p style={{margin: "2px 0px", fontWeight: "200", fontSize: "20px"}}>Address:</p>
              <p style={{margin: "2px 0px", fontWeight: "400", fontSize: "18px"}}>Medical Resources, Inc.</p>
              <p style={{margin: "2px 0px", fontWeight: "400", fontSize: "18px"}}>1516 5th Ave South</p>
              <p style={{margin: "2px 0px", fontWeight: "400", fontSize: "18px"}}>Birmingham</p>
              <p style={{margin: "2px 0px", fontWeight: "400", fontSize: "18px"}}>AL 35233</p>
              <p style={{margin: "2px 0px", fontWeight: "400", fontSize: "18px"}}>United States</p>
              <br/>
              <p style={{margin: "2px 0px", fontWeight: "200", fontSize: "20px"}}>Phone:</p>
              <p style={{margin: "2px 0px", fontWeight: "400", fontSize: "18px"}}>+1 (866) 521-7337</p>
              <p style={{margin: "2px 0px", fontWeight: "400", fontSize: "18px"}}>(205) 521-0585</p>
              <br/>
              <p style={{margin: "2px 0px", fontWeight: "200", fontSize: "20px"}}>Email:</p>
              <p style={{margin: "2px 0px", fontWeight: "400", fontSize: "18px"}}>WeCare@Mdr.net</p>
            </div>
            <div style={{width: "80%", height: "calc(100% - 20px)", margin: "10px"}}>
              <GoogleMapReact bootstrapURLKeys={{ key: "AIzaSyAncSw1idCzao4rKnmSiqeGwa8KVJwrTy8" }} defaultCenter={{lat: 33.505602, lng: -86.807961}} defaultZoom={15}>
              <Pin
              lat={33.505602}
              lng={-86.807961}
              text="MDR"
              />
              </GoogleMapReact>
            </div>
          </div>
        </Paper>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={959}>
        <Paper>
        <div style={{display: "", width: "100%", height: "75vh"}}>
          <div style={{padding: "5px 10px", width: "80%"}}>
            <h1 style={{marginTop: "5px", fontWeight: "200"}}>Contact Us</h1>
            <div style={{display: "flex"}}>
                <div style={{flex: 1}}>
                <p style={{margin: "2px 0px", fontWeight: "200", fontSize: "20px"}}>Address:</p>
                <p style={{margin: "2px 0px", fontWeight: "400", fontSize: "12px"}}>Medical Resources, Inc.</p>
                <p style={{margin: "2px 0px", fontWeight: "400", fontSize: "12px"}}>1516 5th Ave South</p>
                <p style={{margin: "2px 0px", fontWeight: "400", fontSize: "12px"}}>Birmingham</p>
                <p style={{margin: "2px 0px", fontWeight: "400", fontSize: "12px"}}>AL 35233</p>
                <p style={{margin: "2px 0px", fontWeight: "400", fontSize: "12px"}}>United States</p>
              </div>
              <div style={{flex: 1}}>
                <p style={{margin: "2px 0px", fontWeight: "200", fontSize: "20px"}}>Phone:</p>
                <p style={{margin: "2px 0px", fontWeight: "400", fontSize: "12px"}}>+1 (866) 521-7337</p>
                <p style={{margin: "2px 0px", fontWeight: "400", fontSize: "12px"}}>(205) 521-0585</p>
                <p style={{margin: "2px 0px", fontWeight: "200", fontSize: "20px"}}>Email:</p>
                <p style={{margin: "2px 0px", fontWeight: "400", fontSize: "12px"}}>WeCare@Mdr.net</p>
              </div>
            </div>
            </div>
            <div style={{width: "calc(100% - 20px)", height: "calc(60% - 20px)", margin: "10px"}}>
              <GoogleMapReact bootstrapURLKeys={{ key: "AIzaSyAncSw1idCzao4rKnmSiqeGwa8KVJwrTy8" }} defaultCenter={{lat: 33.505602, lng: -86.807961}} defaultZoom={15}>
              <Pin
              lat={33.505602}
              lng={-86.807961}
              text="MDR"
              />
              </GoogleMapReact>
            </div>
          </div>
        </Paper>
        </MediaQuery>
      </div>
    );

}

export default Contact;
