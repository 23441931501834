import React, {Component} from 'react';
import '../App.css';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import {maskSizes, maskStyles} from "../enums.js"
import Icon from '@mdi/react'
import { mdiDelete, mdiPlus } from '@mdi/js';
import {romanize} from "../utils.js"

const useStyles = makeStyles((theme) => ({
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 120,
    margin: "8px 0px"
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
    },
  },
  fullHeightCard: {
		height: "100%",
	},
  halfWidth: {
    width: "50%"
  },
  quartWidth:{
    width: "25%"
  },
  threeQuartWidth:{
    width: "75%"
  },
  commentWidth:{
    marginTop: "8px",
    width: "calc(100% - 69px)"
  },
  delButton:{
    marginTop: "8px",
    marginRight: "4px",
    height: "40px"
  }
}));

function CPAPInfo(props) {
  const classes = useStyles();

  return (
    <div style={{padding: "20px"}}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card className={classes.fullHeightCard}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                CPAP Information
              </Typography>
              <form className={classes.root} noValidate autoComplete="off">

              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel>Usage</InputLabel>
                <Select name="cpapUsage" value={props.patient.FormData[10].requiredFields.cpapUsage} onChange={(e) => props.modifyForm(10, e)} label="Model" >
                  <MenuItem value={"Continuously"}>Continuously</MenuItem>
                  <MenuItem value={"During all sleep periods"}>During all sleep periods</MenuItem>
                  <MenuItem value={"PRN / As needed"}>PRN / As needed</MenuItem>
                </Select>
              </FormControl>

              <TextField fullWidth variant="outlined" label="Pressure/Range Setting (cmH₂O)" name="cpapPressure" error={props.checkFieldValidity(10, "cpapPressure").valid && props.highestStep > 10}
              value={props.patient.FormData[10].requiredFields.cpapPressure} onChange={(e) => props.modifyForm(10, e)} />

              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel>Mask Style</InputLabel>
                <Select name="cpapMaskStyle" value={props.patient.FormData[10].requiredFields.cpapMaskStyle} onChange={(e) => props.modifyForm(10, e)} label="Mask Style" >
                  {maskStyles.map((e) => <MenuItem value={e}>{e}</MenuItem>)}
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel>Mask Size</InputLabel>
                <Select name="cpapMaskSize" value={props.patient.FormData[10].requiredFields.cpapMaskSize} onChange={(e) => props.modifyForm(10, e)} label="Mask Size" >
                  {maskSizes.map((e) => <MenuItem value={e}>{e}</MenuItem>)}
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel>Ramp Duration</InputLabel>
                <Select name="cpapRampDuration" value={props.patient.FormData[10].requiredFields.cpapRampDuration} onChange={(e) => props.modifyForm(10, e)} label="Ramp Duration" >
                  {[5,10,15,20,25,30,35,40,45].map((e) => <MenuItem value={e}>{e + " Minutes"}</MenuItem>)}
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel>Bleed In Oxygen</InputLabel>
                <Select name="cpapBleedInOxygen" value={props.patient.FormData[10].requiredFields.cpapBleedInOxygen} onChange={(e) => props.modifyForm(10, e)} label="bleedInOxygen" >
                  <MenuItem value={"No"}>No</MenuItem>
                  <MenuItem value={"Yes"}>Yes</MenuItem>
                </Select>
              </FormControl>

              <FormControlLabel control={
                <Checkbox color="primary" checked={props.patient.FormData[10].requiredFields.cpapRequiresHumidity} onClick={(e) => props.modifyForm(10, "checkbox", "cpapRequiresHumidity")} />} label="Patient requires CPaP humidification."/>

              {props.patient.FormData[10].requiredFields.requiresHumidity ?
                <FormControlLabel control={
                  <Checkbox color="primary" checked={props.patient.FormData[10].requiredFields.cpapRequiresHumidityConfig} onClick={(e) => props.modifyForm(10, "checkbox", "cpapRequiresHumidityConfig")} />} label="Patient requires heated humidity configuration"/>
              : null}

              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
        <Card className={classes.fullHeightCard}>
        <CardContent style={{position: "relative"}}>
          <Typography color="textSecondary" gutterBottom>
            Comments
          </Typography>
          <Button style={{position: "absolute", right: "17px", top: "10px"}} variant="contained" color="primary" onClick={(e) => props.addRemoveComment(e, 10, "add")}><Icon path={mdiPlus} size={1}/></Button>
          <p className="recommended"><b>Related Equipment & Supply Items may Include:</b>
          <br/>A7034/A7030 - CPAP Mask
          <br/>A7033/A7034 - Nasal Pillows
          <br/>A7045/A9900 - Whisper Swivel
          <br/>A7038/A7039 - Filters
          <br/>S8186 - Pressure Line Adapter
          <br/>A4604 - Heated Humidifier w/embedded Heating Element.
          <br/>A7031 - Full Face Cushion
          <br/>A7032 - Mask Cushion
          <br/>A7035 - Headgear
          <br/>A7036 - Chinstrap
          <br/>A7044 - Oral Interface
          <br/>A7046 - Humidifier Chamber
          <br/>A7037 - CPAP Tubing</p>

          {props.patient.FormData[10].requiredFields.comments.map((e, i) =>
            <div style={{width: "100%"}}>
            <Button className={classes.delButton} variant="contained" color="secondary" onClick={(e) => props.addRemoveComment(i, 10, "remove")}><Icon path={mdiDelete} size={1}/></Button>
            <TextField className={classes.commentWidth} multiline rowsMax={4} size="small" margin="normal" variant="outlined" label={"Comment " + romanize(i + 1)} name={i.toString()} value={e} onChange={(e) => props.addRemoveComment(e, 10, "modify")} />
            </div>
          )}

        </CardContent>
        </Card>
        </Grid>



      </Grid>
    </div>
  );

}

export default CPAPInfo;
