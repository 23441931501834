export var tubes = ["Tracoe", "Bivona", "Shiley"]
export var tubeOrderNumbers = [
  [{orderNumber: "", application: "", model: "", distal: "", cannula: "", specialLength: "", cuff: "", fenestration: "", flange: "", size: ""}],
  [
    {orderNumber: "", application: "", model: "", distal: "", cannula: "", specialLength: "", cuff: "", fenestration: "", flange: "", size: ""},
    {cuff: "Cuffed", model:"Aire-Cuf", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"2.5mm", orderNumber: "65SN025"},
    {cuff: "Cuffed", model:"Aire-Cuf", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"3.0mm", orderNumber: "65SN030"},
    {cuff: "Cuffed", model:"Aire-Cuf", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"3.5mm", orderNumber: "65SN035"},
    {cuff: "Cuffed", model:"Aire-Cuf", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"4.0mm", orderNumber: "65SN040"},
    {cuff: "Cuffed", model:"Aire-Cuf", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"2.5mm", orderNumber: "65N025"},
    {cuff: "Cuffed", model:"Aire-Cuf", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"3.0mm", orderNumber: "65N030"},
    {cuff: "Cuffed", model:"Aire-Cuf", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"3.5mm", orderNumber: "65N035"},
    {cuff: "Cuffed", model:"Aire-Cuf", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"4.0mm", orderNumber: "65N040"},
    {cuff: "Cuffed", model:"Aire-Cuf", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"2.5mm", orderNumber: "65SP025"},
    {cuff: "Cuffed", model:"Aire-Cuf", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"3.0mm", orderNumber: "65SP030"},
    {cuff: "Cuffed", model:"Aire-Cuf", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"3.5mm", orderNumber: "65SP035"},
    {cuff: "Cuffed", model:"Aire-Cuf", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"4.0mm", orderNumber: "65SP040"},
    {cuff: "Cuffed", model:"Aire-Cuf", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"4.5mm", orderNumber: "65SP045"},
    {cuff: "Cuffed", model:"Aire-Cuf", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"5.0mm", orderNumber: "65SP050"},
    {cuff: "Cuffed", model:"Aire-Cuf", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"5.5mm", orderNumber: "65SP055"},
    {cuff: "Cuffed", model:"Aire-Cuf", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"2.5mm", orderNumber: "65P025"},
    {cuff: "Cuffed", model:"Aire-Cuf", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"3.0mm", orderNumber: "65P030"},
    {cuff: "Cuffed", model:"Aire-Cuf", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"3.5mm", orderNumber: "65P035"},
    {cuff: "Cuffed", model:"Aire-Cuf", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"4.0mm", orderNumber: "65P040"},
    {cuff: "Cuffed", model:"Aire-Cuf", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"4.5mm", orderNumber: "65P045"},
    {cuff: "Cuffed", model:"Aire-Cuf", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"5.0mm", orderNumber: "65P050"},
    {cuff: "Cuffed", model:"Aire-Cuf", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"5.5mm", orderNumber: "65P055"},
    {cuff: "Cuffed", model:"Fome-Cuf (Silicone)", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"3.0mm", orderNumber: "85N030"},
    {cuff: "Cuffed", model:"Fome-Cuf (Silicone)", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"3.5mm", orderNumber: "85N035"},
    {cuff: "Cuffed", model:"Fome-Cuf (Silicone)", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"4.0mm", orderNumber: "85N040"},
    {cuff: "Cuffed", model:"Fome-Cuf (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"2.5mm", orderNumber: "85P025"},
    {cuff: "Cuffed", model:"Fome-Cuf (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"3.0mm", orderNumber: "85P030"},
    {cuff: "Cuffed", model:"Fome-Cuf (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"3.5mm", orderNumber: "85P035"},
    {cuff: "Cuffed", model:"Fome-Cuf (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"4.0mm", orderNumber: "85P040"},
    {cuff: "Cuffed", model:"Fome-Cuf (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"4.5mm", orderNumber: "85P045"},
    {cuff: "Cuffed", model:"Fome-Cuf (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"5.0mm", orderNumber: "85P050"},
    {cuff: "Cuffed", model:"Fome-Cuf (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"5.5mm", orderNumber: "85P055"},
    {cuff: "Cuffed", model:"TTS (Silicone)", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"2.5mm", orderNumber: "67SN025"},
    {cuff: "Cuffed", model:"TTS (Silicone)", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"3.0mm", orderNumber: "67SN030"},
    {cuff: "Cuffed", model:"TTS (Silicone)", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"3.5mm", orderNumber: "67SN035"},
    {cuff: "Cuffed", model:"TTS (Silicone)", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"4.0mm", orderNumber: "67SN040"},
    {cuff: "Cuffed", model:"TTS (Silicone)", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"2.5mm", orderNumber: "67N025"},
    {cuff: "Cuffed", model:"TTS (Silicone)", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"3.0mm", orderNumber: "67N030"},
    {cuff: "Cuffed", model:"TTS (Silicone)", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"3.5mm", orderNumber: "67N035"},
    {cuff: "Cuffed", model:"TTS (Silicone)", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"4.0mm", orderNumber: "67N040"},
    {cuff: "Cuffed", model:"TTS (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"2.5mm", orderNumber: "67SP025"},
    {cuff: "Cuffed", model:"TTS (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"3.0mm", orderNumber: "67SP030"},
    {cuff: "Cuffed", model:"TTS (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"3.5mm", orderNumber: "67SP035"},
    {cuff: "Cuffed", model:"TTS (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"4.0mm", orderNumber: "67SP040"},
    {cuff: "Cuffed", model:"TTS (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"4.5mm", orderNumber: "67SP045"},
    {cuff: "Cuffed", model:"TTS (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"5.0mm", orderNumber: "67SP050"},
    {cuff: "Cuffed", model:"TTS (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"5.5mm", orderNumber: "67SP055"},
    {cuff: "Cuffed", model:"TTS (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"2.5mm", orderNumber: "67P025"},
    {cuff: "Cuffed", model:"TTS (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"3.0mm", orderNumber: "67P030"},
    {cuff: "Cuffed", model:"TTS (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"3.5mm", orderNumber: "67P035"},
    {cuff: "Cuffed", model:"TTS (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"4.0mm", orderNumber: "67P040"},
    {cuff: "Cuffed", model:"TTS (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"4.5mm", orderNumber: "67P045"},
    {cuff: "Cuffed", model:"TTS (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"5.0mm", orderNumber: "67P050"},
    {cuff: "Cuffed", model:"TTS (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"5.5mm", orderNumber: "67P055"},
    {cuff: "Uncuffed", model:"Standard", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"2.5mm", orderNumber: "60SN025"},
    {cuff: "Uncuffed", model:"Standard", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"3.0mm", orderNumber: "60SN030"},
    {cuff: "Uncuffed", model:"Standard", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"3.5mm", orderNumber: "60SN035"},
    {cuff: "Uncuffed", model:"Standard", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"4.0mm", orderNumber: "60SN040"},
    {cuff: "Uncuffed", model:"Standard", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"2.5mm", orderNumber: "60N025"},
    {cuff: "Uncuffed", model:"Standard", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"3.0mm", orderNumber: "60N030"},
    {cuff: "Uncuffed", model:"Standard", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"3.5mm", orderNumber: "60N035"},
    {cuff: "Uncuffed", model:"Standard", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"4.0mm", orderNumber: "60N040"},
    {cuff: "Uncuffed", model:"Standard", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"2.5mm", orderNumber: "60SP025"},
    {cuff: "Uncuffed", model:"Standard", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"3.0mm", orderNumber: "60SP030"},
    {cuff: "Uncuffed", model:"Standard", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"3.5mm", orderNumber: "60SP035"},
    {cuff: "Uncuffed", model:"Standard", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"4.0mm", orderNumber: "60SP040"},
    {cuff: "Uncuffed", model:"Standard", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"4.5mm", orderNumber: "60SP045"},
    {cuff: "Uncuffed", model:"Standard", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"5.0mm", orderNumber: "60SP050"},
    {cuff: "Uncuffed", model:"Standard", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"Straight Neck", size:"5.5mm", orderNumber: "60SP055"},
    {cuff: "Uncuffed", model:"Standard", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"2.5mm", orderNumber: "60P025"},
    {cuff: "Uncuffed", model:"Standard", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"3.0mm", orderNumber: "60P030"},
    {cuff: "Uncuffed", model:"Standard", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"3.5mm", orderNumber: "60P035"},
    {cuff: "Uncuffed", model:"Standard", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"4.0mm", orderNumber: "60P040"},
    {cuff: "Uncuffed", model:"Standard", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"4.5mm", orderNumber: "60P045"},
    {cuff: "Uncuffed", model:"Standard", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"5.0mm", orderNumber: "60P050"},
    {cuff: "Uncuffed", model:"Standard", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", specialLength: "", flange:"V-Neck (Standard)", size:"5.5mm", orderNumber: "60P055"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", flange:"Straight Neck", specialLength:"Plus (Standard)", size:"2.5mm", orderNumber: "60NFPS25"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", flange:"Straight Neck", specialLength:"Plus (Standard)", size:"3.0mm", orderNumber: "60NFPS30"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", flange:"Straight Neck", specialLength:"Plus (Standard)", size:"3.5mm", orderNumber: "60NFPS35"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", flange:"Straight Neck", specialLength:"Plus (Standard)", size:"4.0mm", orderNumber: "60NFPS40"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", flange:"V-Neck (Standard)", specialLength:"Plus (Standard)", size:"2.5mm", orderNumber: "60NFP25"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", flange:"V-Neck (Standard)", specialLength:"Plus (Standard)", size:"3.0mm", orderNumber: "60NFP30"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", flange:"V-Neck (Standard)", specialLength:"Plus (Standard)", size:"3.5mm", orderNumber: "60NFP35"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Neonatal (NEO)", distal: "", cannula: "", fenestration: "", flange:"V-Neck (Standard)", specialLength:"Plus (Standard)", size:"4.0mm", orderNumber: "60NFP40"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", flange:"Straight Neck", specialLength:"Extra Length", size:"4.0mm", orderNumber: "60PFPS40"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", flange:"Straight Neck", specialLength:"Extra Length", size:"4.5mm", orderNumber: "60PFPS45"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", flange:"Straight Neck", specialLength:"Extra Length", size:"5.0mm", orderNumber: "60PFPS50"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", flange:"Straight Neck", specialLength:"Extra Length", size:"5.5mm", orderNumber: "60PFPS55"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", flange:"Straight Neck", specialLength:"Extra Length", size:"6.0mm", orderNumber: "60PFPS60"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", flange:"Straight Neck", specialLength:"Plus (Standard)", size:"2.5mm", orderNumber: "60PFSS25"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", flange:"Straight Neck", specialLength:"Plus (Standard)", size:"3.0mm", orderNumber: "60PFSS30"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", flange:"Straight Neck", specialLength:"Plus (Standard)", size:"3.5mm", orderNumber: "60PFSS35"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", flange:"Straight Neck", specialLength:"Plus (Standard)", size:"4.0mm", orderNumber: "60PFSS40"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", flange:"Straight Neck", specialLength:"Plus (Standard)", size:"4.5mm", orderNumber: "60PFSS45"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", flange:"Straight Neck", specialLength:"Plus (Standard)", size:"5.0mm", orderNumber: "60PFSS50"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", flange:"Straight Neck", specialLength:"Plus (Standard)", size:"5.5mm", orderNumber: "60PFSS55"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", flange:"V-Neck (Standard)", specialLength:"Extra Length", size:"4.0mm", orderNumber: "60PFP40"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", flange:"V-Neck (Standard)", specialLength:"Extra Length", size:"4.5mm", orderNumber: "60PFP45"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", flange:"V-Neck (Standard)", specialLength:"Extra Length", size:"5.0mm", orderNumber: "60PFP50"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", flange:"V-Neck (Standard)", specialLength:"Extra Length", size:"5.5mm", orderNumber: "60PFP55"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", flange:"V-Neck (Standard)", specialLength:"Extra Length", size:"6.0mm", orderNumber: "60PFP60"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", flange:"V-Neck (Standard)", specialLength:"Plus (Standard)", size:"2.5mm", orderNumber: "60PFSS25"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", flange:"V-Neck (Standard)", specialLength:"Plus (Standard)", size:"3.0mm", orderNumber: "60PFSS30"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", flange:"V-Neck (Standard)", specialLength:"Plus (Standard)", size:"3.5mm", orderNumber: "60PFSS35"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", flange:"V-Neck (Standard)", specialLength:"Plus (Standard)", size:"4.0mm", orderNumber: "60PFSS40"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", flange:"V-Neck (Standard)", specialLength:"Plus (Standard)", size:"4.5mm", orderNumber: "60PFSS45"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", flange:"V-Neck (Standard)", specialLength:"Plus (Standard)", size:"5.0mm", orderNumber: "60PFSS50"},
    {cuff: "Uncuffed", model:"FlexTend (Silicone)", application:"Pediatric (PED)", distal: "", cannula: "", fenestration: "", flange:"V-Neck (Standard)", specialLength:"Plus (Standard)", size:"5.5mm", orderNumber: "60PFSS55"},
  ],
  [
    {orderNumber: "", application: "", model: "", distal: "", cannula: "", specialLength: "", cuff: "", fenestration: "", flange: "", size: ""},
    {orderNumber: "3.0 NEO", application: "Neonatal NEO", model: "", distal: "", cannula: "", specialLength: "", cuff: "Non-Cuffed", fenestration: "Non-Fenestrated", flange: "Soft Material and Lower Flange Angle", size: "3.0 - 3.0mm"},
    {orderNumber: "3.5 NEO", application: "Neonatal NEO", model: "", distal: "", cannula: "", specialLength: "", cuff: "Non-Cuffed", fenestration: "Non-Fenestrated", flange: "Soft Material and Lower Flange Angle", size: "3.5 - 3.5mm"},
    {orderNumber: "4.0 NEO", application: "Neonatal NEO", model: "", distal: "", cannula: "", specialLength: "", cuff: "Non-Cuffed", fenestration: "Non-Fenestrated", flange: "Soft Material and Lower Flange Angle", size: "4.0 - 4.0mm"},
    {orderNumber: "4.5 NEO", application: "Neonatal NEO", model: "", distal: "", cannula: "", specialLength: "", cuff: "Non-Cuffed", fenestration: "Non-Fenestrated", flange: "Soft Material and Lower Flange Angle", size: "4.5 - 4.5mm"},
    {orderNumber: "3.0 PED", application: "Pediatric (PED)", model: "", distal: "", cannula: "", specialLength: "", cuff: "Non-Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "3.0 - 3.0mm"},
    {orderNumber: "3.5 PED", application: "Pediatric (PED)", model: "", distal: "", cannula: "", specialLength: "", cuff: "Non-Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "3.5 - 3.5mm"},
    {orderNumber: "4.0 PED", application: "Pediatric (PED)", model: "", distal: "", cannula: "", specialLength: "", cuff: "Non-Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "4.0 - 4.0mm"},
    {orderNumber: "4.5 PED", application: "Pediatric (PED)", model: "", distal: "", cannula: "", specialLength: "", cuff: "Non-Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "4.5 - 4.5mm"},
    {orderNumber: "5.0 PED", application: "Pediatric (PED)", model: "", distal: "", cannula: "", specialLength: "", cuff: "Non-Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "5.0 - 5.0mm"},
    {orderNumber: "5.5 PED", application: "Pediatric (PED)", model: "", distal: "", cannula: "", specialLength: "", cuff: "Non-Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "5.5 - 5.5mm"},
    {orderNumber: "5.0PLC", application: "Pediatric (PED)", model: "", distal: "", cannula: "", specialLength: "Long", cuff: "Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "5.0 - 5.0mm"},
    {orderNumber: "5.5PLC", application: "Pediatric (PED)", model: "", distal: "", cannula: "", specialLength: "Long", cuff: "Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "5.5 - 5.5mm"},
    {orderNumber: "6.0PLC", application: "Pediatric (PED)", model: "", distal: "", cannula: "", specialLength: "Long", cuff: "Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "6.0 - 6.0mm"},
    {orderNumber: "6.5PLC", application: "Pediatric (PED)", model: "", distal: "", cannula: "", specialLength: "Long", cuff: "Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "6.5 - 6.5mm"},
    {orderNumber: "5.0 PDL", application: "Pediatric (PED)", model: "", distal: "", cannula: "", specialLength: "Long", cuff: "Non-Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "5.0 - 5.0mm"},
    {orderNumber: "5.5 PDL", application: "Pediatric (PED)", model: "", distal: "", cannula: "", specialLength: "Long", cuff: "Non-Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "5.5 - 5.5mm"},
    {orderNumber: "6.0 PDL", application: "Pediatric (PED)", model: "", distal: "", cannula: "", specialLength: "Long", cuff: "Non-Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "6.0 - 6.0mm"},
    {orderNumber: "6.5 PDL", application: "Pediatric (PED)", model: "", distal: "", cannula: "", specialLength: "Long", cuff: "Non-Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "6.5 - 6.5mm"},
    {orderNumber: "4.0PDC", application: "Pediatric (PED)", model: "", distal: "", cannula: "", specialLength: "", cuff: "Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "4.0 - 4.0mm"},
    {orderNumber: "4.5PDC", application: "Pediatric (PED)", model: "", distal: "", cannula: "", specialLength: "", cuff: "Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "4.5 - 4.5mm"},
    {orderNumber: "5.0PDC", application: "Pediatric (PED)", model: "", distal: "", cannula: "", specialLength: "", cuff: "Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "5.0 - 5.0mm"},
    {orderNumber: "5.5PDC", application: "Pediatric (PED)", model: "", distal: "", cannula: "", specialLength: "", cuff: "Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "5.5 - 5.5mm"},
    {orderNumber: "50XLTCD", application: "Adult", model: "", distal: "Distal", cannula: "Disposable Inner Cannula", specialLength: "Extra Length (XLT)", cuff: "Cuffed", fenestration: "Non-Fenestrated", flange: "Swivel Neck Flange", size: "5.0 - 5.0mm"},
    {orderNumber: "60XLTCD", application: "Adult", model: "", distal: "Distal", cannula: "Disposable Inner Cannula", specialLength: "Extra Length (XLT)", cuff: "Cuffed", fenestration: "Non-Fenestrated", flange: "Swivel Neck Flange", size: "6.0 - 6.0mm"},
    {orderNumber: "70XLTCD", application: "Adult", model: "", distal: "Distal", cannula: "Disposable Inner Cannula", specialLength: "Extra Length (XLT)", cuff: "Cuffed", fenestration: "Non-Fenestrated", flange: "Swivel Neck Flange", size: "7.0 - 7.0mm"},
    {orderNumber: "80XLTCD", application: "Adult", model: "", distal: "Distal", cannula: "Disposable Inner Cannula", specialLength: "Extra Length (XLT)", cuff: "Cuffed", fenestration: "Non-Fenestrated", flange: "Swivel Neck Flange", size: "8.0 - 8.0mm"},
    {orderNumber: "50XLTUD", application: "Adult", model: "", distal: "Distal", cannula: "Disposable Inner Cannula", specialLength: "Extra Length (XLT)", cuff: "Non-Cuffed", fenestration: "Non-Fenestrated", flange: "Swivel Neck Flange", size: "5.0 - 5.0mm"},
    {orderNumber: "60XLTUD", application: "Adult", model: "", distal: "Distal", cannula: "Disposable Inner Cannula", specialLength: "Extra Length (XLT)", cuff: "Non-Cuffed", fenestration: "Non-Fenestrated", flange: "Swivel Neck Flange", size: "6.0 - 6.0mm"},
    {orderNumber: "70XLTUD", application: "Adult", model: "", distal: "Distal", cannula: "Disposable Inner Cannula", specialLength: "Extra Length (XLT)", cuff: "Non-Cuffed", fenestration: "Non-Fenestrated", flange: "Swivel Neck Flange", size: "7.0 - 7.0mm"},
    {orderNumber: "80XLTUD", application: "Adult", model: "", distal: "Distal", cannula: "Disposable Inner Cannula", specialLength: "Extra Length (XLT)", cuff: "Non-Cuffed", fenestration: "Non-Fenestrated", flange: "Swivel Neck Flange", size: "8.0 - 8.0mm"},
    {orderNumber: "50XLTCP", application: "Adult", model: "", distal: "Proximal", cannula: "Disposable Inner Cannula", specialLength: "Extra Length (XLT)", cuff: "Cuffed", fenestration: "Non-Fenestrated", flange: "Swivel Neck Flange", size: "5.0 - 5.0mm"},
    {orderNumber: "60XLTCP", application: "Adult", model: "", distal: "Proximal", cannula: "Disposable Inner Cannula", specialLength: "Extra Length (XLT)", cuff: "Cuffed", fenestration: "Non-Fenestrated", flange: "Swivel Neck Flange", size: "6.0 - 6.0mm"},
    {orderNumber: "70XLTCP", application: "Adult", model: "", distal: "Proximal", cannula: "Disposable Inner Cannula", specialLength: "Extra Length (XLT)", cuff: "Cuffed", fenestration: "Non-Fenestrated", flange: "Swivel Neck Flange", size: "7.0 - 7.0mm"},
    {orderNumber: "80XLTCP", application: "Adult", model: "", distal: "Proximal", cannula: "Disposable Inner Cannula", specialLength: "Extra Length (XLT)", cuff: "Cuffed", fenestration: "Non-Fenestrated", flange: "Swivel Neck Flange", size: "8.0 - 8.0mm"},
    {orderNumber: "50XLTUP", application: "Adult", model: "", distal: "Proximal", cannula: "Disposable Inner Cannula", specialLength: "Extra Length (XLT)", cuff: "Non-Cuffed", fenestration: "Non-Fenestrated", flange: "Swivel Neck Flange", size: "5.0 - 5.0mm"},
    {orderNumber: "60XLTUP", application: "Adult", model: "", distal: "Proximal", cannula: "Disposable Inner Cannula", specialLength: "Extra Length (XLT)", cuff: "Non-Cuffed", fenestration: "Non-Fenestrated", flange: "Swivel Neck Flange", size: "6.0 - 6.0mm"},
    {orderNumber: "70XLTUP", application: "Adult", model: "", distal: "Proximal", cannula: "Disposable Inner Cannula", specialLength: "Extra Length (XLT)", cuff: "Non-Cuffed", fenestration: "Non-Fenestrated", flange: "Swivel Neck Flange", size: "7.0 - 7.0mm"},
    {orderNumber: "80XLTUP", application: "Adult", model: "", distal: "Proximal", cannula: "Disposable Inner Cannula", specialLength: "Extra Length (XLT)", cuff: "Non-Cuffed", fenestration: "Non-Fenestrated", flange: "Swivel Neck Flange", size: "8.0 - 8.0mm"},
    {orderNumber: "6PERC", application: "Adult", model: "", distal: "", cannula: "Disposable Inner Cannula", specialLength: "", cuff: "Percutaneous (Inverted Cuff)", fenestration: "Non-Fenestrated", flange: "Swivel Neck Flange", size: "6 - 10.8mm"},
    {orderNumber: "8PERC", application: "Adult", model: "", distal: "", cannula: "Disposable Inner Cannula", specialLength: "", cuff: "Percutaneous (Inverted Cuff)", fenestration: "Non-Fenestrated", flange: "Swivel Neck Flange", size: "8 - 12.2mm"},
    {orderNumber: "4CFN", application: "Adult", model: "", distal: "", cannula: "Inner Cannula", specialLength: "", cuff: "Non-Cuffed", fenestration: "Fenestrated", flange: "Swivel Neck Flange", size: "4 - 5.0mm"},
    {orderNumber: "6CFN", application: "Adult", model: "", distal: "", cannula: "Inner Cannula", specialLength: "", cuff: "Non-Cuffed", fenestration: "Fenestrated", flange: "Swivel Neck Flange", size: "6 - 6.4mm"},
    {orderNumber: "8CFN", application: "Adult", model: "", distal: "", cannula: "Inner Cannula", specialLength: "", cuff: "Non-Cuffed", fenestration: "Fenestrated", flange: "Swivel Neck Flange", size: "8 - 7.6mm"},
    {orderNumber: "10CFN", application: "Adult", model: "", distal: "", cannula: "Inner Cannula", specialLength: "", cuff: "Non-Cuffed", fenestration: "Fenestrated", flange: "Swivel Neck Flange", size: "10 - 8.9mm"},
    {orderNumber: "4CFS", application: "Adult", model: "", distal: "", cannula: "Inner Cannula", specialLength: "", cuff: "Non-Cuffed", fenestration: "Non-Fenestrated", flange: "Swivel Neck Flange", size: "4 - 5.0mm"},
    {orderNumber: "6CFS", application: "Adult", model: "", distal: "", cannula: "Inner Cannula", specialLength: "", cuff: "Non-Cuffed", fenestration: "Non-Fenestrated", flange: "Swivel Neck Flange", size: "6 - 6.4mm"},
    {orderNumber: "8CFS", application: "Adult", model: "", distal: "", cannula: "Inner Cannula", specialLength: "", cuff: "Non-Cuffed", fenestration: "Non-Fenestrated", flange: "Swivel Neck Flange", size: "8 - 7.6mm"},
    {orderNumber: "10CFS", application: "Adult", model: "", distal: "", cannula: "Inner Cannula", specialLength: "", cuff: "Non-Cuffed", fenestration: "Non-Fenestrated", flange: "Swivel Neck Flange", size: "10 - 8.9mm"},
    {orderNumber: "5SCT", application: "Adult", model: "", distal: "", cannula: "Single Cannula", specialLength: "", cuff: "Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "5 - 5.0mm"},
    {orderNumber: "6SCT", application: "Adult", model: "", distal: "", cannula: "Single Cannula", specialLength: "", cuff: "Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "6 - 6.0mm"},
    {orderNumber: "7SCT", application: "Adult", model: "", distal: "", cannula: "Single Cannula", specialLength: "", cuff: "Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "7 - 7.0mm"},
    {orderNumber: "8SCT", application: "Adult", model: "", distal: "", cannula: "Single Cannula", specialLength: "", cuff: "Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "8 - 8.0mm"},
    {orderNumber: "8SCT", application: "Adult", model: "", distal: "", cannula: "Single Cannula", specialLength: "", cuff: "Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "9 - 9.0mm"},
    {orderNumber: "10SCT", application: "Adult", model: "", distal: "", cannula: "Single Cannula", specialLength: "", cuff: "Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "10 - 10.0mm"},
    {orderNumber: "4DFEN", application: "Adult", model: "", distal: "", cannula: "Disposable Inner Cannula", specialLength: "", cuff: "Cuffed", fenestration: "Fenestrated", flange: "", size: "4 - 9.4mm"},
    {orderNumber: "6DFEN", application: "Adult", model: "", distal: "", cannula: "Disposable Inner Cannula", specialLength: "", cuff: "Cuffed", fenestration: "Fenestrated", flange: "", size: "6 - 10.8mm"},
    {orderNumber: "8DFEN", application: "Adult", model: "", distal: "", cannula: "Disposable Inner Cannula", specialLength: "", cuff: "Cuffed", fenestration: "Fenestrated", flange: "", size: "8 - 12.2mm"},
    {orderNumber: "10DFEN", application: "Adult", model: "", distal: "", cannula: "Disposable Inner Cannula", specialLength: "", cuff: "Cuffed", fenestration: "Fenestrated", flange: "", size: "10 - 13.8mm"},
    {orderNumber: "4DCT", application: "Adult", model: "", distal: "", cannula: "Disposable Inner Cannula", specialLength: "", cuff: "Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "4 - 9.4mm"},
    {orderNumber: "6DCT", application: "Adult", model: "", distal: "", cannula: "Disposable Inner Cannula", specialLength: "", cuff: "Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "6 - 10.8mm"},
    {orderNumber: "8DCT", application: "Adult", model: "", distal: "", cannula: "Disposable Inner Cannula", specialLength: "", cuff: "Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "8 - 12.2mm"},
    {orderNumber: "10DCT", application: "Adult", model: "", distal: "", cannula: "Disposable Inner Cannula", specialLength: "", cuff: "Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "10 - 13.8mm"},
    {orderNumber: "4FEN", application: "Adult", model: "", distal: "", cannula: "Inner Cannula", specialLength: "", cuff: "Cuffed", fenestration: "Fenestrated", flange: "", size: "4 - 5.0mm"},
    {orderNumber: "6FEN", application: "Adult", model: "", distal: "", cannula: "Inner Cannula", specialLength: "", cuff: "Cuffed", fenestration: "Fenestrated", flange: "", size: "6 - 6.4mm"},
    {orderNumber: "8FEN", application: "Adult", model: "", distal: "", cannula: "Inner Cannula", specialLength: "", cuff: "Cuffed", fenestration: "Fenestrated", flange: "", size: "8 - 7.6mm"},
    {orderNumber: "10FEN", application: "Adult", model: "", distal: "", cannula: "Inner Cannula", specialLength: "", cuff: "Cuffed", fenestration: "Fenestrated", flange: "", size: "10 - 8.9mm"},
    {orderNumber: "4LPC", application: "Adult", model: "", distal: "", cannula: "Inner Cannula", specialLength: "", cuff: "Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "4 - 5.0mm"},
    {orderNumber: "6LPC", application: "Adult", model: "", distal: "", cannula: "Inner Cannula", specialLength: "", cuff: "Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "6 - 6.4mm"},
    {orderNumber: "8LPC", application: "Adult", model: "", distal: "", cannula: "Inner Cannula", specialLength: "", cuff: "Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "8 - 7.6mm"},
    {orderNumber: "10LPC", application: "Adult", model: "", distal: "", cannula: "Inner Cannula", specialLength: "", cuff: "Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "10 - 8.9mm"},
    {orderNumber: "4DCFN", application: "Adult", model: "", distal: "", cannula: "Disposable Inner Cannula", specialLength: "", cuff: "Non-Cuffed", fenestration: "Fenestrated", flange: "", size: "4 - 9.4mm"},
    {orderNumber: "6DCFN", application: "Adult", model: "", distal: "", cannula: "Disposable Inner Cannula", specialLength: "", cuff: "Non-Cuffed", fenestration: "Fenestrated", flange: "", size: "6 - 10.8mm"},
    {orderNumber: "8DCFN", application: "Adult", model: "", distal: "", cannula: "Disposable Inner Cannula", specialLength: "", cuff: "Non-Cuffed", fenestration: "Fenestrated", flange: "", size: "8 - 12.2mm"},
    {orderNumber: "10DCFN", application: "Adult", model: "", distal: "", cannula: "Disposable Inner Cannula", specialLength: "", cuff: "Non-Cuffed", fenestration: "Fenestrated", flange: "", size: "10 - 13.8mm"},
    {orderNumber: "4DCFS", application: "Adult", model: "", distal: "", cannula: "Disposable Inner Cannula", specialLength: "", cuff: "Non-Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "4 - 9.4mm"},
    {orderNumber: "6DCFS", application: "Adult", model: "", distal: "", cannula: "Disposable Inner Cannula", specialLength: "", cuff: "Non-Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "6 - 10.8mm"},
    {orderNumber: "8DCFS", application: "Adult", model: "", distal: "", cannula: "Disposable Inner Cannula", specialLength: "", cuff: "Non-Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "8 - 12.2mm"},
    {orderNumber: "10DCFS", application: "Adult", model: "", distal: "", cannula: "Disposable Inner Cannula", specialLength: "", cuff: "Non-Cuffed", fenestration: "Non-Fenestrated", flange: "", size: "10 - 13.8mm"},
  ],
]
export var oxygenFlow = [0.0625, 0.125, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3]
export var maskSizes = ["Petite", "Small", "Medium", "Large", "Other"]
export var maskStyles = ["Nasal", "Full Face", "Nasal Pillow", "Nasal Prong", "Oral"]
export var catheterSizes = ["5 French", "6 French", "8 French", "10 French", "12 French", "14 French", "16 French", "18 French", "N205 Little Suckers", "Yaunkers"]
export var formulas = [
  "Breast Milk", "Bright Beginnings Pediatric", "Enfacare", "Jevity 1.2",
  "Jevity 1.5", "Microlipid", "Mighty Feeds (Bolus)", "Neocate Infant 24 Calorie",
  "Neosure 22 Calorie", "Neosure 27", "Nutren 1.0", "Nutren 2.0", "Nutren Junior",
  "Pediasure", "Pediasure 1.5 Calorie", "Pediasure 1.5 Calorie with Fiber",
  "Pediasure 1.5 with Fiber", "Peptamen 1.0", "Peptamen Junior", "Peptamen with Fiber",
  "Peptinex", "Peptinex Junior", "Similac", "Similac 20 Calorie", "Similac Advance",
  "Similac Advance 27 Calorie", "Similac Alimentum Powder", "Similac Sensitive 20 Calorie", "Vivonex", "Whole Milk"]


export var departmentList = [
  "Allergy Asthma, Immunology",
  "Anesthesiology",
  "Behavioral Health",
  "Cardiology",
  "Cleft Craniofacial",
  "Virology",
  "Dermatology",
  "Endocrinology",
  "Epidemiology ",
  "Gastroenterology/Nutrition",
  "Genetics",
  "Hearing Speech",
  "Hematology/Oncology",
  "Infectious Disease/Virology",
  "Lion's Eye Clinic",
  "Neonatology",
  "Nephrology",
  "Neuro·Oncology",
  "Neurology",
  "Neurosurgery",
  "Newborn Follow-Up Clinic",
  "Obstetrics and Gynecology",
  "Occupational Therapy",
  "Opthalmology",
  "Orthopaedic",
  "Otolaryngology",
  "Pathology",
  "General Surgery",
  "Plastic Surgery",
  "Pulmonary",
  "Radiology",
  "Physical Therapy/Rehab Medicine",
  "Retinology",
  "Rheumatology",
  "Spina Bifida Clinic",
  "Sleep Disorders Center",
  "Urology",
  "Virology"
]

export var detailedDepartmentList = [
  "Adolescent Medicine Department, Adolescent Medicine Specialist, Adolescent Medicine Specialists",
  "Allergy Asthma Immunology Department, Allergist/Asthma Immunologist, Allergist/Asthma Immunologists",
  "Anesthesiology Department, Anesthesiologist, Anesthesiologists",
  "Behavioral Health Department, Behavioral Health Specialist, Behavioral Health Specialists",
  "Cardiology Department, Cardiologist, Cardiologists",
  "Cleft Craniofacial Department, Cleft Craniofacial Specialist, Cleft Craniofacial Specialists",
  "Virology Department, Virologist, Virologists",
  "Dermatology Department, Dermatologist, Dermatologists",
  "Endocrinology Department, Endocrinologist, Endocrinologists",
  "Epidemiology  Department, Epidemiologist, Epidemiologists",
  "Gastroenterology/Nutrition Department, Gastroenterologist, Gastroenterologists",
  "Genetics Department, Geneticist/Genetician Specialist, Geneticist/Genetician Specialists",
  "Hearing Speech Department, Hearing Speech Specialist, Hearing Speech Specialists",
  "Hematology/Oncology Department, Hematologist/Oncologist, Hematologist/Oncologists",
  "Infectious Disease/Virology Department, Infectious Disease/Virologist Specialist, Infectious Disease/Virologist Specialists",
  "Lion's Eye Clinic, Eye Specialist, Eye Specialists",
  "Neonatology Department, Neonatologist, Neonatologists",
  "Nephrology Department, Nephrologists, Nephrologistss",
  "Neuro·Oncology Department, Neuro-Oncologist, Neuro-Oncologists",
  "Neurology Department, Neurologist, Neurologists",
  "Neurosurgery Department, Neurosurgeon, Neurosurgeons",
  "Newborn Follow-Up Clinic, Newborn Specialist, Newborn Specialists",
  "Obstetrics and Gynecology Department, Obstetrician/Gynecologist, Obstetricians/Gynecologists",
  "Occupational Therapy, Occupational Therapist/Specialist, Occupational Therapists/Specialists",
  "Opthalmology Department, Ophthalmologist/Optometrist/Optician, Ophthalmologists/Optometrists/Opticians",
  "Orthopaedic Department, Orthopedists/Orthopaedic Specialist, Orthopedists/Orthopaedic Specialists",
  "Otolaryngology Department, Otolaryngologist/Ear/Nose/Throat Specialist, Otolaryngologist/Ear/Nose/Throat Specialists",
  "Pathology Department, Pathologist, Pathologists",
  "General Surgery Department, General Surgeon, General Surgeons",
  "Plastic Surgery Department, Plastic Surgeon, Plastic Surgeons",
  "Pulmonary Department, Pulmonologist, Pulmonologists",
  "Radiology Department, Radiologist, Radiologists",
  "Physical Therapy/Rehab Medicine Department, Physicial Therapist/Rehab Specialist, Physicial Therapists/Rehab Specialists",
  "Retinology Department, Retinologist, Retinologists",
  "Rheumatology Department, Rheumatologist, Rheumatologists",
  "Spina Bifida Clinic, Orthodists/Other Spina Bifida Specialist, Orthodists/Other Spina Bifida Specialists",
  "Sleep Disorders Center, Sleep Doctor/Specialist, Sleep Doctors/Specialists",
  "Urology Department, Urologist, Urologists",
  "Virology Department, Virologist, Virologists",
  "None, None, None"
]
