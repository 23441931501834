import React, {useEffect, useState} from 'react';
import './App.css';
import { useHistory } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

function Invitation(props) {
  const [state, setState] = useState({
    isInvited: false
  })

  const history = useHistory();
  useEffect(() => {
    async function checkInvite(){
      var verified = await props.validateInvite(props.match.params.uid)
      if(!verified){
        history.push("/");
      }
      else{
        setState({...state, isInvited: true})
      }
    }
    checkInvite()
  }, [])

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  }

  const tryCreateAccount = async () => {
    var res = await fetch(props.apiURL + '/processInviteData', {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        password: state.password,
        data: props.match.params.uid
      })
  })
  var data = await res.json();
  if(data.status){
    console.log("It worked!")
    history.push("/login");
  }
  else{
    console.log("Reg failed")
  }
  }

    return (
      <div className="formBack" style={{
        backgroundImage: 'url("https://images.pexels.com/photos/40568/medical-appointment-doctor-healthcare-40568.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260")',
        backgroundSize: "cover", textAlign: "center", position: "fixed", width: "100vw"}}>
        <div style={{width: "300px", margin: "0 auto", textAlign: "center"}}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
        <Card style={{width: "90%", margin: "80px auto", textAlign: "center"}}>
          <CardContent>
          <Typography variant="h5" component="h2">
            Sign Up!
          </Typography>
          <Typography align="justify" color="textSecondary" gutterBottom>
          Welcome to Medical Research. Please Enter a Password for Your Account.
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField fullWidth margin="normal" type="password" id="standard-basic" variant="outlined" label="Password" onChange={handleChange} value={state.password} name="password" />
            </Grid>
          </Grid>
          <Button variant="contained" color="primary" onClick={tryCreateAccount}>Create Account</Button>
          </CardContent>
        </Card>
        </Grid>
      </Grid>
      </div>
      </div>
    );

}

export default Invitation;
