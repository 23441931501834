import React, {Component} from 'react';
import '../App.css';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import {catheterSizes} from '../enums.js';
import Icon from '@mdi/react'
import { mdiDelete, mdiPlus } from '@mdi/js';
import {romanize} from "../utils.js"

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    marginTop: "16px"
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
    },
  },
  fullHeightCard: {
		height: "100%",
	},
  halfWidth: {
    width: "50%"
  },
  quartWidth:{
    width: "25%"
  },
  threeQuartWidth:{
    width: "75%"
  },
  commentWidth:{
    marginTop: "8px",
    width: "calc(100% - 69px)"
  },
  delButton:{
    marginTop: "8px",
    marginRight: "4px",
    height: "40px"
  },
  otherBox:{
    marginTop: "5px",
    marginBottom: "8px",
    width: "calc(100% - 50px)",
    marginLeft: "0px"
  }
}));

function SuctionMachineInfo(props) {
  const classes = useStyles();

  return (
    <div style={{padding: "20px"}}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Card className={classes.fullHeightCard}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Suction Machine Information
              </Typography>

              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel>Mobility</InputLabel>
                <Select name="mobility" value={props.patient.FormData[3].requiredFields.mobility} onChange={(e) => props.modifyForm(3, e)} label="Mobility" >
                  <MenuItem value={"Independently powered by internal battery"}>Independently powered by internal battery</MenuItem>
                  <MenuItem value={"AC Only - No internal battery"}>AC Only - No internal battery</MenuItem>
                </Select>
              </FormControl>
              <br/>
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel>Catheter Size</InputLabel>
                <Select name="catheterSize" error={props.checkFieldValidity(3, "catheterSize").valid && props.highestStep > 3}
                value={props.patient.FormData[3].requiredFields.catheterSize} onChange={(e) => props.modifyForm(3, e)} label="Catheter Size" >
                  {catheterSizes.map((e, i) => <MenuItem value={e}>{e}</MenuItem>)}
                </Select>
              </FormControl>

              <Typography color="textSecondary" gutterBottom>
                Justification
              </Typography>

              <Grid container spacing={1}>
                {Object.entries(props.patient.FormData[3].requiredFields.justifications).map((e, i) => <Grid item xs={12}>
                {e[0] != "Other" ?
                <FormControlLabel control={
                  <Checkbox name={e} color="primary" checked={props.patient.FormData[3].requiredFields.justifications[i]} onClick={() => {
                    var just = props.patient.FormData[3].requiredFields.justifications;
                    just[e[0]] = !just[e[0]];
                    props.modifyForm(3, just, "justifications")
                  }} />} label={e}/>
                :
                <FormControlLabel control={
                  <div>
                  <Checkbox name={e} color="primary" checked={props.patient.FormData[3].requiredFields.justifications[e[0]] != ""} />
                  <TextField className={classes.otherBox} margin="normal" label="" placeholder="Other" name="otherEquipment" className={classes.otherBox} value={props.patient.FormData[3].requiredFields.justifications[i]} onChange={(f) => {
                    var just = props.patient.FormData[3].requiredFields.justifications;
                    just[e[0]] = f.target.value;
                    props.modifyForm(3, just, "justifications")}}/>
                    </div>} label=""/>
                }
                </Grid>)}
              </Grid>

            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={9}>
        <Card className={classes.fullHeightCard}>
        <CardContent style={{position: "relative"}}>
          <Typography color="textSecondary" gutterBottom>
            Comments
          </Typography>
          <Button style={{position: "absolute", right: "17px", top: "10px"}} variant="contained" color="primary" onClick={(e) => props.addRemoveComment(e, 3, "add")}><Icon path={mdiPlus} size={1}/></Button>
          <p className="recommended"><b>Related Equipment & Supply Items may Include: </b>
          <br/>E1399 - Suction Machine Auto Adapter
          <br/>A9900 - Suction Machine Bacteria Filters
          <br/>A4624 - Tracheal Suction Catheters
          <br/>A7002 - Suction Machine Tubing
          <br/>A7000 - Suction Machine Cannister
          <br/>A4628 - Oropharyngeal Suction Catheter (Lil Suckers)</p>


          {props.patient.FormData[3].requiredFields.comments.map((e, i) =>
            <div style={{width: "100%"}}>
            <Button className={classes.delButton} variant="contained" color="secondary" onClick={(e) => props.addRemoveComment(i, 3, "remove")}><Icon path={mdiDelete} size={1}/></Button>
            <TextField className={classes.commentWidth} multiline rowsMax={4} size="small" margin="normal" variant="outlined" label={"Comment " + romanize(i + 1)} name={i.toString()} value={e} onChange={(e) => props.addRemoveComment(e, 3, "modify")} />
            </div>
          )}

        </CardContent>
        </Card>
        </Grid>



      </Grid>
    </div>
  );

}

export default SuctionMachineInfo;
