import React, {useEffect} from 'react';
import '../App.css';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import {formulas} from "../enums.js"
import Icon from '@mdi/react'
import { mdiDelete, mdiPlus } from '@mdi/js';
import {romanize} from "../utils.js"

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    marginTop: "16px",
    marginBottom: "8px"
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      //margin: theme.spacing(1),
    },
  },
  fullHeightCard: {
		height: "100%",
	},
  halfWidth: {
    width: "50%"
  },
  quartWidth:{
    width: "25%"
  },
  threeQuartWidth:{
    width: "75%"
  },
  commentWidth:{
    marginTop: "8px",
    width: "calc(100% - 69px)"
  },
  delButton:{
    marginTop: "8px",
    marginRight: "4px",
    height: "40px"
  },
}));

function EnteralInfo(props) {
  const classes = useStyles();

  useEffect(() => {
    var tot = 0;
    switch (props.patient.FormData[5].requiredFields.nutritionEquipment){
      case "Pump":
        tot += props.patient.FormData[5].requiredFields.pumpInfoCC * props.patient.FormData[5].requiredFields.pumpInfoTimes;
        break;
      case "Syringe":
        tot += props.patient.FormData[5].requiredFields.syringeInfoCC * props.patient.FormData[5].requiredFields.syringeInfoTimes;
        break;
      case "Pump and Syringe":
        tot += props.patient.FormData[5].requiredFields.pumpInfoCC * props.patient.FormData[5].requiredFields.pumpInfoTimes;
        tot += props.patient.FormData[5].requiredFields.syringeInfoCC * props.patient.FormData[5].requiredFields.syringeInfoTimes;
        break;
    }
    console.log("EFFECT USED")
    props.modifyForm(5, tot, "totalThroughEnteral")
    props.modifyForm(5, Math.ceil(tot / (props.patient.FormData[5].requiredFields.calorieNeeds > 0 ? props.patient.FormData[5].requiredFields.calorieNeeds : 1) * 100) + "%", "enteralCalorieIntake")
  },[props.patient.FormData[5].requiredFields.pumpInfoCC, props.patient.FormData[5].requiredFields.syringeInfoCC, props.patient.FormData[5].requiredFields.pumpInfoTimes, props.patient.FormData[5].requiredFields.syringeInfoTimes, props.patient.FormData[5].requiredFields.calorieNeeds])

  return (
    <div style={{padding: "20px"}}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card className={classes.fullHeightCard}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Enteral Information
              </Typography>

              <Grid container spacing={3}>
                <Grid item xs={6}>
              <form className={classes.root} noValidate autoComplete="off">
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel>Nutrition Equipment</InputLabel>
                <Select name="nutritionEquipment" value={props.patient.FormData[5].requiredFields.nutritionEquipment} onChange={(e) => props.modifyForm(5, e)} label="Nutrition Equipment" >
                  <MenuItem value={"Pump"}>Pump</MenuItem>
                  <MenuItem value={"Syringe"}>Syringe</MenuItem>
                  <MenuItem value={"Pump and Syringe"}>Pump and Syringe</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel>Administration</InputLabel>
                <Select name="methodOfAdministration" value={props.patient.FormData[5].requiredFields.methodOfAdministration} onChange={(e) => props.modifyForm(5, e)} label="Administration" >
                  <MenuItem value={"Abdominal (G-Tube - B4088)"}>Abdominal (G-Tube - B4088)</MenuItem>
                  <MenuItem value={"Nasal (NG-Tube - B4086)"}>Nasal (NG-Tube - B4086)</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel>Formula</InputLabel>
                <Select name="formula" value={props.patient.FormData[5].requiredFields.formula} onChange={(e) => props.modifyForm(5, e)} label="Formula" >
                  {formulas.map((e) => <MenuItem value={e}>{e}</MenuItem>)}
                </Select>
              </FormControl>
              </form>

              {props.patient.FormData[5].requiredFields.nutritionEquipment == "Syringe" || props.patient.FormData[5].requiredFields.nutritionEquipment == "Pump and Syringe" ?
                <div>
                <TextField fullWidth margin="normal" variant="outlined" label="Syringe CC" name="syringeInfoCC" error={props.checkFieldValidity(5, "syringeInfoCC").valid && props.highestStep > 5}
                value={props.patient.FormData[5].requiredFields.syringeInfoCC} onChange={(e) => props.modifyForm(5, e)} />
                <TextField fullWidth margin="normal" variant="outlined" label="Syringe Times Per Day" name="syringeInfoTimes" error={props.checkFieldValidity(5, "syringeInfoTimes").valid && props.highestStep > 5}
                value={props.patient.FormData[5].requiredFields.syringeInfoTimes} onChange={(e) => props.modifyForm(5, e)} />
                </div>
              :null}

              </Grid>

              <Grid item xs={6}>
              {props.patient.FormData[5].requiredFields.nutritionEquipment == "Pump" || props.patient.FormData[5].requiredFields.nutritionEquipment == "Pump and Syringe" ?
                <div>
                <TextField fullWidth type="number" margin="normal" variant="outlined" label="Pump CC" name="pumpInfoCC" error={props.checkFieldValidity(5, "pumpInfoCC").valid && props.highestStep > 5}
                value={props.patient.FormData[5].requiredFields.pumpInfoCC} onChange={(e) => props.modifyForm(5, e)} />
                <TextField fullWidth type="number" margin="normal" variant="outlined" label="Pump Times Per Day" name="pumpInfoTimes" error={props.checkFieldValidity(5, "pumpInfoTimes").valid && props.highestStep > 5}
                value={props.patient.FormData[5].requiredFields.pumpInfoTimes} onChange={(e) => props.modifyForm(5, e)} />
                </div>
              :null}

              <TextField fullWidth type="number" margin="normal" variant="outlined" label="Calorie Needs" name="calorieNeeds" error={props.checkFieldValidity(5, "calorieNeeds").valid && props.highestStep > 5}
              value={props.patient.FormData[5].requiredFields.calorieNeeds} onChange={(e) => props.modifyForm(5, e)} />
              <TextField fullWidth type="number" margin="normal" variant="outlined" label="Total Through Enteral" name="totalThroughEnteral" disabled
              value={props.patient.FormData[5].requiredFields.totalThroughEnteral} />
              <TextField fullWidth margin="normal" variant="outlined" label="Enteral Administration Provides" name="enteralCalorieIntake" disabled
              value={props.patient.FormData[5].requiredFields.enteralCalorieIntake} />
              </Grid>
              </Grid>


            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
        <Card className={classes.fullHeightCard}>
        <CardContent style={{position: "relative"}}>
          <Typography color="textSecondary" gutterBottom>
            Comments
          </Typography>
          <Button style={{position: "absolute", right: "17px", top: "10px"}} variant="contained" color="primary" onClick={(e) => props.addRemoveComment(e, 5, "add")}><Icon path={mdiPlus} size={1}/></Button>
          <p className="recommended"><b>Related Equipment & Supply Items may Include: </b>
          <br/>B9998 - Extension Sets</p>

          {props.patient.FormData[5].requiredFields.comments.map((e, i) =>
            <div style={{width: "100%"}}>
            <Button className={classes.delButton} variant="contained" color="secondary" onClick={(e) => props.addRemoveComment(i, 5, "remove")}><Icon path={mdiDelete} size={1}/></Button>
            <TextField className={classes.commentWidth} multiline rowsMax={4} size="small" margin="normal" variant="outlined" label={"Comment " + romanize(i + 1)} name={i.toString()} value={e} onChange={(e) => props.addRemoveComment(e, 5, "modify")} />
            </div>
          )}

        </CardContent>
        </Card>
        </Grid>



      </Grid>
    </div>
  );

}

export default EnteralInfo;
