import React, {Component} from 'react';
import '../App.css';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import DateFnsUtils from '@date-io/date-fns';
import InputAdornment from '@material-ui/core/InputAdornment';
import OxygenProjections from './OxygenProjections.js'
import {MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker} from '@material-ui/pickers';
import Icon from '@mdi/react'
import { mdiDelete, mdiPlus } from '@mdi/js';
import {romanize, practicionerList, clinicianList} from "../utils.js"
import {oxygenFlow} from "../enums.js"

const useStyles = makeStyles((theme) => ({
  formControl: {
//margin: theme.spacing(1),
    minWidth: 120,
    marginTop: "16px",
    marginBottom: "8px"
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
//margin: theme.spacing(1),
      //width: '25ch',
    },
  },
  fullHeightCard: {
		height: "100%",
	},
  halfWidth: {
    width: "50%"
  },
  quartWidth:{
    width: "25%"
  },
  threeQuartWidth:{
    width: "75%"
  },
  commentWidth:{
    marginTop: "8px",
    width: "calc(100% - 69px)"
  },
  delButton:{
    marginTop: "8px",
    marginRight: "4px",
    height: "40px"
  }
}));

function Oxygen360(props) {
  const classes = useStyles();

  return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
        <Typography color="textSecondary" gutterBottom>
          Patient Info
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField className={classes.quartWidth} margin="normal" variant="outlined" label="Suffix" name="patientSuffix" error={props.checkFieldValidity(0, "patientSuffix").valid && props.highestStep > 0}
            value={props.patient.FormData[0].requiredFields.patientSuffix} onChange={(e) => props.modifyForm(0, e)} />
            <TextField className={classes.threeQuartWidth} margin="normal" variant="outlined" label="Last Name" name="patientLastName" error={props.checkFieldValidity(0, "patientLastName").valid && props.highestStep > 0}
            value={props.patient.FormData[0].requiredFields.patientLastName} onChange={(e) => props.modifyForm(0, e)} />
            <TextField className={classes.halfWidth} margin="normal" variant="outlined" label="First Name" name="patientFirstName" error={props.checkFieldValidity(0, "patientFirstName").valid && props.highestStep > 0}
            value={props.patient.FormData[0].requiredFields.patientFirstName} onChange={(e) => props.modifyForm(0, e)}/>
            <TextField className={classes.halfWidth} margin="normal" variant="outlined" label="Middle Name" name="patientMiddleName" error={props.checkFieldValidity(0, "patientMiddleName").valid && props.highestStep > 0}
            value={props.patient.FormData[0].requiredFields.patientMiddleName} onChange={(e) => props.modifyForm(0, e)}/>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker disableToolbar variant="inline" fullWidth format="MM/dd/yyyy" margin="normal" label="Date of Birth" error={props.checkFieldValidity(0, "patientBirth").valid && props.highestStep > 0}
            value={props.patient.FormData[0].requiredFields.patientBirth} style={{marginTop: "24px"}}
              onChange={(e) => props.modifyForm(0, e, "patientBirth")} KeyboardButtonProps={{'aria-label': 'change date',}}/>
            </MuiPickersUtilsProvider>
            <TextField fullWidth margin="normal" variant="outlined" label="MR" name="patientMR" error={props.checkFieldValidity(0, "patientMR").valid && props.highestStep > 0}
            value={props.patient.FormData[0].requiredFields.patientMR} onChange={(e) => props.modifyForm(0, e)} />
            <TextField fullWidth margin="normal" variant="outlined" label="Diagnosis" name="oxygenDiagnosis" error={props.checkFieldValidity(7, "oxygenDiagnosis").valid && props.highestStep > 7}
            value={props.patient.FormData[7].requiredFields.oxygenDiagnosis} onChange={(e) => props.modifyForm(7, e)} />
          </Grid>
        </Grid>

        <Typography color="textSecondary" gutterBottom>
          Prescription Info
        </Typography>
        <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
        <FormControl fullWidth variant="outlined" className={classes.formControl}>
          <InputLabel>Litre Flow</InputLabel>
          <Select name="oxygenLitreFlow" value={props.patient.FormData[7].requiredFields.oxygenLitreFlow} onChange={(e) => props.modifyForm(7, e)} label="Litre Flow" >
            {oxygenFlow.map((e) => <MenuItem value={e}>{e + "Litres"}</MenuItem>)}
          </Select>
        </FormControl>

        <FormControl fullWidth variant="outlined" className={classes.formControl}>
          <InputLabel>Method of Delivery</InputLabel>
          <Select name="oxygenMethod" value={props.patient.FormData[7].requiredFields.oxygenMethod} onChange={(e) => props.modifyForm(7, e)} label="Method of Delivery" >
          <MenuItem value={"Nasal Canula"}>Nasal Canula</MenuItem>
          <MenuItem value={"Tracheostomy Mask"}>Tracheostomy Mask</MenuItem>
          <MenuItem value={"Ventilator"}>Ventilator</MenuItem>
          <MenuItem value={"CPAP Mask"}>CPAP Mask</MenuItem>
          <MenuItem value={"BiPaP Mask"}>BiPaP Mask</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth variant="outlined" className={classes.formControl}>
          <InputLabel>Prescribed Usage</InputLabel>
          <Select name="oxygenUsage" value={props.patient.FormData[7].requiredFields.oxygenUsage} onChange={(e) => props.modifyForm(7, e)} label="Prescribed Usage" >
            <MenuItem value={"Continuously"}>Continuously</MenuItem>
            <MenuItem value={"While Asleep/Unattended"}>While Asleep/Unattended</MenuItem>
            <MenuItem value={"While Asleep (6+ hours/day)"}>While Asleep (6+ hours/day)</MenuItem>
          </Select>
        </FormControl>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker disableToolbar variant="inline" fullWidth format="MM/dd/yyyy" margin="normal" label="Date Prescribed" error={props.checkFieldValidity(7, "oxygenDatePrescribed").valid && props.highestStep > 7}
        value={props.patient.FormData[7].requiredFields.oxygenDatePrescribed} style={{marginTop: "24px"}}
          onChange={(e) => props.modifyForm(7, e, "oxygenDatePrescribed")} KeyboardButtonProps={{'aria-label': 'change date',}}/>
        </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={6}>
        <TextField fullWidth margin="normal" variant="outlined" label="Hours Per Day" name="oxygenHours"
        value={props.patient.FormData[7].requiredFields.oxygenHours} onChange={(e) => props.modifyForm(7, e)} />
        <TextField fullWidth margin="normal" variant="outlined" label="Length of Time" name="oxygenTimeLength"
        value={props.patient.FormData[7].requiredFields.oxygenTimeLength} onChange={(e) => props.modifyForm(7, e)} />
        <FormControlLabel control={
          <Checkbox color="primary" checked={props.patient.FormData[7].requiredFields.oxygenInitial} onChange={(e) => props.modifyForm(7, "checkbox", "oxygenInitial")} />} label="Initial Prescription"/>
        <FormControlLabel control={
          <Checkbox color="primary" checked={!props.patient.FormData[7].requiredFields.oxygenInitial} onChange={(e) => props.modifyForm(7, "checkbox", "oxygenInitial")} />} label="Renewal"/>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker disableToolbar variant="inline" fullWidth format="MM/dd/yyyy" margin="normal" label="Date Last Seen" error={props.checkFieldValidity(7, "oxygenLastSeen").valid && props.highestStep > 7}
        value={props.patient.FormData[7].requiredFields.oxygenLastSeen} style={{marginTop: "24px"}}
          onChange={(e) => props.modifyForm(7, e, "oxygenLastSeen")} KeyboardButtonProps={{'aria-label': 'change date',}}/>
        </MuiPickersUtilsProvider>
        </Grid>
        </Grid>

        <Grid item xs={12}>
        <TextField fullWidth margin="normal" variant="outlined" label="Purpose (if portable oxygen prescribed)" name="oxygenPurpose"
        value={props.patient.FormData[7].requiredFields.oxygenPurpose} onChange={(e) => props.modifyForm(7, e)} />

        <TextField fullWidth margin="normal" variant="outlined" label="Type, Duration and Frequency of Patient Activities" name="oxygenActivities"
        value={props.patient.FormData[7].requiredFields.oxygenActivities} onChange={(e) => props.modifyForm(7, e)} />
        </Grid>


        </Grid>
        <Grid item xs={12} md={6}>

        <Grid container spacing={3}>

          <Grid item xs={12}>
          <Typography color="textSecondary" gutterBottom>Equipment Prescribed</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
          <Typography color="textSecondary" gutterBottom>Stationary System</Typography>
          <FormControlLabel control={
            <Checkbox color="primary" checked={props.patient.FormData[7].requiredFields.stationaryCompressed} onClick={(e) => props.modifyForm(7, "checkbox", "stationaryCompressed")} />} label="Compressed Gas"/>
          <FormControlLabel control={
            <Checkbox color="primary" checked={props.patient.FormData[7].requiredFields.stationaryConcentrator} onClick={(e) => props.modifyForm(7, "checkbox", "stationaryConcentrator")} />} label="Oxygen Concentrator"/>
          <Typography color="textSecondary" gutterBottom>Portable System</Typography>
          <FormControlLabel control={
            <Checkbox color="primary" checked={props.patient.FormData[7].requiredFields.portableCompressed} onClick={(e) => props.modifyForm(7, "checkbox", "portableCompressed")} />} label="Compressed Gas"/>
          </Grid>
          <Grid item xs={12} md={6}>
          <Typography color="textSecondary" gutterBottom>Ventilator System</Typography>
          <FormControlLabel control={
            <Checkbox color="primary" checked={props.patient.FormData[7].requiredFields.oxygenVentDep} onClick={(e) => props.modifyForm(7, "checkbox", "oxygenVentDep")} />} label="Patient Ventilator Dependant?"/>
          </Grid>

          <Grid item xs={12}>
          <Typography color="textSecondary" gutterBottom>Laboratory Results</Typography>
          <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
          <TextField fullWidth margin="normal" variant="outlined" label="ABG (P02) Result" name="oxygenABG"
          value={props.patient.FormData[7].requiredFields.oxygenABG} onChange={(e) => props.modifyForm(7, e)} />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker disableToolbar variant="inline" fullWidth format="MM/dd/yyyy" margin="normal" label="Date Prescribed" value={props.patient.FormData[7].requiredFields.oxygenDateABG} style={{marginTop: "24px"}}
            onChange={(e) => props.modifyForm(7, e, "oxygenDateABG")} KeyboardButtonProps={{'aria-label': 'change date',}}/>
          </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={6}>
          <FormControl component="fieldset">
          {Object.entries(props.patient.FormData[7].requiredFields.oxygenABGType).map((e, i) =>
            <FormControlLabel control={
              <Checkbox color="primary" checked={props.patient.FormData[7].requiredFields.oxygenABGType[e[0]]} onChange={() => {
                var just = props.patient.FormData[7].requiredFields.oxygenABGType;
                just[e[0]] = !just[e[0]];
                props.modifyForm(7, just, "oxygenABGType")
              }} />} label={e}/>)}
          </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
          <TextField fullWidth margin="normal" variant="outlined" label="Oxygen Saturation" name="oxygenSaturation"
          value={props.patient.FormData[7].requiredFields.oxygenSaturation} onChange={(e) => props.modifyForm(7, e)} />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker disableToolbar variant="inline" fullWidth format="MM/dd/yyyy" margin="normal" label="Date Prescribed" value={props.patient.FormData[7].requiredFields.oxygenDateSaturation} style={{marginTop: "24px"}}
            onChange={(e) => props.modifyForm(7, e, "oxygenDateSaturation")} KeyboardButtonProps={{'aria-label': 'change date',}}/>
          </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={6}>
          <FormControl component="fieldset">
          {Object.entries(props.patient.FormData[7].requiredFields.oxygenSaturationType).map((e, i) =>
            <FormControlLabel control={
              <Checkbox color="primary" checked={props.patient.FormData[7].requiredFields.oxygenSaturationType[e[0]]} onClick={() => {
                var just = props.patient.FormData[7].requiredFields.oxygenSaturationType;
                just[e[0]] = !just[e[0]];
                props.modifyForm(7, just, "oxygenSaturationType")
              }} />} label={e}/>)}
          </FormControl>
          </Grid>

          <Grid item xs={12}>
          <TextField fullWidth margin="normal" variant="outlined" label="If ABG Not Performed, please explain" name="oxygenABGExplanation"
          value={props.patient.FormData[7].requiredFields.oxygenABGExplanation} onChange={(e) => props.modifyForm(7, e)} />
          </Grid>

          <Grid item xs={12}>
          <TextField fullWidth margin="normal" variant="outlined" label="If test not performed on room air, please explain" name="oxygenRoomAirExplanation"
          value={props.patient.FormData[7].requiredFields.oxygenRoomAirExplanation} onChange={(e) => props.modifyForm(7, e)} />
          </Grid>


          </Grid>
          </Grid>



        </Grid>

        </Grid>
      </Grid>

  );

}

export default Oxygen360;
