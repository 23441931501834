import React from 'react';
import '../App.css';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  fullHeightCard: {
		height: "100%",
	},
  halfWidth: {
    width: "50%"
  },
  quartWidth:{
    width: "25%"
  },
  threeQuartWidth:{
    width: "75%"
  },
  commentWidth:{
    marginTop: "8px",
    width: "calc(100% - 69px)"
  },
  delButton:{
    marginTop: "8px",
    marginRight: "4px",
    height: "40px"
  }
}));

function CompletionPage(props) {
  const classes = useStyles();
  const history = useHistory();

  const attemptSave = async () => {
    var saved = await props.saveForm();
    if(saved){
      history.push("/dashboard");
    }
  }

  const attemptSaveAndPrint = async () => {
    var saved = await props.saveForm();
    console.log(saved)
    if(saved){
      await props.printForm(saved.filename);
      history.push("/dashboard");
    }
  }

  return (
    <div style={{padding: "20px"}}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card className={classes.fullHeightCard}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                CMN Completed! (Validation Disabled - Just for testing purposes, You wont be able to save and view if fields are incomplete later on)
              </Typography>

              <Button variant="contained" color="primary" onClick={attemptSave} style={{marginRight: "4px", marginTop: "4px"}}>SAVE</Button>
              <Button variant="contained" color="primary" onClick={attemptSaveAndPrint} style={{marginTop: "4px"}}>SAVE & VIEW</Button>

            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );

}

export default CompletionPage;
