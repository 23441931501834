import React, {Component} from 'react';
import '../App.css';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Slider from '@material-ui/core/Slider';
import DateFnsUtils from '@date-io/date-fns';
import InputAdornment from '@material-ui/core/InputAdornment';
import OxygenProjections from './OxygenProjections.js';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker} from '@material-ui/pickers';
import Icon from '@mdi/react'
import { mdiDelete, mdiPlus } from '@mdi/js';
import {romanize, practicionerList, clinicianList} from "../utils.js"
import {oxygenFlow, departmentList} from "../enums.js"

const useStyles = makeStyles((theme) => ({
  formControl: {
//margin: theme.spacing(1),
    minWidth: 120,
    marginTop: "16px",
    marginBottom: "8px"
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
//margin: theme.spacing(1),
      //width: '25ch',
    },
  },
  fullHeightCard: {
		height: "100%",
	},
  halfWidth: {
    width: "50%"
  },
  quartWidth:{
    width: "25%"
  },
  threeQuartWidth:{
    width: "75%"
  },
  commentWidth:{
    marginTop: "8px",
    width: "calc(100% - 69px)"
  },
  delButton:{
    marginTop: "8px",
    marginRight: "4px",
    height: "40px"
  }
}));

function OxygenEvaluation(props) {
  const classes = useStyles();

  return (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
            <Typography color="textSecondary" gutterBottom>
              Patient
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField fullWidth margin="normal" variant="outlined" label="MR" name="patientMR" error={props.checkFieldValidity(0, "patientMR").valid && props.highestStep > 0}
                value={props.patient.FormData[0].requiredFields.patientMR} onChange={(e) => props.modifyForm(0, e)} />
                <TextField fullWidth margin="normal" variant="outlined" label="Caregiver" name="oxygenCaregiver" error={props.checkFieldValidity(7, "oxygenCaregiver").valid && props.highestStep > 7}
                value={props.patient.FormData[7].requiredFields.oxygenCaregiver} onChange={(e) => props.modifyForm(7, e)} />
                <TextField fullWidth margin="normal" variant="outlined" label="Diagnosis" name="oxygenDiagnosis" error={props.checkFieldValidity(7, "oxygenDiagnosis").valid && props.highestStep > 7}
                value={props.patient.FormData[7].requiredFields.oxygenDiagnosis} onChange={(e) => props.modifyForm(7, e)} />
              </Grid>
            </Grid>

            <div style={{width: "100%"}}>
            <TextField fullWidth multiline rows={12} rowsMax={12} size="small" margin="normal" variant="outlined" label={"Comments"}
            name="evalComments"
            value={props.patient.FormData[7].requiredFields.evalComments}
            onChange={(e) => props.modifyForm(7, e)} />
            </div>

            <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel id="select-outlined-label">Clinician</InputLabel>
                <Select
                  labelId="select-outlined-label"
                  id="select-outlined"
                  value={props.patient.FormData[7].requiredFields.oxygenClinician}
                  name="oxygenClinician"
                  onChange={(e) => props.modifyForm(7, e)}
                  label="Clinician"
                >
                  {clinicianList.map((e, i) =>
                    <MenuItem key={i} value={e}>{e}</MenuItem>
                  )}
                </Select>
              </FormControl>

            </Grid>
            <Grid item xs={12} md={6}>

            <Typography color="textSecondary" gutterBottom>
            <FormControlLabel control={
              <Checkbox color="primary" checked={props.patient.FormData[7].requiredFields.oxygenInfo} onClick={(e) => props.modifyForm(7, "checkbox", "oxygenInfo")} />} label="Oxygen Info"/>
            </Typography>
            {props.patient.FormData[7].requiredFields.oxygenInfo ?
            <Grid container spacing={3}>
              <Grid item xs={6}>

              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel>Prescribed Usage</InputLabel>
                <Select name="oxygenUsage" value={props.patient.FormData[7].requiredFields.oxygenUsage} onChange={(e) => props.modifyForm(7, e)} label="Prescribed Usage" >
                  <MenuItem value={"Continuously"}>Continuously</MenuItem>
                  <MenuItem value={"While Asleep/Unattended"}>While Asleep/Unattended</MenuItem>
                  <MenuItem value={"While Asleep (6+ hours/day)"}>While Asleep (6+ hours/day)</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel>Litre Flow</InputLabel>
                <Select name="oxygenLitreFlow" value={props.patient.FormData[7].requiredFields.oxygenLitreFlow} onChange={(e) => props.modifyForm(7, e)} label="Litre Flow" >
                  {oxygenFlow.map((e) => <MenuItem value={e}>{e + " Litres"}</MenuItem>)}
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel>Method of Delivery</InputLabel>
                <Select name="oxygenMethod" value={props.patient.FormData[7].requiredFields.oxygenMethod} onChange={(e) => props.modifyForm(7, e)} label="Method of Delivery" >
                  <MenuItem value={"Nasal Canula"}>Nasal Canula</MenuItem>
                  <MenuItem value={"Tracheostomy Mask"}>Tracheostomy Mask</MenuItem>
                  <MenuItem value={"Ventilator"}>Ventilator</MenuItem>
                  <MenuItem value={"CPAP Mask"}>CPAP Mask</MenuItem>
                  <MenuItem value={"BiPaP Mask"}>BiPaP Mask</MenuItem>
                </Select>
              </FormControl>

              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth margin="normal" variant="outlined" label="Usage Adjustments" InputProps={{startAdornment: <InputAdornment position="start">↑↓</InputAdornment>}}
                name="usageAdjustments" value={props.patient.FormData[7].requiredFields.usageAdjustments} onChange={(e) => props.modifyForm(7, e)} />
                <TextField fullWidth margin="normal" variant="outlined" label="Flow Adjustments" InputProps={{startAdornment: <InputAdornment position="start">↑↓</InputAdornment>}}
                name="flowAdjustments" value={props.patient.FormData[7].requiredFields.flowAdjustments} onChange={(e) => props.modifyForm(7, e)} />
                <TextField fullWidth margin="normal" variant="outlined" label="Delivery Adjustments" InputProps={{startAdornment: <InputAdornment position="start">∆</InputAdornment>}}
                name="deliveryAdjustments" value={props.patient.FormData[7].requiredFields.deliveryAdjustments} onChange={(e) => props.modifyForm(7, e)} />

              </Grid>
              </Grid>
              :null}

              <Typography color="textSecondary" gutterBottom>
              <FormControlLabel control={
                <Checkbox color="primary" checked={props.patient.FormData[7].requiredFields.oximeterInfo} onClick={(e) => props.modifyForm(7, "checkbox", "oximeterInfo")} />} label="Oximeter Info"/>
              </Typography>

              {props.patient.FormData[7].requiredFields.oximeterInfo ?
              <Grid container spacing={3}>
              <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel>Usage</InputLabel>
                <Select name="oximeterUsage" value={props.patient.FormData[8].requiredFields.oximeterUsage} onChange={(e) => props.modifyForm(8, e)} label="Usage" >
                <MenuItem value={"Continuously"}>Continuously</MenuItem>
                <MenuItem value={"While asleep/unattended"}>While asleep/unattended</MenuItem>
                <MenuItem value={"While asleep (6+ hours/day)"}>While asleep (6+ hours/day)</MenuItem>
                </Select>
              </FormControl>
              <br/><br/>
              <Typography color="textSecondary" id="discrete-slider-hi" gutterBottom>
                SpO2 High Alarm (BPM)
              </Typography>
              <Slider
                defaultValue={130}
                value={props.patient.FormData[8].requiredFields.spO2HiAlarm}
                aria-labelledby="discrete-slider-hi"
                valueLabelDisplay="auto"
                name="spO2HiAlarm"
                onChange={(e, v) => props.modifyForm(8, v, "spO2HiAlarm")}
                handleDragStop={(e, v) => props.modifyForm(8, v, "spO2HiAlarm")}
                step={1}
                marks
                //valueLabelFormat={(e) => e + " BPM"}
                min={90}
                max={100}
              />
              <br/><br/>
              <Typography color="textSecondary" id="discrete-slider-lo" gutterBottom>
                SpO2 Low Alarm (BPM)
              </Typography>
              <Slider
                defaultValue={130}
                value={props.patient.FormData[8].requiredFields.spO2LoAlarm}
                aria-labelledby="discrete-slider-lo"
                valueLabelDisplay="auto"
                name="spO2LoAlarm"
                onChange={(e, v) => props.modifyForm(8, v, "spO2LoAlarm")}
                handleDragStop={(e, v) => props.modifyForm(8, v, "spO2LoAlarm")}
                step={1}
                marks
                //valueLabelFormat={(e) => e + " BPM"}
                min={90}
                max={100}
              />
              <br/><br/>
              <Typography color="textSecondary" id="discrete-slider-hi-heart" gutterBottom>
                Heart Rate High Alarm (BPM)
              </Typography>
              <Slider
                defaultValue={130}
                value={props.patient.FormData[8].requiredFields.heartRateHiAlarm}
                aria-labelledby="discrete-slider-hi-heart"
                valueLabelDisplay="auto"
                name="heartRateHiAlarm"
                onChange={(e, v) => props.modifyForm(8, v, "heartRateHiAlarm")}
                handleDragStop={(e, v) => props.modifyForm(8, v, "heartRateHiAlarm")}
                step={5}
                marks
                //valueLabelFormat={(e) => e + " BPM"}
                min={115}
                max={240}
              />
              <br/><br/>
              <Typography color="textSecondary" id="discrete-slider-lo-heart" gutterBottom>
                Heart Rate Low Alarm (BPM)
              </Typography>
              <Slider
                defaultValue={130}
                value={props.patient.FormData[8].requiredFields.heartRateHiAlarm}
                aria-labelledby="discrete-slider-lo"
                valueLabelDisplay="auto"
                name="heartRateHiAlarm"
                onChange={(e, v) => props.modifyForm(8, v, "heartRateHiAlarm")}
                handleDragStop={(e, v) => props.modifyForm(8, v, "heartRateHiAlarm")}
                step={5}
                marks
                //valueLabelFormat={(e) => e + " BPM"}
                min={45}
                max={100}
              />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth margin="normal" variant="outlined" label="Usage Adjustments" InputProps={{startAdornment: <InputAdornment position="start">↑↓</InputAdornment>}}
                name="oximeterUsageAdjustment" value={props.patient.FormData[7].requiredFields.oximeterUsageAdjustment} onChange={(e) => props.modifyForm(7, e)} />
                <TextField fullWidth margin="normal" variant="outlined" label="HHR Adjustments" InputProps={{startAdornment: <InputAdornment position="start">↑↓</InputAdornment>}}
                name="oximeterHHRAdjustment" value={props.patient.FormData[7].requiredFields.oximeterHHRAdjustment} onChange={(e) => props.modifyForm(7, e)} />
                <TextField fullWidth margin="normal" variant="outlined" label="LHR Adjustments" InputProps={{startAdornment: <InputAdornment position="start">↑↓</InputAdornment>}}
                name="oximeterLHRAdjustment" value={props.patient.FormData[7].requiredFields.oximeterLHRAdjustment} onChange={(e) => props.modifyForm(7, e)} />
                <TextField fullWidth margin="normal" variant="outlined" label="HSpO2 Adjustments" InputProps={{startAdornment: <InputAdornment position="start">↑↓</InputAdornment>}}
                name="HSpO2Adjustment" value={props.patient.FormData[7].requiredFields.HSpO2Adjustment} onChange={(e) => props.modifyForm(7, e)} />
                <TextField fullWidth margin="normal" variant="outlined" label="LSpO2 Adjustments" InputProps={{startAdornment: <InputAdornment position="start">↑↓</InputAdornment>}}
                name="LSpO2Adjustment" value={props.patient.FormData[7].requiredFields.LSpO2Adjustment} onChange={(e) => props.modifyForm(7, e)} />
              </Grid>
            </Grid>
            :null}

            <Typography color="textSecondary" gutterBottom style={{position: "relative"}}>
              Contact Info
              <Button style={{position: "absolute", right: "1px", top: "-9px"}} variant="contained" color="primary" onClick={(e) => props.addRemoveContact(e, 8, "add")}><Icon path={mdiPlus} size={1}/></Button>
            </Typography>

            {props.patient.FormData[7].requiredFields.contactInfo.map((e, i) =>
              <div style={{width: "100%"}}>
              <Button className={classes.delButton} variant="contained" color="secondary" onClick={(e) => props.addRemoveContact(i, 8, "remove")}><Icon path={mdiDelete} size={1}/></Button>
              <TextField className={classes.commentWidth} multiline rowsMax={4} size="small" margin="normal" variant="outlined" label={"Phone " + romanize(i + 1)} name={i.toString()} value={e.phone} onChange={(e) => props.addRemoveContact(e, "phone", "modify")} />
              <TextField className={classes.commentWidth} style={{marginTop: "0px", marginLeft: "69px"}} multiline rowsMax={4} size="small" margin="normal" variant="outlined" label={"Changes/Updates"} name={i.toString()} value={e.changes} onChange={(e) => props.addRemoveContact(e, "changes", "modify")} />
              </div>
            )}

            <br/>

            <Typography color="textSecondary" gutterBottom style={{position: "relative"}}>
              Appointment Info
              <Button style={{position: "absolute", right: "1px", top: "-9px"}} variant="contained" color="primary" onClick={(e) => props.addRemoveAppointment(e, 8, "add")}><Icon path={mdiPlus} size={1}/></Button>
            </Typography>

            {props.patient.FormData[7].requiredFields.appointments.map((e, i) =>
              <div style={{width: "100%"}}>
              <Button style={{marginTop: "0px"}} className={classes.delButton} variant="contained" color="secondary" onClick={(e) => props.addRemoveAppointment(i, 8, "remove")}><Icon path={mdiDelete} size={1}/></Button>
              <Autocomplete
                options={departmentList}
                freeSolo
                value={e.known}
                className={classes.commentWidth}
                style={{display: "inline-flex"}}
                onChange={(e, value) => props.addRemoveAppointment({target: {value: value, name: i.toString()}}, "known", "modify")}
                name={i.toString()}
                renderInput={(params) => <TextField {...params} value={e.known} onChange={(e) => props.addRemoveAppointment(e, "known", "modify")} style={{width: "100%", margin: "0px"}}
                size="small" variant="outlined" label={"Known Appointment " + romanize(i + 1)} margin="normal" name={i.toString()} className={classes.commentWidth}/>}
              />
              <Autocomplete
                options={departmentList}
                freeSolo
                value={e.additional}
                className={classes.commentWidth}
                style={{display: "inline-flex", width: "100%"}}
                onChange={(e, value) => props.addRemoveAppointment({target: {value: value, name: i.toString()}}, "additional", "modify")}
                renderInput={(params) => <TextField {...params} value={e.additional} onChange={(e) => props.addRemoveAppointment(e, "additional", "modify")} style={{margin: "0px", marginTop: "0px", marginLeft: "69px", width: "100%"}}
                size="small" variant="outlined" label={"Additional Appointment"} margin="normal" name={i.toString()} className={classes.commentWidth}/>}
              />
              </div>
            )}

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker disableToolbar variant="inline" fullWidth format="MM/dd/yyyy" margin="normal" label="Last Seen" error={props.checkFieldValidity(7, "evalLastSeen").valid && props.highestStep > 7}
            value={props.patient.FormData[7].requiredFields.evalLastSeen} style={{marginTop: "24px"}}
              onChange={(e) => props.modifyForm(7, e, "evalLastSeen")} KeyboardButtonProps={{'aria-label': 'change date',}}/>
            </MuiPickersUtilsProvider>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker disableToolbar variant="inline" fullWidth format="MM/dd/yyyy" margin="normal" label="Last Sleep Study" error={props.checkFieldValidity(7, "evalLastSleep").valid && props.highestStep > 7}
            value={props.patient.FormData[7].requiredFields.evalLastSleep} style={{marginTop: "24px"}}
              onChange={(e) => props.modifyForm(7, e, "evalLastSleep")} KeyboardButtonProps={{'aria-label': 'change date',}}/>
            </MuiPickersUtilsProvider>

            </Grid>
          </Grid>
  );

}

export default OxygenEvaluation;
