import React, {Component} from 'react';
import './App.css';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { BrowserRouter as Router, Route, Link, Switch, Redirect, useHistory } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import MediaQuery from 'react-responsive'
import { slide as Menu } from 'react-burger-menu'

function Header(props) {
  const history = useHistory();

  const tryLogout = async () => {
    var res = await props.sendLogout();
    if(!res){
      history.push('/');
    }
  }


  return (
    <div style={{position: "relative"}}>
    <MediaQuery minDeviceWidth={960}>
      <Link to="/"><img src="https://dev.cmnsolutions.com/MDR.png" style={{height: "100px"}}/></Link>
      <div style={{position: "absolute", right: "15px", top: "15px"}}>
        {props.isAuthed ? <div>{props.username && props.username.toLowerCase() == "evanjones@medres" ? <Link to="/admin"><Button size="medium" variant="outlined" color="primary">Admin Panel</Button></Link>  :null}<Link style={{marginLeft: "5px"}} to="/dashboard"><Button size="medium" variant="outlined" color="primary">Dashboard</Button></Link><Button size="medium" variant="outlined" color="primary" onClick={tryLogout} style={{marginLeft: "5px"}}>Log Out</Button></div> :
        <Link to="/login"><Button size="medium" variant="outlined" color="primary">Login</Button></Link>}
      </div>

      <div style={{position: "absolute", right: "10px", bottom: "7px"}}>
        <Link to="/about"><h2 className="headerLink">About</h2></Link>
        <Link to="/contact"><h2 className="headerLink">Contact Us</h2></Link>
        <Link to="/feedback"><h2 className="headerLink">Feedback</h2></Link>
      </div>

      <hr/>
    </MediaQuery>
    <MediaQuery maxDeviceWidth={959}>
      <Link to="/"><img src="./MDR.png" style={{height: "100px"}}/></Link>
      <Menu right >
      <div style={{position: "absolute", right: "35px", top: "35px"}}>
        {props.isAuthed ? <div>{props.username && props.username.toLowerCase() == "evanjones@medres" ? <Link to="/admin"><Button size="medium" variant="outlined" color="primary">Admin Panel</Button></Link>  :null}<Link style={{marginLeft: "5px"}} to="/dashboard"><Button size="medium" variant="outlined" color="primary">Dashboard</Button></Link><Button size="medium" variant="outlined" color="primary" onClick={tryLogout} style={{marginLeft: "5px"}}>Log Out</Button></div> :
        <Link to="/login"><Button size="medium" variant="outlined" color="primary">Login</Button></Link>}
      </div>
      <div style={{height: "20px"}}/>
      <Link to="/" className="menu-item"><h3 style={{fontWeight: "200", textAlign: "right"}}>Home</h3></Link>
      <Link to="/about" className="menu-item"><h3 style={{fontWeight: "200", textAlign: "right"}}>About</h3></Link>
      <Link to="/contact" className="menu-item"><h3 style={{fontWeight: "200", textAlign: "right"}}>Contact Us</h3></Link>
    </Menu>
      <hr/>
    </MediaQuery>
    </div>
  );
}

export default Header;
