import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './App.css';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Icon from '@mdi/react'
import Grid from '@material-ui/core/Grid';
import { mdiEmoticonOutline } from '@mdi/js';

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: "4px",
    marginBottom: "4px",
    minWidth: 120,
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
    },
  }
}));

function Feedback(props) {
  const classes = useStyles();

  const [state, setState] = useState({
    subject: "",
    name: "",
    email: "",
    company: "",
    phone: "",
    text: "",
    sent: false
  })

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  }

  const trySendFeedback = () => {
    props.sendFeedback(state.name, state.phone, state.company, state.email, state.subject, state.text)
    setState({...state, sent: true})
  }

    return (
      <div className="formBack" style={{alignItems: "center", display: "flex", justifyContent: "center"}}>
        <div style={{width: "60%", marginTop: "0%", textAlign: "center", minWidth: "330px"}}>
          <Card>
            <CardContent>
              <form noValidate autoComplete="off">
              <h2 style={{fontWeight: 300, color: "rgba(0, 0, 0, 0.54)", margin: "2px 0px 12px 0px", textAlign: "le333ft"}}>Feedback</h2>
              {state.sent ?
                <Typography color="textSecondary" gutterBottom>
                  <br/><br/>
                  <Icon path={mdiEmoticonOutline} size={6}/>
                  <br/><br/><br/>
                  Your Message Has Been Sent - Thank You!
                </Typography>
                :
                <Grid container spacing={1}>
                  <Grid item xs={6}><TextField className={classes.grid} fullWidth margin="normal" id="standard-basic" variant="outlined" label="Name" value={state.name} onChange={handleChange} name="name" /></Grid>
                  <Grid item xs={6}><TextField className={classes.grid} fullWidth margin="normal" id="standard-basic" variant="outlined" label="Email" value={state.email} onChange={handleChange} name="email" /></Grid>
                  <Grid item xs={6}><TextField className={classes.grid} fullWidth margin="normal" id="standard-basic" variant="outlined" label="Phone" value={state.phone} onChange={handleChange} name="phone" /></Grid>
                  <Grid item xs={6}><TextField className={classes.grid} fullWidth margin="normal" id="standard-basic" variant="outlined" label="Company" value={state.company} onChange={handleChange} name="company" /></Grid>
                  <Grid item xs={12}><TextField className={classes.grid} fullWidth margin="normal" id="standard-basic" variant="outlined" label="Subject" value={state.subject} onChange={handleChange} name="subject" /></Grid>
                  <Grid item xs={12}><TextField className={classes.grid} fullWidth margin="normal" id="standard-basic" variant="outlined" label="Message" multiline variant="outlined" rows={8} rowsMax={8} value={state.text} onChange={handleChange} name="text"/></Grid>
                </Grid>}
              {state.sent ? null : <Button variant="contained" color="primary" onClick={trySendFeedback}>SEND</Button>}
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    );

}

export default Feedback;
