import React, {Component} from 'react';
import '../App.css';
import { makeStyles } from '@material-ui/core/styles';
import OxygenEvaluation from "./OxygenEvaluation.js";
import Oxygen360 from "./Oxygen360.js";
import OxygenProjections from './OxygenProjections.js';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/date-fns';
import InputAdornment from '@material-ui/core/InputAdornment';
import {MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker} from '@material-ui/pickers';
import Icon from '@mdi/react'
import { mdiDelete, mdiPlus } from '@mdi/js';
import {romanize, practicionerList, clinicianList} from "../utils.js"
import {oxygenFlow} from "../enums.js"

const useStyles = makeStyles((theme) => ({
  formControl: {
//margin: theme.spacing(1),
    minWidth: 120,
    marginTop: "16px",
    marginBottom: "8px"
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
//margin: theme.spacing(1),
      //width: '25ch',
    },
  },
  fullHeightCard: {
		height: "100%",
	},
  halfWidth: {
    width: "50%"
  },
  quartWidth:{
    width: "25%"
  },
  threeQuartWidth:{
    width: "75%"
  },
  commentWidth:{
    marginTop: "8px",
    width: "calc(100% - 69px)"
  },
  delButton:{
    marginTop: "8px",
    marginRight: "4px",
    height: "40px"
  }
}));

function OxygenInfo(props) {
  const classes = useStyles();

  return (
    <div style={{padding: "20px"}}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card className={classes.fullHeightCard}>
            <CardContent style={{position: "relative"}}>
              <Typography color="textSecondary" gutterBottom>
                Oxygen Information
              </Typography>
              <FormControlLabel style={{position: "absolute", right: "17px", top: "10px"}} control={
                <Checkbox color="primary" checked={props.patient.FormData[7].requiredFields.oxygenMain} onClick={(e) => props.modifyForm(7, "checkbox", "oxygenMain")} />} label="Enable Main Form"/>

              {props.patient.FormData[7].requiredFields.oxygenMain ?
                <div>
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel>Litre Flow</InputLabel>
                <Select name="oxygenLitreFlow" value={props.patient.FormData[7].requiredFields.oxygenLitreFlow} onChange={(e) => props.modifyForm(7, e)} label="Litre Flow" >
                  {oxygenFlow.map((e) => <MenuItem value={e}>{e + "Litres"}</MenuItem>)}
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel>Method of Delivery</InputLabel>
                <Select name="oxygenMethod" value={props.patient.FormData[7].requiredFields.oxygenMethod} onChange={(e) => props.modifyForm(7, e)} label="Method of Delivery" >
                <MenuItem value={"Nasal Canula"}>Nasal Canula</MenuItem>
                <MenuItem value={"Tracheostomy Mask"}>Tracheostomy Mask</MenuItem>
                <MenuItem value={"Ventilator"}>Ventilator</MenuItem>
                <MenuItem value={"CPAP Mask"}>CPAP Mask</MenuItem>
                <MenuItem value={"BiPaP Mask"}>BiPaP Mask</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel>Prescribed Usage</InputLabel>
                <Select name="oxygenUsage" value={props.patient.FormData[7].requiredFields.oxygenUsage} onChange={(e) => props.modifyForm(7, e)} label="Prescribed Usage" >
                  <MenuItem value={"Continuously"}>Continuously</MenuItem>
                  <MenuItem value={"While Asleep/Unattended"}>While Asleep/Unattended</MenuItem>
                  <MenuItem value={"While Asleep (6+ hours/day)"}>While Asleep (6+ hours/day)</MenuItem>
                </Select>
              </FormControl>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker disableToolbar variant="inline" fullWidth format="MM/dd/yyyy" margin="normal" label="Date Prescribed" error={props.checkFieldValidity(7, "oxygenDatePrescribed").valid && props.highestStep > 7}
              value={props.patient.FormData[7].requiredFields.oxygenDatePrescribed} style={{marginTop: "24px"}}
                onChange={(e) => props.modifyForm(7, e, "oxygenDatePrescribed")} KeyboardButtonProps={{'aria-label': 'change date',}}/>
              </MuiPickersUtilsProvider>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker disableToolbar variant="inline" fullWidth format="MM/dd/yyyy" margin="normal" label="Date Last Seen" error={props.checkFieldValidity(7, "oxygenLastSeen").valid && props.highestStep > 7}
              value={props.patient.FormData[7].requiredFields.oxygenLastSeen} style={{marginTop: "24px"}}
                onChange={(e) => props.modifyForm(7, e, "oxygenLastSeen")} KeyboardButtonProps={{'aria-label': 'change date',}}/>
              </MuiPickersUtilsProvider>

              <FormControlLabel control={
                <Checkbox color="primary" checked={props.patient.FormData[7].requiredFields.oxygenPortable} onClick={(e) => props.modifyForm(7, "checkbox", "oxygenPortable")} />} label="Patient requires portable oxygen to maintain adequate oxygen saturation levels of 94% or more during transport
                away from stationary concentrator."/>
                </div>
                : null}

            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
        <Card className={classes.fullHeightCard}>
          <CardContent style={{position: "relative"}}>
            <Typography color="textSecondary" gutterBottom>
              Comments
            </Typography>
            {props.patient.FormData[7].requiredFields.oxygenMain ?
            <div>
            <p className="recommended"><b>Related Equipment & Supply Items may Include: </b>
            <br/>B9998 - Extension Sets</p>

            <div style={{width: "100%"}}>
            <TextField fullWidth multiline rows={12} rowsMax={12} size="small" margin="normal" variant="outlined" label={"Comment"}
            name="comment" value={props.patient.FormData[7].requiredFields.comment}
            onChange={(e) => props.modifyForm(7, e)} />
            </div>
            </div>
            :null}

          </CardContent>
        </Card>
        </Grid>

        <Grid item xs={12}>
        <Card className={classes.fullHeightCard}>
          <CardContent style={{position: "relative"}}>
            <Typography color="textSecondary" gutterBottom>
              Portable Oxygen Projections
            </Typography>

            <FormControlLabel style={{position: "absolute", right: "17px", top: "10px"}} control={
              <Checkbox color="primary" checked={props.patient.FormData[7].requiredFields.oxygenPortable} onClick={(e) => props.modifyForm(7, "checkbox", "oxygenPortable")} />} label="Enable Portable Form"/>
              {props.patient.FormData[7].requiredFields.oxygenPortable ?
            <OxygenProjections data={props.patient.FormData[7].requiredFields.oxygenProjections} patient={props.patient} addRemoveProjection={props.addRemoveProjection} modifyForm={props.modifyForm}/>
            : null}
          </CardContent>
        </Card>
        </Grid>

        <Grid item xs={12}>
        <Card className={classes.fullHeightCard}>
          <CardContent style={{position: "relative"}}>
            <Typography color="textSecondary" gutterBottom>
              Form 360 Alabama Medicaid Agency
            </Typography>

            <FormControlLabel style={{position: "absolute", right: "17px", top: "10px"}} control={
              <Checkbox color="primary" checked={props.patient.FormData[7].requiredFields.oxygen360} onClick={(e) => props.modifyForm(7, "checkbox", "oxygen360")} />} label="Enable Form 360"/>

              {props.patient.FormData[7].requiredFields.oxygen360 ?
                <Oxygen360 patient={props.patient} modifyForm={props.modifyForm} modifyPhysician={props.modifyPhysician} checkFieldValidity={props.checkFieldValidity}/>
              :null}

          </CardContent>
        </Card>
        </Grid>

        <Grid item xs={12}>
        <Card className={classes.fullHeightCard}>
          <CardContent style={{position: "relative"}}>
            <Typography color="textSecondary" gutterBottom>
              Clinical Evaluation/Follow-Up Form
            </Typography>

            <FormControlLabel style={{position: "absolute", right: "17px", top: "10px"}} control={
              <Checkbox color="primary" checked={props.patient.FormData[7].requiredFields.oxygenEval} onClick={(e) => props.modifyForm(7, "checkbox", "oxygenEval")} />} label="Enable Evaluation/Follow-Up Form"/>

            {props.patient.FormData[7].requiredFields.oxygenEval ?
              <OxygenEvaluation addRemoveContact={props.addRemoveContact}
              addRemoveAppointment={props.addRemoveAppointment}
              patient={props.patient}
              modifyForm={props.modifyForm}
              modifyPhysician={props.modifyPhysician}
              checkFieldValidity={props.checkFieldValidity}/>
            :null}

          </CardContent>
        </Card>
        </Grid>



      </Grid>
    </div>
  );

}

export default OxygenInfo;
