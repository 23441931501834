import React from 'react';
import './App.css';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Icon from '@mdi/react'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { mdiClockFast, mdiShieldLock, mdiBriefcaseEdit} from '@mdi/js'
import { BrowserRouter as Router, Route, Link, Switch, Redirect, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  formControl: {
    //margin: theme.spacing(1),
    marginTop:"8px",
    minWidth: 120,
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  fullHeightCard: {
		height: "100%",
	},
  halfWidth: {
    width: "50%"
  },
  quartWidth:{
    width: "25%"
  },
  threeQuartWidth:{
    width: "75%"
  },
  commentWidth:{
    marginTop: "8px",
    width: "calc(100% - 69px)"
  },
  delButton:{
    marginTop: "8px",
    marginRight: "4px",
    height: "40px"
  },
  otherBox:{
    marginTop: "5px",
    marginBottom: "8px",
    width: "calc(100% - 50px)",
    marginLeft: "-14px"
  }
}));

function Home() {
  const classes = useStyles();


    return (
      <div className="formBack" style={{
        backgroundImage: 'url("https://images.pexels.com/photos/40568/medical-appointment-doctor-healthcare-40568.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260")',
        backgroundSize: "cover", padding: "0 8%", textAlign: "center", position: "absolute"}}>
      <Grid container spacing={3}>
        <Grid className={classes.fullHeightCard} item xs={12} md={4}>
        <Card style={{width: "90%", margin: "20px auto", textAlign: "center", marginTop: "80px"}}>
          <CardContent>
          <Icon path={mdiClockFast} size={2}/>
          <Typography variant="h5" component="h2">
            Fast
          </Typography>
          <Typography align="justify" color="textSecondary" gutterBottom>
          Let your doctors focus on whats important. Our technology condenses all mandatory Medicaid forms into one that only requires one signature. This saves your practicioners valuable time and has already saved our clients millions of dollars over the years.
          </Typography>
          </CardContent>
        </Card>
        </Grid>

        <Grid className={classes.fullHeightCard} item xs={12} md={4}>
        <Card style={{width: "90%", margin: "20px auto", textAlign: "center", marginTop: "80px"}}>
          <CardContent>
          <Icon path={mdiShieldLock} size={2}/>
          <Typography variant="h5" component="h2">
            Secure
          </Typography>
          <Typography align="justify" color="textSecondary" gutterBottom>
            Our software is both HIPAA and HITECH compliant and boasts a level of encryption far beyond industry standard. We ensure that only your staff can see your clients data and continue to work tirelessly to keep up to date with the latest security technology.
          </Typography>
          </CardContent>
        </Card>
        </Grid>

        <Grid className={classes.fullHeightCard} item xs={12} md={4}>
        <Card style={{width: "90%", margin: "20px auto", textAlign: "center", marginTop: "80px"}}>
          <CardContent>
          <Icon path={mdiBriefcaseEdit} size={2}/>
          <Typography variant="h5" component="h2">
            Reliable
          </Typography>
          <Typography align="justify" color="textSecondary" gutterBottom>
            There are over 250,000 deaths each year from administrative mistakes in the US, ranking it the largest cause of death. Our software provides an error proof way to administer equipment by taking into consideration your patients history as you fill out the forms.
          </Typography>
          </CardContent>
        </Card>
        </Grid>
      </Grid>
      <Link to="/about"><Button size="large" variant="contained" color="primary">Learn More</Button></Link>
      <br/><br/><br/>
      </div>
    );

}

export default Home;
