import React, {Component} from 'react';
import '../App.css';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Slider from '@material-ui/core/Slider';
import Icon from '@mdi/react'
import { mdiDelete, mdiPlus } from '@mdi/js';
import {romanize} from "../utils.js"

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  fullHeightCard: {
		height: "100%",
	},
  halfWidth: {
    width: "50%"
  },
  quartWidth:{
    width: "25%"
  },
  threeQuartWidth:{
    width: "75%"
  },
  commentWidth:{
    marginTop: "8px",
    width: "calc(100% - 69px)"
  },
  delButton:{
    marginTop: "8px",
    marginRight: "4px",
    height: "40px"
  }
}));

function ApneaMonitorInfo(props) {
  const classes = useStyles();

  return (
    <div style={{padding: "20px"}}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Card className={classes.fullHeightCard}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Apnea Monitor Information
              </Typography>

              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel>Usage</InputLabel>
                <Select name="apneaUsage" value={props.patient.FormData[6].requiredFields.apneaUsage} onChange={(e) => props.modifyForm(6, e)} label="Usage" >
                  <MenuItem value={"Continuously"}>Continuously</MenuItem>
                  <MenuItem value={"While asleep & while unobserved"}>While asleep & while unobserved</MenuItem>
                </Select>
              </FormControl>

              <Typography color="textSecondary" id="discrete-slider-hi" gutterBottom>
                Heart Rate High Alarm (BPM)
              </Typography>
              <Slider
                defaultValue={130}
                value={props.patient.FormData[6].requiredFields.hiAlarm}
                aria-labelledby="discrete-slider-hi"
                valueLabelDisplay="auto"
                name="hiAlarm"
                onChange={(e, v) => props.modifyForm(6, v, "hiAlarm")}
                step={5}
                marks
                min={130}
                max={240}
              />

              <Typography color="textSecondary" id="discrete-slider-lo" gutterBottom>
                Heart Rate Low Alarm (BPM)
              </Typography>
              <Slider
                defaultValue={130}
                value={props.patient.FormData[6].requiredFields.loAlarm}
                aria-labelledby="discrete-slider-lo"
                valueLabelDisplay="auto"
                name="loAlarm"
                onChange={(e, v) => props.modifyForm(6, v, "loAlarm")}
                step={5}
                marks
                min={50}
                max={90}
              />

              <TextField fullWidth margin="normal" variant="outlined" label="Apnea Alarm Delay (sec)" name="delay" error={props.checkFieldValidity(6, "delay").valid && props.highestStep > 6}
              value={props.patient.FormData[6].requiredFields.delay} onChange={(e) => props.modifyForm(6, e)} />

            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={9}>
        <Card className={classes.fullHeightCard}>
          <CardContent style={{position: "relative"}}>
            <Typography color="textSecondary" gutterBottom>
              Comments
            </Typography>
            <Button style={{position: "absolute", right: "17px", top: "10px"}} variant="contained" color="primary" onClick={(e) => props.addRemoveComment(e, 6, "add")}><Icon path={mdiPlus} size={1}/></Button>
            <p className="recommended"><b>Related Equipment & Supply Items may Include:</b>
            <br/>A4556 - Electrodes
            <br/>E1399 - Charger
            <br/>A9025 - Carry Bag
            <br/>A4557 - Electrode Lead Wires
            <br/>L0210/A9900 - Electrode Belts
            <br/>94772 - Apnea Monitor Memory Download Reports</p>

            {props.patient.FormData[6].requiredFields.comments.map((e, i) =>
              <div style={{width: "100%"}}>
              <Button className={classes.delButton} variant="contained" color="secondary" onClick={(e) => props.addRemoveComment(i, 6, "remove")}><Icon path={mdiDelete} size={1}/></Button>
              <TextField className={classes.commentWidth} multiline rowsMax={4} size="small" margin="normal" variant="outlined" label={"Comment " + romanize(i + 1)} name={i.toString()} value={e} onChange={(e) => props.addRemoveComment(e, 6, "modify")} />
              </div>
            )}

          </CardContent>
        </Card>
        </Grid>



      </Grid>
    </div>
  );

}

export default ApneaMonitorInfo;
