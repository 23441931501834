import React, {useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { mdiDelete, mdiPlus, mdiMapMarker  } from '@mdi/js';
import Button from '@material-ui/core/Button';
import Icon from '@mdi/react'
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import GoogleMapReact from 'google-map-react';
import Geosuggest from 'react-geosuggest';
import {detailedDepartmentList} from '../enums.js'
import MediaQuery from 'react-responsive'


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Pin = ({ text }) =>
<div>
<div className="marker"/>
<span className="beacon"/>
</div>;

export default function OxygenProjections(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({
    columns: [
      { title: 'Reason', field: 'reason',headerStyle: {padding: "0px", fontSize: "10px"} },
      { title: 'Estimated Round-trip Mileage', field: 'mileage', type: 'numeric',headerStyle: {padding: "0px", fontSize: "10px"}},
      { title: 'Estimated Transport Duration in minutes', field: 'transportDuration', type: 'numeric',headerStyle: {padding: "0px", fontSize: "10px"}},
      { title: 'Estimated Non-Transport Duration in minutes (Waiting Room, etc.)', field: 'nonTransportDuration', type: 'numeric',headerStyle: {padding: "0px", fontSize: "10px"}},
      { title: 'Estimated Unexpected Delays in minutes (Traffic etc.)', field: 'delayDuration', type: 'numeric',headerStyle: {padding: "0px", fontSize: "10px"}},
      { title: 'Estimated number of occurrences during PA certification', field: 'occurances', type: 'numeric',headerStyle: {padding: "0px", fontSize: "10px"}},
      { title: 'Subtotal of estimated minutes needed for Portable Oxygen', field: 'subTotal', type: 'numeric',headerStyle: {padding: "0px", fontSize: "10px"}},
    ],
    selectedProjection: null,
    lat: 33.505602,
    lng: -86.807961
  });

  useEffect(() => {
    var tot = 0;
    for(var x = 0; x < props.patient.FormData[7].requiredFields.oxygenProjections.length; x++){
      var subtot = 0;
      subtot += props.patient.FormData[7].requiredFields.oxygenProjections[x].transportDuration;
      subtot += props.patient.FormData[7].requiredFields.oxygenProjections[x].nonTransportDuration;
      subtot += props.patient.FormData[7].requiredFields.oxygenProjections[x].delayDuration;
      subtot = subtot * props.patient.FormData[7].requiredFields.oxygenProjections[x].occurances;
      props.addRemoveProjection({target: {name: x, value: subtot}}, "subTotal", "modify")
      tot += subtot
    }
    props.modifyForm(7, {target: {name: "oxygenTotal", value: tot}})
  },[
    ...props.patient.FormData[7].requiredFields.oxygenProjections.map(item => item.transportDuration),
    ...props.patient.FormData[7].requiredFields.oxygenProjections.map(item => item.nonTransportDuration),
    ...props.patient.FormData[7].requiredFields.oxygenProjections.map(item => item.delayDuration),
    ...props.patient.FormData[7].requiredFields.oxygenProjections.map(item => item.occurances),
  ])

  useEffect(() => {
    var reasonString = "";
    for(var x = 0; x < props.patient.FormData[7].requiredFields.oxygenProjections.length; x++){
      if(props.patient.FormData[7].requiredFields.oxygenProjections[x].visitType && props.patient.FormData[7].requiredFields.oxygenProjections[x].companyName && props.patient.FormData[7].requiredFields.oxygenProjections[x].doctor){
            reasonString += props.patient.FormData[7].requiredFields.oxygenProjections[x].visitType
            reasonString += " ("
            reasonString += props.patient.FormData[7].requiredFields.oxygenProjections[x].companyName
            reasonString += " - "
            reasonString += props.patient.FormData[7].requiredFields.oxygenProjections[x].doctor
            reasonString += ")"
            props.addRemoveProjection({target: {name: x, value: reasonString}}, "reason", "modify")
          }
    }
  },[
    ...props.patient.FormData[7].requiredFields.oxygenProjections.map(item => item.doctor),
    ...props.patient.FormData[7].requiredFields.oxygenProjections.map(item => item.companyName),
    ...props.patient.FormData[7].requiredFields.oxygenProjections.map(item => item.address),
    ...props.patient.FormData[7].requiredFields.oxygenProjections.map(item => item.specialty),
  ])

  useEffect(() => {
    var capacity = "NaN"
    switch(props.patient.FormData[7].requiredFields.oxygenCylinderType){
      case "E Cylinder":
        capacity = 577;
        break;
      case "D Cylinder":
        capacity = 351;
        break;
      case "A/B Cylinder":
        capacity = 112;
        break;
    }
    var cylinders = Math.ceil(props.patient.FormData[7].requiredFields.oxygenTotal * props.patient.FormData[7].requiredFields.oxygenLitreFlow  / capacity)
    props.modifyForm(7, {target: {name: "oxygenCylinderQuantity", value: isNaN(cylinders) ? 0 : cylinders}})
  },[
    props.patient.FormData[7].requiredFields.oxygenCylinderType,
    props.patient.FormData[7].requiredFields.oxygenTotal,
    props.patient.FormData[7].requiredFields.oxygenLitreFlow,
  ])

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const geosuggestEl = useRef(null);
  const mapRef = useRef(null);

  const fixtures = [/*
    {label: 'New York', location: {lat: 40.7033127, lng: -73.979681}},
    {label: 'Rio', location: {lat: -22.066452, lng: -42.9232368}},
    {label: 'Tokyo', location: {lat: 35.673343, lng: 139.710388}}*/
  ];

  const onSuggestSelect = (suggest) => {
    props.addRemoveProjection({target: {name: state.selectedProjection, value: [suggest.location.lat, suggest.location.lng]}}, "location", "modify")
    props.addRemoveProjection({target: {name: state.selectedProjection, value: suggest.gmaps.adr_address}}, "address", "modify")
    setState({...state, lat: suggest.location.lat, lng: suggest.location.lng})
  };

  return (
    <div>
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <h2 id="transition-modal-title">Driving Directions Wizard</h2>
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <InputLabel>Visit Type</InputLabel>
            <Select name={state.selectedProjection} value={props.patient.FormData[7].requiredFields.oxygenProjections[state.selectedProjection] ? props.patient.FormData[7].requiredFields.oxygenProjections[state.selectedProjection].visitType : null} onChange={(e) => props.addRemoveProjection(e, "visitType", "modify")} label="Visit Type" >
              <MenuItem value={"Local Primary/Patient 1st Appointment"}>Local Primary/Patient 1st</MenuItem>
              <MenuItem value={"Specialty Appointment"}>Specialty</MenuItem>
            </Select>
          </FormControl>
          <br/>
          <TextField size="small" margin="normal" variant="outlined" name="doctor" name={state.selectedProjection} onChange={(e) => props.addRemoveProjection(e, "doctor", "modify")}
          value={props.patient.FormData[7].requiredFields.oxygenProjections[state.selectedProjection] ? props.patient.FormData[7].requiredFields.oxygenProjections[state.selectedProjection].doctor : null} label="Physician Name"/>
          <br/>
          <TextField size="small" margin="normal" variant="outlined" name="companyName" name={state.selectedProjection} onChange={(e) => props.addRemoveProjection(e, "companyName", "modify")}
          value={props.patient.FormData[7].requiredFields.oxygenProjections[state.selectedProjection] ? props.patient.FormData[7].requiredFields.oxygenProjections[state.selectedProjection].companyName : null} label="Organization Name"/>
          <br/>

          {props.patient.FormData[7].requiredFields.oxygenProjections[state.selectedProjection] && props.patient.FormData[7].requiredFields.oxygenProjections[state.selectedProjection].visitType == "Specialty Appointment" ?
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <InputLabel>Specialty</InputLabel>
            <Select name={state.selectedProjection} value={props.patient.FormData[7].requiredFields.oxygenProjections[state.selectedProjection] ? props.patient.FormData[7].requiredFields.oxygenProjections[state.selectedProjection].specialty : null} onChange={(e) => props.addRemoveProjection(e, "specialty", "modify")} label="Specialty" >
              {detailedDepartmentList.map((e) => {
                return(<MenuItem value={e}>{e}</MenuItem>)
              })}
            </Select>
          </FormControl>
          :null}

          <Geosuggest
          ref={geosuggestEl}
          placeholder="Choose a location"
          initialValue=""
          fixtures={fixtures}
          onSuggestSelect={onSuggestSelect}
          location={new window.google.maps.LatLng(33.505602, -86.807961)}
          radius="20" />

          <div style={{width: "300px", height: "300px", margin: "10px"}}>
            <GoogleMapReact
            ref={mapRef}
            bootstrapURLKeys={{ key: "AIzaSyAncSw1idCzao4rKnmSiqeGwa8KVJwrTy8" }} center={{lat: state.lat, lng: state.lng}} defaultZoom={15}>
            <Pin
            lat={state.lat}
            lng={state.lng}
            text="MDR"
            />
            </GoogleMapReact>
          </div>
        </div>
      </Fade>
    </Modal>

    <MediaQuery minDeviceWidth={960}>
      <div style={{display: "flex"}}>
        <div style={{fontSize: "10px", flex: 1}}><Button variant="contained" color="primary" onClick={(e) => props.addRemoveProjection(e, "", "add")}><Icon path={mdiPlus} size={1}/></Button></div>
        <div style={{fontSize: "10px", flex: 2}}>Reason</div>
        <div style={{fontSize: "10px", flex: 1}}>Estimated Round-trip Mileage</div>
        <div style={{fontSize: "10px", flex: 1}}>Estimated Transport Duration in minutes</div>
        <div style={{fontSize: "10px", flex: 1}}>Estimated Non-Transport Duration in minutes (Waiting Room, etc.)</div>
        <div style={{fontSize: "10px", flex: 1}}>Estimated Unexpected Delays in minutes (Traffic etc.)</div>
        <div style={{fontSize: "10px", flex: 1}}>Estimated number of occurrences during PA certification</div>
        <div style={{fontSize: "10px", flex: 1}}>Subtotal of estimated minutes needed for Portable Oxygen</div>
      </div>
      {props.data.map((e, i) => {return(
        <div style={{display: "flex"}}>
          <div style={{fontSize: "10px", flex: 1}}>
          <Button size="small" variant="contained" color="primary" style={{margin: "20px 5px 0px 0px", minWidth: "20px"}} onClick={() => {setState({...state, selectedProjection: i}); handleOpen()}}><Icon path={mdiMapMarker} size={1}/></Button>
          <Button size="small" variant="contained" color="secondary" style={{margin: "20px 5px 0px 0px", minWidth: "20px"}} onClick={(e) => props.addRemoveProjection(i, "", "remove")}><Icon path={mdiDelete} size={1}/></Button>
          </div>
          <div style={{fontSize: "10px", flex: 2}}><TextField name={i.toString()} fullWidth multiline rowsMax={4} size="small" margin="normal" variant="outlined" onChange={(e) => props.addRemoveProjection(e, "reason", "modify")} value={e.reason} /></div>
          <div style={{fontSize: "10px", flex: 1}}><TextField name={i.toString()} size="small" margin="normal" variant="outlined" onChange={(e) => props.addRemoveProjection(e, "mileage", "modify")} value={e.mileage}/></div>
          <div style={{fontSize: "10px", flex: 1}}><TextField name={i.toString()} size="small" margin="normal" variant="outlined" onChange={(e) => props.addRemoveProjection(e, "transportDuration", "modify")} value={e.transportDuration}/></div>
          <div style={{fontSize: "10px", flex: 1}}><TextField name={i.toString()} size="small" margin="normal" variant="outlined" onChange={(e) => props.addRemoveProjection(e, "nonTransportDuration", "modify")} value={e.nonTransportDuration}/></div>
          <div style={{fontSize: "10px", flex: 1}}><TextField name={i.toString()} size="small" margin="normal" variant="outlined" onChange={(e) => props.addRemoveProjection(e, "delayDuration", "modify")} value={e.delayDuration}/></div>
          <div style={{fontSize: "10px", flex: 1}}><TextField name={i.toString()} size="small" margin="normal" variant="outlined" onChange={(e) => props.addRemoveProjection(e, "occurances", "modify")} value={e.occurances}/></div>
          <div style={{fontSize: "10px", flex: 1}}><TextField name={i.toString()} disabled size="small" margin="normal" variant="outlined" onChange={(e) => props.addRemoveProjection(e, "subTotal", "modify")} value={e.subTotal}/></div>
        </div>
      )})}
      <div style={{display: "flex"}}>
        <div style={{fontSize: "10px", flex: 2}}></div>
        <div style={{fontSize: "10px", flex: 5}}><h3 style={{textAlign: "right"}}>Total Estimated Minutes Requiring Oxygen:</h3></div>
        <div style={{fontSize: "10px", flex: 2}}><h3 style={{textAlign: "center"}}>{props.patient.FormData[7].requiredFields.oxygenTotal}</h3></div>
      </div>
      <div style={{display: "flex"}}>
        <div style={{fontSize: "10px", flex: 1}}></div>
        <div style={{fontSize: "10px", flex: 2}}>
        <FormControl fullWidth variant="outlined" className={classes.formControl}>
          <InputLabel>Cylinder Size</InputLabel>
          <Select name="oxygenCylinderType" value={props.patient.FormData[7].requiredFields.oxygenCylinderType} onChange={(e) => props.modifyForm(7, e)} label="Cylinder Size" >
            <MenuItem value={"E Cylinder"}>E Cylinder</MenuItem>
            <MenuItem value={"D Cylinder"}>D Cylinder</MenuItem>
            <MenuItem value={"A/B Cylinder"}>A/B Cylinder</MenuItem>
          </Select>
        </FormControl>
        </div>
        <div style={{fontSize: "10px", flex: 4}}><h3 style={{textAlign: "right"}}>Minimum # of cylinders required for certification period:</h3></div>
        <div style={{fontSize: "10px", flex: 2}}><h3 style={{textAlign: "center"}}>{props.patient.FormData[7].requiredFields.oxygenCylinderQuantity}</h3></div>
      </div>
      </MediaQuery>
      <MediaQuery maxDeviceWidth={959}>
      {props.data.map((e, i) => {return(
        <div>
        <div style={{display: "flex"}}>
          <div style={{fontSize: "10px", flex: 1}}><TextField label="Mileage" name={i.toString()} size="small" margin="normal" variant="outlined" onChange={(e) => props.addRemoveProjection(e, "mileage", "modify")} value={e.mileage}/></div>
          <div style={{fontSize: "10px", flex: 1}}><TextField label="Delay" name={i.toString()} size="small" margin="normal" variant="outlined" onChange={(e) => props.addRemoveProjection(e, "delayDuration", "modify")} value={e.delayDuration}/></div>
          <div style={{fontSize: "10px", flex: 1}}><TextField label="Occurances" name={i.toString()} size="small" margin="normal" variant="outlined" onChange={(e) => props.addRemoveProjection(e, "occurances", "modify")} value={e.occurances}/></div>
        </div>
        <div style={{display: "flex"}}>
          <div style={{fontSize: "10px", flex: 1}}><TextField label="Non-Transport" name={i.toString()} size="small" margin="normal" variant="outlined" onChange={(e) => props.addRemoveProjection(e, "nonTransportDuration", "modify")} value={e.nonTransportDuration}/></div>
          <div style={{fontSize: "10px", flex: 1}}><TextField label="Transport" name={i.toString()} size="small" margin="normal" variant="outlined" onChange={(e) => props.addRemoveProjection(e, "transportDuration", "modify")} value={e.transportDuration}/></div>
        </div>
        <div style={{display: "flex"}}>
          <div style={{fontSize: "10px", flex: 2}}><TextField label="Reason" name={i.toString()} fullWidth multiline rowsMax={4} size="small" margin="normal" variant="outlined" onChange={(e) => props.addRemoveProjection(e, "reason", "modify")} value={e.reason} /></div>
        </div>
        <div style={{display: "flex"}}>
          <div style={{fontSize: "10px", flex: 1}}><TextField label="Sub Total" name={i.toString()} disabled size="small" margin="normal" variant="outlined" onChange={(e) => props.addRemoveProjection(e, "subTotal", "modify")} value={e.subTotal}/></div>
          <Button size="small" variant="contained" color="primary" style={{margin: "20px 5px 0px 0px", minWidth: "20px"}} onClick={() => {setState({...state, selectedProjection: i}); handleOpen()}}><Icon path={mdiMapMarker} size={1}/></Button>
          <Button size="small" variant="contained" color="secondary" style={{margin: "20px 5px 0px 0px", minWidth: "20px"}} onClick={(e) => props.addRemoveProjection(i, "", "remove")}><Icon path={mdiDelete} size={1}/></Button>
        </div>
        </div>
      )})}
      <br/>
      <FormControl fullWidth variant="outlined" className={classes.formControl}>
        <InputLabel>Cylinder Size</InputLabel>
        <Select name="oxygenCylinderType" value={props.patient.FormData[7].requiredFields.oxygenCylinderType} onChange={(e) => props.modifyForm(7, e)} label="Cylinder Size" >
          <MenuItem value={"E Cylinder"}>E Cylinder</MenuItem>
          <MenuItem value={"D Cylinder"}>D Cylinder</MenuItem>
          <MenuItem value={"A/B Cylinder"}>A/B Cylinder</MenuItem>
        </Select>
      </FormControl>
      <div style={{display: "flex"}}>
        <div style={{fontSize: "10px", flex: 5}}><h3 style={{textAlign: "right"}}>Total Estimated Minutes Requiring Oxygen:</h3></div>
        <div style={{fontSize: "10px", flex: 2}}><h3 style={{textAlign: "center"}}>{props.patient.FormData[7].requiredFields.oxygenTotal}</h3></div>
      </div>

      <div style={{display: "flex"}}>
        <div style={{fontSize: "10px", flex: 5}}><h3 style={{textAlign: "right"}}>Minimum # of cylinders required for certification period:</h3></div>
        <div style={{fontSize: "10px", flex: 2}}><h3 style={{textAlign: "center"}}>{props.patient.FormData[7].requiredFields.oxygenCylinderQuantity}</h3></div>
      </div>
      </MediaQuery>

    </div>
  );
}
