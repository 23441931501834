import React, {useEffect} from 'react';
import '../App.css';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Slider from '@material-ui/core/Slider';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker} from '@material-ui/pickers';
import Icon from '@mdi/react'
import { mdiDelete, mdiPlus } from '@mdi/js';
import {romanize} from "../utils.js"
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
    },
  },
  fullHeightCard: {
		height: "100%",
	},
  halfWidth: {
    width: "50%"
  },
  quartWidth:{
    width: "25%"
  },
  threeQuartWidth:{
    width: "75%"
  },
  commentWidth:{
    marginTop: "8px",
    width: "calc(100% - 69px)"
  },
  delButton:{
    marginTop: "8px",
    marginRight: "4px",
    height: "40px"
  }
}));

function OximeterInfo(props) {
  const classes = useStyles();

  useEffect(() => {
    if(props.patient.FormData[8].requiredFields.effectiveDates1 == ""){
      props.modifyForm(8, moment(props.patient.FormData[0].requiredFields.payorCertFrom).add('month', 1).toDate() , "effectiveDates1")
    }
    if(props.patient.FormData[8].requiredFields.effectiveDates2 == ""){
      props.modifyForm(8, moment(props.patient.FormData[0].requiredFields.payorCertFrom).add('month', 2).toDate() , "effectiveDates2")
    }
    if(props.patient.FormData[8].requiredFields.effectiveDates3 == ""){
      props.modifyForm(8, moment(props.patient.FormData[0].requiredFields.payorCertFrom).add('month', 3).toDate() , "effectiveDates3")
    }
  })

  return (
    <div style={{padding: "20px"}}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card className={classes.fullHeightCard}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Oximeter Information
              </Typography>

              <form className={classes.root} noValidate autoComplete="off">
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel>Usage</InputLabel>
                <Select name="oximeterUsage" value={props.patient.FormData[6].requiredFields.oximeterUsage} onChange={(e) => props.modifyForm(6, e)} label="Usage" >
                  <MenuItem value={"Continuously"}>Continuously</MenuItem>
                  <MenuItem value={"While asleep/unattended"}>While asleep/unattended</MenuItem>
                  <MenuItem value={"While asleep (6+ hours/day)"}>While asleep (6+ hours/day)</MenuItem>
                </Select>
              </FormControl>
              </form>

              <Typography color="textSecondary" id="discrete-slider-hi" gutterBottom>
                SpO2 High Alarm (BPM)
              </Typography>
              <Slider
                defaultValue={130}
                value={props.patient.FormData[8].requiredFields.spO2HiAlarm}
                aria-labelledby="discrete-slider-hi"
                valueLabelDisplay="auto"
                name="spO2HiAlarm"
                onChange={(e, v) => props.modifyForm(8, v, "spO2HiAlarm")}
                handleDragStop={(e, v) => props.modifyForm(8, v, "spO2HiAlarm")}
                step={1}
                marks
                //valueLabelFormat={(e) => e + " BPM"}
                min={90}
                max={100}
              />

              <Typography color="textSecondary" id="discrete-slider-lo" gutterBottom>
                SpO2 Low Alarm (BPM)
              </Typography>
              <Slider
                defaultValue={130}
                value={props.patient.FormData[8].requiredFields.spO2LoAlarm}
                aria-labelledby="discrete-slider-lo"
                valueLabelDisplay="auto"
                name="spO2LoAlarm"
                onChange={(e, v) => props.modifyForm(8, v, "spO2LoAlarm")}
                handleDragStop={(e, v) => props.modifyForm(8, v, "spO2LoAlarm")}
                step={1}
                marks
                //valueLabelFormat={(e) => e + " BPM"}
                min={90}
                max={100}
              />

              <Typography color="textSecondary" id="discrete-slider-hi-heart" gutterBottom>
                Heart Rate High Alarm (BPM)
              </Typography>
              <Slider
                defaultValue={130}
                value={props.patient.FormData[8].requiredFields.heartRateHiAlarm}
                aria-labelledby="discrete-slider-hi-heart"
                valueLabelDisplay="auto"
                name="heartRateHiAlarm"
                onChange={(e, v) => props.modifyForm(8, v, "heartRateHiAlarm")}
                handleDragStop={(e, v) => props.modifyForm(8, v, "heartRateHiAlarm")}
                step={5}
                marks
                //valueLabelFormat={(e) => e + " BPM"}
                min={115}
                max={240}
              />

              <Typography color="textSecondary" id="discrete-slider-lo-heart" gutterBottom>
                Heart Rate Low Alarm (BPM)
              </Typography>
              <Slider
                defaultValue={130}
                value={props.patient.FormData[8].requiredFields.heartRateHiAlarm}
                aria-labelledby="discrete-slider-lo"
                valueLabelDisplay="auto"
                name="heartRateHiAlarm"
                onChange={(e, v) => props.modifyForm(8, v, "heartRateHiAlarm")}
                handleDragStop={(e, v) => props.modifyForm(8, v, "heartRateHiAlarm")}
                step={5}
                marks
                //valueLabelFormat={(e) => e + " BPM"}
                min={45}
                max={100}
              />

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker disableToolbar variant="inline" fullWidth format="MM/dd/yyyy" margin="normal" label="Effective Dates I" error={props.checkFieldValidity(8, "effectiveDates1").valid && props.highestStep > 8}
              value={props.patient.FormData[8].requiredFields.effectiveDates1} style={{marginTop: "0px"}}
                onChange={(e) => props.modifyForm(8, e, "effectiveDates1")} KeyboardButtonProps={{'aria-label': 'change date',}}/>
              </MuiPickersUtilsProvider>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker disableToolbar variant="inline" fullWidth format="MM/dd/yyyy" margin="normal" label="Effective Dates II" error={props.checkFieldValidity(8, "effectiveDates2").valid && props.highestStep > 8}
              value={props.patient.FormData[8].requiredFields.effectiveDates2} style={{marginTop: "0px"}}
                onChange={(e) => props.modifyForm(8, e, "effectiveDates2")} KeyboardButtonProps={{'aria-label': 'change date',}}/>
              </MuiPickersUtilsProvider>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker disableToolbar variant="inline" fullWidth format="MM/dd/yyyy" margin="normal" label="Effective Dates III" error={props.checkFieldValidity(8, "effectiveDates3").valid && props.highestStep > 8}
              value={props.patient.FormData[8].requiredFields.effectiveDates3} style={{marginTop: "0px"}}
                onChange={(e) => props.modifyForm(8, e, "effectiveDates3")} KeyboardButtonProps={{'aria-label': 'change date',}}/>
              </MuiPickersUtilsProvider>


            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
        <Card className={classes.fullHeightCard}>
        <CardContent style={{position: "relative"}}>
          <Typography color="textSecondary" gutterBottom>
            Comments
          </Typography>
          <Button style={{position: "absolute", right: "17px", top: "10px"}} variant="contained" color="primary" onClick={(e) => props.addRemoveComment(e, 8, "add")}><Icon path={mdiPlus} size={1}/></Button>
          <p className="recommended"><b>Recommended Equipment: </b>
          <br/>A4450 - PaperTape Rolls</p>

          {props.patient.FormData[8].requiredFields.comments.map((e, i) =>
            <div style={{width: "100%"}}>
            <Button className={classes.delButton} variant="contained" color="secondary" onClick={(e) => props.addRemoveComment(i, 8, "remove")}><Icon path={mdiDelete} size={1}/></Button>
            <TextField className={classes.commentWidth} multiline rowsMax={4} size="small" margin="normal" variant="outlined" label={"Comment " + romanize(i + 1)} name={i.toString()} value={e} onChange={(e) => props.addRemoveComment(e, 8, "modify")} />
            </div>
          )}

        </CardContent>
        </Card>
        </Grid>



      </Grid>
    </div>
  );

}

export default OximeterInfo;
