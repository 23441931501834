import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Link, Switch, Redirect, useLocation } from "react-router-dom";
import Header from './Header.js';
import Home from './Home.js';
import Login from './Login.js';
import About from './About.js';
import Contact from './Contact.js';
import Feedback from './Feedback.js';
import Invitation from './Invitation.js';
import Dashboard from './Dashboard.js';
import AdminDashboard from './AdminDashboard.js';
import Form from './Form.js';
import { practicionerList, defaultPatient, getAge, resolve } from './utils.js';
import { tubeOrderNumbers } from './enums.js';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      apiURL: "https://api.cmnsolutions.com",
      patient: JSON.parse(JSON.stringify(defaultPatient)),
      CMNList: []
    }
  }

  componentDidMount = async () => {
    var token = localStorage.getItem('token')
    var user = localStorage.getItem('username')

    if (token && user) {
      var res = await fetch('https://api.cmnsolutions.com/login', {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: user,
          token: token
        })
      })
      var data = await res.json();
      console.log(data)
      this.setState({ ...data, username: user, token: token })
    }
  }

  modifyForm = (form, e, label) => {
    let patientCopy = Object.assign({}, this.state);
    if (e == "checkbox") {
      patientCopy.patient.FormData[form].requiredFields[label] = !patientCopy.patient.FormData[form].requiredFields[label];
    }
    else if (e.target) {
      patientCopy.patient.FormData[form].requiredFields[e.target.name] = e.target.value;
    }
    else if (label) {
      patientCopy.patient.FormData[form].requiredFields[label] = e;
    }
    this.setState(patientCopy);
  }

  modifyTrach = (tube, e, label) => {
    let patientCopy = Object.assign({}, this.state);
    var primaryOrSecondary = (tube == 0 ? "primaryTubeType" : "secondaryTubeType")
    if (label == "primaryTubeType" || label == "secondaryTubeType") {
      patientCopy.patient.FormData[2].requiredFields[label] = e.target.value;
      patientCopy.patient.FormData[2].requiredFields["orderNumber" + (tube == 0 ? "Primary" : "Secondary")] = "";
      patientCopy.patient.FormData[2].requiredFields.tubes[tube] = { application: "", model: "", distal: "", cannula: "", specialLength: "", cuff: "", fenestration: "", flange: "", size: "" }
    }
    else if (label == "orderNumber" + (tube == 0 ? "Primary" : "Secondary")) {
      patientCopy.patient.FormData[2].requiredFields["orderNumber" + (tube == 0 ? "Primary" : "Secondary")] = e.target.value;
      var tubeInstance = tubeOrderNumbers[patientCopy.patient.FormData[2].requiredFields[primaryOrSecondary]].find((f) => f.orderNumber == e.target.value);
      var fieldArr = Object.keys(tubeInstance);
      for (var x = 0; x < fieldArr.length; x++) {
        patientCopy.patient.FormData[2].requiredFields.tubes[tube][fieldArr[x]] = tubeInstance[fieldArr[x]]
      }
    }
    else {
      patientCopy.patient.FormData[2].requiredFields.tubes[tube][label] = e.target.value;
      var compareTube = tubeOrderNumbers[patientCopy.patient.FormData[2].requiredFields[primaryOrSecondary]].find((e) =>
        e.application == patientCopy.patient.FormData[2].requiredFields.tubes[tube].application &&
        e.model == patientCopy.patient.FormData[2].requiredFields.tubes[tube].model &&
        e.distal == patientCopy.patient.FormData[2].requiredFields.tubes[tube].distal &&
        e.cannula == patientCopy.patient.FormData[2].requiredFields.tubes[tube].cannula &&
        e.specialLength == patientCopy.patient.FormData[2].requiredFields.tubes[tube].specialLength &&
        e.cuff == patientCopy.patient.FormData[2].requiredFields.tubes[tube].cuff &&
        e.fenestration == patientCopy.patient.FormData[2].requiredFields.tubes[tube].fenestration &&
        e.flange == patientCopy.patient.FormData[2].requiredFields.tubes[tube].flange &&
        e.size == patientCopy.patient.FormData[2].requiredFields.tubes[tube].size
      )
      if (compareTube) {
        patientCopy.patient.FormData[2].requiredFields["orderNumber" + (tube == 0 ? "Primary" : "Secondary")] = compareTube.orderNumber
      }
      else {
        patientCopy.patient.FormData[2].requiredFields["orderNumber" + (tube == 0 ? "Primary" : "Secondary")] = ""
      }
    }
    this.setState(patientCopy);
  }

  modifyPhysician = (e) => {
    let patientCopy = Object.assign({}, this.state);
    console.log(e.target.name, e.target.value)
    patientCopy.patient.FormData[0].requiredFields[e.target.name] = e.target.value;
    if (e.target.name == "physicianIndex") {
      if (e.target.value == 0) {
        patientCopy.patient.FormData[0].requiredFields.physicianName = "";
        patientCopy.patient.FormData[0].requiredFields.physicianAddress = "";
        patientCopy.patient.FormData[0].requiredFields.physicianNPI = "";
        patientCopy.patient.FormData[0].requiredFields.physicianPhone = "";
        patientCopy.patient.FormData[0].requiredFields.physicianFax = "";
      }
      else {
        patientCopy.patient.FormData[0].requiredFields.physicianName = practicionerList[e.target.value].name;
        patientCopy.patient.FormData[0].requiredFields.physicianAddress = practicionerList[e.target.value].address;
        patientCopy.patient.FormData[0].requiredFields.physicianNPI = practicionerList[e.target.value].npi;
        patientCopy.patient.FormData[0].requiredFields.physicianPhone = practicionerList[e.target.value].phone;
        patientCopy.patient.FormData[0].requiredFields.physicianFax = practicionerList[e.target.value].fax;
      }
    }
    else {
      var comparePhysician = practicionerList.findIndex((e) =>
        e.name == patientCopy.patient.FormData[0].requiredFields.physicianName &&
        e.address == patientCopy.patient.FormData[0].requiredFields.physicianAddress &&
        e.npi == patientCopy.patient.FormData[0].requiredFields.physicianNPI &&
        e.phone == patientCopy.patient.FormData[0].requiredFields.physicianPhone &&
        e.fax == patientCopy.patient.FormData[0].requiredFields.physicianFax
      )
      if (comparePhysician != -1) {
        patientCopy.patient.FormData[0].requiredFields.physicianIndex = comparePhysician
      }
      else {
        patientCopy.patient.FormData[0].requiredFields.physicianIndex = 0
      }
    }
    this.setState(patientCopy);
  }

  addRemoveEquipment = (e) => {
    var patientTemp = this.state.patient
    var equipTemp = patientTemp.FormData.find((f) => f.name == e.target.name);
    if (equipTemp) {
      equipTemp.active = !equipTemp.active;
      this.setState({ patient: patientTemp })
    }
  }

  addRemoveComment = (e, form, action) => {
    let patientCopy = Object.assign({}, this.state);
    switch (action) {
      case "add":
        if (patientCopy.patient.FormData[form].requiredFields.comments.length < 5)
          patientCopy.patient.FormData[form].requiredFields.comments.push("")
        break;
      case "remove":
        patientCopy.patient.FormData[form].requiredFields.comments.splice(e, 1);
        break;
      case "modify":
        patientCopy.patient.FormData[form].requiredFields.comments[e.target.name] = e.target.value;
        break;
    }
    this.setState(patientCopy);
  }

  addRemoveContact = (e, field, action) => {
    let patientCopy = Object.assign({}, this.state);
    switch (action) {
      case "add":
        if (patientCopy.patient.FormData[7].requiredFields.contactInfo.length < 5)
          patientCopy.patient.FormData[7].requiredFields.contactInfo.push({ phone: "", changes: "" })
        break;
      case "remove":
        patientCopy.patient.FormData[7].requiredFields.contactInfo.splice(e, 1);
        break;
      case "modify":
        patientCopy.patient.FormData[7].requiredFields.contactInfo[e.target.name][field] = e.target.value;
        break;
    }
    this.setState(patientCopy);
  }

  addRemoveAppointment = (e, field, action) => {
    let patientCopy = Object.assign({}, this.state);
    switch (action) {
      case "add":
        if (patientCopy.patient.FormData[7].requiredFields.appointments.length < 5)
          patientCopy.patient.FormData[7].requiredFields.appointments.push({ known: "", additional: "" })
        break;
      case "remove":
        patientCopy.patient.FormData[7].requiredFields.appointments.splice(e, 1);
        break;
      case "modify":
        console.log(e.target.name)
        console.log(e.target.value)
        console.log(patientCopy.patient.FormData[7].requiredFields.appointments)
        patientCopy.patient.FormData[7].requiredFields.appointments[e.target.name][field] = e.target.value;
        break;
    }
    this.setState(patientCopy);
  }

  addRemoveProjection = (e, field, action) => {
    let patientCopy = Object.assign({}, this.state);
    switch (action) {
      case "add":
        if (patientCopy.patient.FormData[7].requiredFields.oxygenProjections.length < 15)
          patientCopy.patient.FormData[7].requiredFields.oxygenProjections.push({ reason: "", visitType: "", companyName: "", address: "", specialty: "", doctor: "", location: "", mileage: 0, transportDuration: 0, nonTransportDuration: 0, delayDuration: 0, occurances: 0, subTotal: 0 })
        break;
      case "remove":
        patientCopy.patient.FormData[7].requiredFields.oxygenProjections.splice(e, 1);
        break;
      case "modify":
        patientCopy.patient.FormData[7].requiredFields.oxygenProjections[e.target.name][field] = e.target.value;
        break;
    }
    this.setState(patientCopy);
  }

  resetForm = () => {
    this.setState({ patient: JSON.parse(JSON.stringify(defaultPatient)) });
  }

  saveForm = async () => {
    var res = await fetch('https://api.cmnsolutions.com/saveCMN', {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: this.state.username,
        token: this.state.token,
        data: this.state.patient
      })
    })
    var data = await res.json();
    return data;
  }

  validateInvite = async (uid) => {
    console.log(uid)
    var res = await fetch('https://api.cmnsolutions.com/verifyInvite', {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: uid
      })
    })
    var data = await res.json();
    return data.status;
  }

  sendLogin = async (username, password) => {
    var res = await fetch('https://api.cmnsolutions.com/login', {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: username,
        password: password
      })
    })
    var data = await res.json();
    if (data.token) {
      localStorage.setItem('token', data.token)
      localStorage.setItem('username', username)
      this.setState({ ...data, username: username, password: password })
      return data.isAuthed;
    }
  }

  sendLogout = async () => {
    var res = await fetch('https://api.cmnsolutions.com/logout', {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: this.state.username,
        token: this.state.token
      })
    })
    var data = await res.json();
    console.log(data)
    if (data) {
      localStorage.setItem('token', "")
      localStorage.setItem('username', "")
      this.setState({ isAuthed: false, username: "", token: "" })
      return data.isAuthed;
    }
  }

  fetchCMNList = async () => {
    var res = await fetch('https://api.cmnsolutions.com/fetchCMNList', {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: this.state.username,
        token: this.state.token
      })
    })

    var data = await res.json();
    if (data.status) {
      return ("Error: " + data.status)
    }
    return data;
  }

  fetchCMN = async (filename) => {
    var res = await fetch('https://api.cmnsolutions.com/fetchOneCMN', {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: this.state.username,
        token: this.state.token,
        filename: filename
      })
    })

    var data = await res.json();
    if (data.status) {
      return ("Error: " + data.status)
    }
    let patientCopy = Object.assign({}, this.state);
    patientCopy.patient.FormData = data.formData;
    patientCopy.patient._id = data._id;
    this.setState(patientCopy)
    return data;
  }

  fetchUsers = async (filename) => {
    var res = await fetch('https://api.cmnsolutions.com/getUsers', {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: this.state.username,
        password: this.state.password,
        token: this.state.token,
      })
    })

    var data = await res.json();
    if (!data.status) {
      return ("Error: " + data.status)
    }
    let patientCopy = Object.assign({}, this.state);
    patientCopy.patient.FormData = data.formData;
    patientCopy.patient._id = data._id;
    this.setState({ userList: data.users })
    return data;
  }

  generatePDF = async (filename) => {
    console.log(filename)
    var res = await fetch('https://api.cmnsolutions.com/printPDF', {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: this.state.username,
        token: this.state.token,
        filename: filename
      })
    })

    var data = await res.blob();
    const file = new Blob([data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, '_blank', 'fullscreen=yes');
    return data;
  }

  sendFeedback = async (name, phone, company, email, subject, text) => {
    var res = await fetch('https://api.cmnsolutions.com/sendFeedback', {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: name,
        phone: phone,
        company: company,
        email: email,
        subject: subject,
        text: text
      })
    })

    var data = await res.json();
    if (!data.status) {
      return (false)
    }
    return true;
  }


  fetchPatient = async (patientID) => {
    var res = await fetch('https://api.cmnsolutions.com/getPatient', {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: this.state.username,
        token: this.state.token,
        patientID: patientID
      })
    })
    var data = await res.json();
    if (data.status == "Not Found") {
      return false;
    }
    else {
      var payor = data.Patient.PatientInsuranceInfo.Payors.PatientPayorInfo.find((e) => new Date(e.payorPolicyInfo.EffectiveEndDate).getTime() > new Date().getTime())
      var physician = practicionerList.indexOf((e) => e.npi == data.Patient.PatientClinicalInfo.PrimaryDoctor.NPI);

      var patientCopy = Object.assign({}, this.state);

      if (payor) {
        patientCopy.patient.FormData[0].requiredFields.currentPayor = payor
      }

      patientCopy.patient.FormData[0].requiredFields.physicianIndex = physician != -1 ? practicionerList[physician] : 0
      patientCopy.patient.FormData[0].requiredFields.physicianName = physician != -1 ? practicionerList[physician].name : data.Patient.PatientClinicalInfo.PrimaryDoctor.Doctor.Value
      patientCopy.patient.FormData[0].requiredFields.physicianAddress = physician != -1 ? practicionerList[physician].address : Object.values(data.Patient.PatientClinicalInfo.PrimaryDoctor.Address).join('\n')
      patientCopy.patient.FormData[0].requiredFields.physicianNPI = physician != -1 ? practicionerList[physician].npi : data.Patient.PatientClinicalInfo.PrimaryDoctor.NPI
      patientCopy.patient.FormData[0].requiredFields.physicianPhone = physician != -1 ? practicionerList[physician].phone : data.Patient.PatientClinicalInfo.PrimaryDoctor.Phone
      patientCopy.patient.FormData[0].requiredFields.physicianFax = physician != -1 ? practicionerList[physician].fax : data.Patient.PatientClinicalInfo.PrimaryDoctor.Fax
      patientCopy.patient.FormData[0].requiredFields.patientSuffix = data.Patient.PatientGeneralInfo.Name.Suffix
      patientCopy.patient.FormData[0].requiredFields.patientLastName = data.Patient.PatientGeneralInfo.Name.Last
      patientCopy.patient.FormData[0].requiredFields.patientFirstName = data.Patient.PatientGeneralInfo.Name.First
      patientCopy.patient.FormData[0].requiredFields.patientMiddleName = data.Patient.PatientGeneralInfo.Name.Middle
      patientCopy.patient.FormData[0].requiredFields.patientBirth = new Date(data.Patient.PatientGeneralInfo.BirthDate)
      patientCopy.patient.FormData[0].requiredFields.patientAge = getAge(patientCopy.patient.FormData[0].requiredFields.patientBirth)
      patientCopy.patient.FormData[0].requiredFields.patientMR = data.Patient.PatientGeneralInfo.User1
      patientCopy.patient.FormData[0].requiredFields.patientBTID = data.Patient.PatientGeneralInfo.PtID
      patientCopy.patient.FormData[0].requiredFields.patientPhone = data.Patient.PatientGeneralInfo.BillingContactInfo.PhoneNumber
      patientCopy.patient.FormData[0].requiredFields.patientAddress1 = data.Patient.PatientGeneralInfo.BillingAddress.AddressLine1
      patientCopy.patient.FormData[0].requiredFields.patientAddress2 = data.Patient.PatientGeneralInfo.BillingAddress.AddressLine2
      patientCopy.patient.FormData[0].requiredFields.patientCity = data.Patient.PatientGeneralInfo.BillingAddress.City
      patientCopy.patient.FormData[0].requiredFields.patientCounty = data.Patient.PatientGeneralInfo.BillingAddress.County
      patientCopy.patient.FormData[0].requiredFields.patientState = data.Patient.PatientGeneralInfo.BillingAddress.State
      patientCopy.patient.FormData[0].requiredFields.patientCountry = data.Patient.PatientGeneralInfo.BillingAddress.Country
      patientCopy.patient.FormData[0].requiredFields.patientPostalCode = data.Patient.PatientGeneralInfo.BillingAddress.PostalCode
      patientCopy.patient.FormData[0].requiredFields.payorPrimary = (payor ? payor.payorPolicyInfo.PolicyNumber : "")
      patientCopy.patient.FormData[0].requiredFields.payorSecondary = ""
      patientCopy.patient.FormData[0].requiredFields.payorCertification = ""
      patientCopy.patient.FormData[0].requiredFields.payorLengthOfNeed = 99
      patientCopy.patient.FormData[0].requiredFields.payorCertFrom = (payor ? new Date(payor.payorPolicyInfo.EffectiveDate) : new Date())
      patientCopy.patient.FormData[0].requiredFields.payorCertTo = (payor ? new Date(payor.payorPolicyInfo.EffectiveEndDate) : new Date())
      patientCopy.patient.FormData[0].requiredFields.patientPrognosisIndex = 1
      patientCopy.patient.FormData[0].requiredFields.patientDiagnosis = data.Patient.PatientClinicalInfo.DiagnosisCodes.ICDCodeInfo.map((e) => e.ICDCode + " - " + e.Description)


      this.setState(patientCopy)
      return true;
    }

  }


  render() {

    return (
      <div>
        <Router>
          <Header isAuthed={this.state.isAuthed} sendLogout={this.sendLogout} username={this.state.username}/>

          <Route exact path='/' render={(props) => <Home {...props}
          />} />

          <Route exact path='/login' render={(props) => <Login {...props}
            sendLogin={this.sendLogin} isAuthed={this.state.isAuthed}
          />} />

          <Route exact path='/about' render={(props) => <About {...props}
          />} />

          <Route exact path='/contact' render={(props) => <Contact {...props}
          />} />

          <Route exact path='/feedback' render={(props) => <Feedback {...props}
            sendFeedback={this.sendFeedback}
          />} />

          <Route path='/invitation/:uid' render={(props) => <Invitation {...props}
            validateInvite={this.validateInvite}
	    apiURL={this.state.apiURL}
          />} />

          <Route exact path='/dashboard' render={(props) => this.state.isAuthed ?
            /*(this.state.username.toLowerCase() == "evanjones@medres" ?
              <AdminDashboard {...props}
                fetchUsers={this.fetchUsers}
                username={this.state.username}
                token={this.state.token}
              />
              :*/
              <Dashboard {...props}
                fetchCMNList={this.fetchCMNList}
                fetchCMN={this.fetchCMN}
                generatePDF={this.generatePDF}
              />//)
            :
            <Redirect to={{ pathname: "/login", state: { from: this.props.location } }} />
          } />

	  <Route exact path='/admin' render={(props) => this.state.username && this.state.username.toLowerCase() == "evanjones@medres" ?
		<AdminDashboard {...props}
		fetchUsers={this.fetchUsers}
		username={this.state.username}
		token={this.state.token}
		/>
		: null} />


          <Route exact path='/form' render={(props) => this.state.isAuthed ?
            <Form {...props}
              fetchPatient={this.fetchPatient}
              patient={this.state.patient}
              modifyForm={this.modifyForm}
              resetForm={this.resetForm}
              saveForm={this.saveForm}
              printForm={this.generatePDF}
              modifyTrach={this.modifyTrach}
              modifyPhysician={this.modifyPhysician}
              addRemoveComment={this.addRemoveComment}
              addRemoveContact={this.addRemoveContact}
              addRemoveAppointment={this.addRemoveAppointment}
              addRemoveProjection={this.addRemoveProjection}
              addRemoveEquipment={this.addRemoveEquipment} />
            :
            <Redirect to={{ pathname: "/login", state: { from: this.props.location } }} />
          } />

        </Router>
      </div>
    );
  }

}

export default App;
