import React, {useState} from 'react';
import './App.css';
import VerticalStepper from './Stepper.js'
import MobileStepper from './MobileStepper.js'
import PatientInfo from './FormPages/PatientInfo.js'
import VentilatorInfo from './FormPages/VentilatorInfo.js'
import TracheostomyInfo from './FormPages/TracheostomyInfo.js'
import SuctionMachineInfo from './FormPages/SuctionMachineInfo.js'
import NebulizerInfo from './FormPages/NebulizerInfo.js'
import EnteralInfo from './FormPages/EnteralInfo.js'
import ApneaMonitorInfo from './FormPages/ApneaMonitorInfo.js'
import OxygenInfo from './FormPages/OxygenInfo.js'
import OximeterInfo from './FormPages/OximeterInfo.js'
import CoughAssistInfo from './FormPages/CoughAssistInfo.js'
import CPAPInfo from './FormPages/CPAPInfo.js'
import BIPAPInfo from './FormPages/BIPAPInfo.js'
import OtherInfo from './FormPages/OtherInfo.js'
import CompletionPage from './FormPages/CompletionPage.js'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {validator} from "./utils";
import MediaQuery from 'react-responsive'

function Form(props) {
  const [state, setState] = useState({
    patientID: "",
    invalidCheck: false,
    activeStep: 0,
    highestStep: 0
  })

  const checkFieldValidity = (form, label) => {
    var isNotValid = !props.patient.FormData[form].requiredFields[label].toString().match(validator[form][label].regex)
    var msg = (isNotValid ? "" : validator[form][label].message)
    return({message: msg, valid: isNotValid})
  };

  var formList = [
    <PatientInfo
      patient={props.patient}
      checkFieldValidity={checkFieldValidity}
      modifyForm={props.modifyForm}
      modifyPhysician={props.modifyPhysician}
      highestStep={state.highestStep}
      addRemoveComment={props.addRemoveComment}
      addRemoveEquipment={props.addRemoveEquipment}/>,
    <VentilatorInfo
      patient={props.patient}
      checkFieldValidity={checkFieldValidity}
      modifyForm={props.modifyForm}
      highestStep={state.highestStep}
      addRemoveComment={props.addRemoveComment}
      addRemoveEquipment={props.addRemoveEquipment}/>,
    <TracheostomyInfo
      patient={props.patient}
      checkFieldValidity={checkFieldValidity}
      modifyTrach={props.modifyTrach}
      modifyForm={props.modifyForm}
      highestStep={state.highestStep}
      addRemoveComment={props.addRemoveComment}
      addRemoveEquipment={props.addRemoveEquipment}/>,
    <SuctionMachineInfo
      patient={props.patient}
      checkFieldValidity={checkFieldValidity}
      modifyForm={props.modifyForm}
      highestStep={state.highestStep}
      addRemoveComment={props.addRemoveComment}
      addRemoveEquipment={props.addRemoveEquipment}/>,
    <NebulizerInfo
      patient={props.patient}
      checkFieldValidity={checkFieldValidity}
      modifyForm={props.modifyForm}
      highestStep={state.highestStep}
      addRemoveComment={props.addRemoveComment}
      addRemoveEquipment={props.addRemoveEquipment}/>,
    <EnteralInfo
      patient={props.patient}
      checkFieldValidity={checkFieldValidity}
      modifyForm={props.modifyForm}
      highestStep={state.highestStep}
      addRemoveComment={props.addRemoveComment}
      addRemoveEquipment={props.addRemoveEquipment}/>,
    <ApneaMonitorInfo
      patient={props.patient}
      checkFieldValidity={checkFieldValidity}
      modifyForm={props.modifyForm}
      highestStep={state.highestStep}
      addRemoveComment={props.addRemoveComment}
      addRemoveEquipment={props.addRemoveEquipment}/>,
    <OxygenInfo
      patient={props.patient}
      checkFieldValidity={checkFieldValidity}
      modifyForm={props.modifyForm}
      highestStep={state.highestStep}
      addRemoveComment={props.addRemoveComment}
      addRemoveContact={props.addRemoveContact}
      addRemoveAppointment={props.addRemoveAppointment}
      addRemoveProjection={props.addRemoveProjection}
      addRemoveEquipment={props.addRemoveEquipment}/>,
    <OximeterInfo
      patient={props.patient}
      checkFieldValidity={checkFieldValidity}
      modifyForm={props.modifyForm}
      highestStep={state.highestStep}
      addRemoveComment={props.addRemoveComment}
      addRemoveEquipment={props.addRemoveEquipment}/>,
    <CoughAssistInfo
      patient={props.patient}
      checkFieldValidity={checkFieldValidity}
      modifyForm={props.modifyForm}
      highestStep={state.highestStep}
      addRemoveComment={props.addRemoveComment}
      addRemoveEquipment={props.addRemoveEquipment}/>,
    <CPAPInfo
      patient={props.patient}
      checkFieldValidity={checkFieldValidity}
      modifyForm={props.modifyForm}
      highestStep={state.highestStep}
      addRemoveComment={props.addRemoveComment}
      addRemoveEquipment={props.addRemoveEquipment}/>,
    <BIPAPInfo
      patient={props.patient}
      checkFieldValidity={checkFieldValidity}
      modifyForm={props.modifyForm}
      highestStep={state.highestStep}
      addRemoveComment={props.addRemoveComment}
      addRemoveEquipment={props.addRemoveEquipment}/>,
    <OtherInfo
      patient={props.patient}
      checkFieldValidity={checkFieldValidity}
      modifyForm={props.modifyForm}
      highestStep={state.highestStep}
      addRemoveComment={props.addRemoveComment}
      addRemoveEquipment={props.addRemoveEquipment}/>
    ];

  const handleChange = (e) => {
    setState({...state, [e.target.name]: e.target.value})
  }

  const tryFetchPatient = async (e) => {
    var res = await props.fetchPatient(state.patientID)
    setState({...state, invalidCheck: !res})
  }

  const handleNext = (e) => {
    console.log("Next")
    e.stopPropagation();
    setState({...state, activeStep: state.activeStep + 1, highestStep: (props.patient.FormData.filter((e) => e.active)[state.activeStep + 1] && state.highestStep < props.patient.FormData.filter((e) => e.active)[state.activeStep + 1].index ? props.patient.FormData.filter((e) => e.active)[state.activeStep + 1].index : state.highestStep)});
  };

  const handleGoTo = (val) => {
    console.log("Go To")
    setState({...state, activeStep: val, highestStep: (props.patient.FormData.filter((e) => e.active)[val] && state.highestStep < props.patient.FormData.filter((e) => e.active)[val].index ? props.patient.FormData.filter((e) => e.active)[val].index : state.highestStep)});
  };

  const handleBack = (e) => {
    console.log("Back")
    e.stopPropagation();
    setState({...state, activeStep: state.activeStep - 1});
  };

  const handleReset = () => {
    setState({...state, activeStep: 0, highestStep: 0});
    props.resetForm()
  };

  const checkFormValidity = (form) => {
    form = props.patient.FormData.filter((e) => e.active)[form].index
    var msg = "Missing: "
    var isAllNotValid = false;
    if(state.highestStep > form){
      for(var x = 0; x < Object.keys(validator[form]).length; x++){
        var isNotValid
        var isConditionMet = true;
        if(validator[form][Object.keys(validator[form])[x]].orCondition){
          isConditionMet = false;
          for(var y = 0; y < validator[form][Object.keys(validator[form])[x]].orCondition.length / 2; y++){
            if(props.patient.FormData[form].requiredFields[ validator[form][Object.keys(validator[form])[x]].orCondition[y * 2] ] == validator[form][Object.keys(validator[form])[x]].orCondition[(y * 2) + 1]){
              isConditionMet = true;
            }
          }
        }
        isNotValid = !props.patient.FormData[form].requiredFields[Object.keys(validator[form])[x]].toString().match(validator[form][Object.keys(validator[form])[x]].regex)
        if(isNotValid && isConditionMet){
          msg += validator[form][Object.keys(validator[form])[x]].message + ", "
          isAllNotValid = true;
        }
      }

      return({message: msg.length > 50 ? msg.substring(0,50) + '...' : msg.slice(0, -2), valid: isAllNotValid})
    }
    else{
      return({message: msg.slice(0, -2), valid: false})
    }

  };

  const checkFormCompletion = (form) => {
    var msg = "Missing Validation and Comments"
    var valid = (state.highestStep > form ? true : false)
    return({message: msg, valid: valid})
  };

  return (
    <div>
    <MediaQuery minDeviceWidth={960}>
      <div style={{display: "flex", height: "calc(100vh - 114px)"}} className="formBack">
        <div style={{flex: 1, margin: "20px 15px"}}>
        <Card style={{height: "calc(100vh - 160px)", overflow: "auto"}}>
          <CardContent>
            <TextField error={state.invalidCheck} fullWidth margin="normal" id="standard-basic" variant="outlined" label="Patient ID" value={state.patientID} name="patientID" onChange={handleChange} />
            <Button variant="contained" color="primary" onClick={tryFetchPatient} style={{marginRight: "4px", marginTop: "4px"}}>Find Patient</Button>
            <Button variant="contained" color="secondary" onClick={handleReset} style={{marginTop: "4px"}}>Reset Form</Button>
            <VerticalStepper
              patient={props.patient}
              activeStep={state.activeStep}
              checkValidity={checkFormValidity}
              handleNext={handleNext}
              handleBack={handleBack}
              handleGoTo={handleGoTo}
              handleReset={handleReset}/>
          </CardContent>
        </Card>
        </div>
        <div style={{flex: 4, overflow: "auto"}}>
          {state.activeStep < props.patient.FormData.filter((e) => e.active).length ? formList[props.patient.FormData.filter((e) => e.active)[state.activeStep].index] : <CompletionPage saveForm={props.saveForm} printForm={props.printForm}/>}
        </div>
      </div>
      </MediaQuery>
      <MediaQuery maxDeviceWidth={959}>
      <div style={{minHeight: "calc(100vh - 114px)"}} className="formBack">
        <MobileStepper
        patient={props.patient}
        activeStep={state.activeStep}
        checkValidity={checkFormValidity}
        handleNext={handleNext}
        handleBack={handleBack}
        handleGoTo={handleGoTo}
        handleReset={handleReset}/>
        <div style={{ overflow: "auto"}}>
          {state.activeStep < props.patient.FormData.filter((e) => e.active).length ? formList[props.patient.FormData.filter((e) => e.active)[state.activeStep].index] : <CompletionPage saveForm={props.saveForm} printForm={props.printForm}/>}
        </div>
        <MobileStepper
        patient={props.patient}
        activeStep={state.activeStep}
        checkValidity={checkFormValidity}
        handleNext={handleNext}
        handleBack={handleBack}
        handleGoTo={handleGoTo}
        handleReset={handleReset}/>
      </div>
      </MediaQuery>
    </div>
  );

}

export default Form;
