import React, {Component} from 'react';
import '../App.css';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Icon from '@mdi/react'
import { mdiDelete, mdiPlus } from '@mdi/js';
import {romanize} from "../utils.js"

const useStyles = makeStyles((theme) => ({
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 120,
    marginTop: "16px"
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
    },
  },
  fullHeightCard: {
		height: "100%",
	},
  halfWidth: {
    width: "50%"
  },
  quartWidth:{
    width: "25%"
  },
  threeQuartWidth:{
    width: "75%"
  },
  commentWidth:{
    marginTop: "8px",
    width: "calc(100% - 69px)"
  },
  delButton:{
    marginTop: "8px",
    marginRight: "4px",
    height: "40px"
  }
}));

function VentilatorInfo(props) {
  const classes = useStyles();

  return (
    <div style={{padding: "20px"}}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Card className={classes.fullHeightCard}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Ventilator Information
              </Typography>
              <form className={classes.root} noValidate autoComplete="off">

              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel>Model</InputLabel>
                <Select name="ventilatorModel" error={props.checkFieldValidity(1, "ventilatorModel").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.ventilatorModel} onChange={(e) => props.modifyForm(1, e)} label="Model" >
                  <MenuItem value={"LTV 950 Ventilator"}>LTV 950 Ventilator</MenuItem>
                  <MenuItem value={"LTV 1150 Ventilator"}>LTV 1150 Ventilator</MenuItem>
                  <MenuItem value={"T100 Ventilator"}>T100 Ventilator</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel>Method</InputLabel>
                <Select name="ventilatorMethod" value={props.patient.FormData[1].requiredFields.ventilatorMethod} onChange={(e) => props.modifyForm(1, e)} label="Method" >
                  <MenuItem value={"Invasive Ventilator (E0465)"}>Invasive (via Tracheostomy)</MenuItem>
                  <MenuItem value={"Non-Invasive Ventilator (E0466)"}>Non-Invasive (via mask)</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel>Usage</InputLabel>
                <Select name="ventilatorUsage" value={props.patient.FormData[1].requiredFields.ventilatorUsage} onChange={(e) => props.modifyForm(1, e)} label="Usage" >
                  <MenuItem value={"Continuously"}>Continuously</MenuItem>
                  <MenuItem value={"While asleep/unattended"}>While asleep/unattended</MenuItem>
                </Select>
              </FormControl>

              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={9}>
        <Card className={classes.fullHeightCard}>
          <CardContent style={{position: "relative"}}>
            <Typography color="textSecondary" gutterBottom>
              Comments
            </Typography>
            <Button style={{position: "absolute", right: "17px", top: "10px"}} variant="contained" color="primary" onClick={(e) => props.addRemoveComment(e, 1, "add")}><Icon path={mdiPlus} size={1}/></Button>
            <p className="recommended"><b>Related Equipment & Supply Items may Include: </b>
            <br/>E0562 - Heated Ventilator Humidifier
            <br/>A7046 - Humidifier Chambers (A7046)
            <br/>A4618 - Ventilator Circuits (A4618)
            <br/>S8999 - Resuscitation Bag (S8999)
            <br/>A7034 - Mask
            <br/>L8501 - Passy Muir Valve (L8501)
            <br/>A4611/A4601 - AC/DC Chargers, Batteries, Cords, Auto Adapters
            <br/>S8189 - Flex Tubes & Fan Filters
            <br/>E1399/A9900 - IV Pole, Peep Valves, Swivel Adapters, Air Inlet & Bacteria
            Filters, Aerochambers, Heated Wire Adapters & Spacers
            <br/>A4217 (500ML of Sterile Water/Saline) & A4216 (10ML of Water/Saline)</p>

            {props.patient.FormData[1].requiredFields.ventilatorMethod == "Non-Invasive Ventilator (E0466)" ?
              <p><b>**NON-INVASIVE CONFIGURATION**</b>
            <br/>A7031 - Headgear, A7035 - Full Face Cushion</p>
            : null}

            {props.patient.FormData[1].requiredFields.comments.map((e, i) =>
              <div style={{width: "100%"}}>
              <Button className={classes.delButton} variant="contained" color="secondary" onClick={(e) => props.addRemoveComment(i, 1, "remove")}><Icon path={mdiDelete} size={1}/></Button>
              <TextField className={classes.commentWidth} multiline rowsMax={4} size="small" margin="normal" variant="outlined" label={"Comment " + romanize(i + 1)} name={i.toString()} value={e} onChange={(e) => props.addRemoveComment(e, 1, "modify")} />
              </div>
            )}

          </CardContent>
        </Card>
        </Grid>

        <Grid item xs={12} style={{display: (props.patient.FormData[1].requiredFields.ventilatorModel == "T100 Ventilator" ? "" : "none")}}>
        <Card className={classes.fullHeightCard}>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Trilogy Ventilator Therapy Settings
            </Typography>
              <Grid container spacing={1}>
                <Grid item xs={6} md={2}>
                  <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <InputLabel>Dual Prescription</InputLabel>
                    <Select name="trilogyDual" error={props.checkFieldValidity(1, "trilogyDual").valid && props.highestStep > 1}
                    value={props.patient.FormData[1].requiredFields.trilogyDual} onChange={(e) => props.modifyForm(1, e)} label="Dual Prescription" >
                      <MenuItem value={"No"}>No</MenuItem>
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Mode" name="trilogyMode" error={props.checkFieldValidity(1, "trilogyMode").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.trilogyMode} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Rate" name="trilogyRate" error={props.checkFieldValidity(1, "trilogyRate").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.trilogyRate} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Vt/PC" name="trilogyVTPC" error={props.checkFieldValidity(1, "trilogyVTPC").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.trilogyVTPC} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}>
                  <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <InputLabel>AVAPS</InputLabel>
                    <Select name="trilogyAVAPS" value={props.patient.FormData[1].requiredFields.trilogyAVAPS} onChange={(e) => props.modifyForm(1, e)} label="AVAPS" >
                      <MenuItem value={"No"}>No</MenuItem>
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="IPAP Max/Min" name="trilogyIPAP" error={props.checkFieldValidity(1, "trilogyIPAP").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.trilogyIPAP} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="IPAP/EPAP" name="trilogyIPAPEPAP" error={props.checkFieldValidity(1, "trilogyIPAPEPAP").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.trilogyIPAPEPAP} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="I Time" name="trilogyITime" error={props.checkFieldValidity(1, "trilogyITime").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.trilogyITime} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Pressure Support" name="trilogyPressureSupport" error={props.checkFieldValidity(1, "trilogyPressureSupport").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.trilogyPressureSupport} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="PEEP" name="trilogyPEEP" error={props.checkFieldValidity(1, "trilogyPEEP").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.trilogyPEEP} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Flow Trigger/Auto-Trak" name="trilogyFlowAuto" error={props.checkFieldValidity(1, "trilogyFlowAuto").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.trilogyFlowAuto} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Rise Time" name="trilogyRiseTime" error={props.checkFieldValidity(1, "trilogyRiseTime").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.trilogyRiseTime} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Sensitivity" name="trilogySensitivity" error={props.checkFieldValidity(1, "trilogySensitivity").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.trilogySensitivity} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Sigh" name="trilogySigh" error={props.checkFieldValidity(1, "trilogySigh").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.trilogySigh} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Hi/Lo Insp Pressure" name="trilogyInspPressure" error={props.checkFieldValidity(1, "trilogyInspPressure").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.trilogyInspPressure} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Flow Trigger" name="trilogyFlowTrigger" error={props.checkFieldValidity(1, "trilogyFlowTrigger").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.trilogyFlowTrigger} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Flow Cycle Sen" name="trilogyFlowCycle" error={props.checkFieldValidity(1, "trilogyFlowCycle").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.trilogyFlowCycle} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}>
                  <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <InputLabel>Flex</InputLabel>
                    <Select name="trilogyFlex" error={props.checkFieldValidity(1, "trilogyFlex").valid && props.highestStep > 1}
                    value={props.patient.FormData[1].requiredFields.trilogyFlex} onChange={(e) => props.modifyForm(1, e)} label="Flex" >
                      <MenuItem value={"No"}>No</MenuItem>
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6} md={2}>
                  <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <InputLabel>Ramp</InputLabel>
                    <Select name="trilogyRamp" error={props.checkFieldValidity(1, "trilogyRamp").valid && props.highestStep > 1}
                    value={props.patient.FormData[1].requiredFields.trilogyRamp} onChange={(e) => props.modifyForm(1, e)} label="Ramp" >
                      <MenuItem value={"No"}>No</MenuItem>
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} style={{display: (props.patient.FormData[1].requiredFields.ventilatorModel == "T100 Ventilator" ? "" : "none")}}>
        <Card className={classes.fullHeightCard}>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Alarm Settings
            </Typography>
              <Grid container spacing={3}>
                <Grid item xs={6} md={2}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel>Apnea</InputLabel>
                    <Select name="trilogyApnea" error={props.checkFieldValidity(1, "trilogyApnea").valid && props.highestStep > 1}
                    value={props.patient.FormData[1].requiredFields.trilogyApnea} onChange={(e) => props.modifyForm(1, e)} label="Apnea" >
                      <MenuItem value={"Off"}>Off</MenuItem>
                      <MenuItem value={"On"}>On</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Apnea Rate" name="trilogyApneaRate" error={props.checkFieldValidity(1, "trilogyApneaRate").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.trilogyApneaRate} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Disconnect" name="trilogyDisconnect" error={props.checkFieldValidity(1, "trilogyDisconnect").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.trilogyDisconnect} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Alarm Volume" name="trilogyAlarmVol" error={props.checkFieldValidity(1, "trilogyAlarmVol").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.trilogyAlarmVol} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Hi VT" name="trilogyHiVT" error={props.checkFieldValidity(1, "trilogyHiVT").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.trilogyHiVT} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Lo VT" name="trilogyLoVT" error={props.checkFieldValidity(1, "trilogyLoVT").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.trilogyLoVT} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Hi VE" name="trilogyHiVE" error={props.checkFieldValidity(1, "trilogyHiVE").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.trilogyHiVE} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Lo VE" name="trilogyLoVE" error={props.checkFieldValidity(1, "trilogyLoVE").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.trilogyLoVE} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Hi RR" name="trilogyHiRR" error={props.checkFieldValidity(1, "trilogyHiRR").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.trilogyHiRR} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Lo RR" name="trilogyLoRR" error={props.checkFieldValidity(1, "trilogyLoRR").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.trilogyLoRR} onChange={(e) => props.modifyForm(1, e)} /></Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} style={{display: (props.patient.FormData[1].requiredFields.ventilatorModel == "LTV 950 Ventilator" || props.patient.FormData[1].requiredFields.ventilatorModel == "LTV 1150 Ventilator" ? "" : "none")}}>
        <Card className={classes.fullHeightCard}>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
            LVT Ventilator Settings
            </Typography>
              <Grid container spacing={3}>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="B Rate" name="LVTBRate" error={props.checkFieldValidity(1, "LVTBRate").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.LVTBRate} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Tidal Vol (BorD)" name="LVTTidalVol" error={props.checkFieldValidity(1, "LVTTidalVol").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.LVTTidalVol} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Press Cont (BorD)" name="LVTPressCont" error={props.checkFieldValidity(1, "LVTPressCont").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.LVTPressCont} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Ins Time" name="LVTInsTime" error={props.checkFieldValidity(1, "LVTInsTime").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.LVTInsTime} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Press Support (BorD)" name="LVTPressSupport" error={props.checkFieldValidity(1, "LVTPressSupport").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.LVTPressSupport} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Sens" name="LVTSens" error={props.checkFieldValidity(1, "LVTSens").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.LVTSens} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="PEEP" name="LVTPEEP" error={props.checkFieldValidity(1, "LVTPEEP").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.LVTPEEP} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Vol or Pressure" name="LVTVolPressure" error={props.checkFieldValidity(1, "LVTVolPressure").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.LVTVolPressure} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Asst/Ctrl or SIMV/CPAP" name="LVTAsstSIMV" error={props.checkFieldValidity(1, "LVTAsstSIMV").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.LVTAsstSIMV} onChange={(e) => props.modifyForm(1, e)} /></Grid>

                <Grid item xs={6} md={2}><FormControl fullWidth variant="outlined" className={classes.formControl}>
                  <InputLabel>NPPV</InputLabel>
                  <Select name="LVTNPPV" error={props.checkFieldValidity(1, "LVTNPPV").valid && props.highestStep > 1}
                  value={props.patient.FormData[1].requiredFields.LVTNPPV} onChange={(e) => props.modifyForm(1, e)} label="NPPV" >
                    <MenuItem value={"Off"}>Off</MenuItem>
                    <MenuItem value={"On"}>On</MenuItem>
                  </Select>
                </FormControl></Grid>

                <Grid item xs={6} md={2}><FormControl fullWidth variant="outlined" className={classes.formControl}>
                  <InputLabel>Insp/Exp Hold</InputLabel>
                  <Select name="LVTInsp" error={props.checkFieldValidity(1, "LVTInsp").valid && props.highestStep > 1}
                  value={props.patient.FormData[1].requiredFields.LVTInsp} onChange={(e) => props.modifyForm(1, e)} label="Insp/Exp Hold" >
                    <MenuItem value={"Off"}>Off</MenuItem>
                    <MenuItem value={"On"}>On</MenuItem>
                  </Select>
                </FormControl></Grid>

                <Grid item xs={6} md={2}><FormControl fullWidth variant="outlined" className={classes.formControl}>
                  <InputLabel>Ctrl Lock</InputLabel>
                  <Select name="LVTCtrlLock" error={props.checkFieldValidity(1, "LVTCtrlLock").valid && props.highestStep > 1}
                  value={props.patient.FormData[1].requiredFields.LVTCtrlLock} onChange={(e) => props.modifyForm(1, e)} label="Ctrl Lock" >
                    <MenuItem value={"Off"}>Off</MenuItem>
                    <MenuItem value={"On"}>On</MenuItem>
                  </Select>
                </FormControl></Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} style={{display: (props.patient.FormData[1].requiredFields.ventilatorModel == "LTV 950 Ventilator" || props.patient.FormData[1].requiredFields.ventilatorModel == "LTV 1150 Ventilator" ? "" : "none")}}>
        <Card className={classes.fullHeightCard}>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
            Alarm Settings
            </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}><TextField fullWidth margin="normal" variant="outlined" label="Hi Pressure Limit" name="LVTHiPressure" error={props.checkFieldValidity(1, "LVTHiPressure").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.LVTHiPressure} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={12} md={4}><TextField fullWidth margin="normal" variant="outlined" label="Low Pressure Limit" name="LVTLoPressure" error={props.checkFieldValidity(1, "LVTLoPressure").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.LVTLoPressure} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={12} md={4}><TextField fullWidth margin="normal" variant="outlined" label="Low Minimum Volume" name="LVTLoMinVol" error={props.checkFieldValidity(1, "LVTLoMinVol").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.LVTLoMinVol} onChange={(e) => props.modifyForm(1, e)} /></Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} style={{display: (props.patient.FormData[1].requiredFields.ventilatorModel == "LTV 950 Ventilator" || props.patient.FormData[1].requiredFields.ventilatorModel == "LTV 1150 Ventilator" ? "" : "none")}}>
        <Card className={classes.fullHeightCard}>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
            Extended Alarm OP Settings
            </Typography>
              <Grid container spacing={3}>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="AI Volume" name="LVTAIVol" error={props.checkFieldValidity(1, "LVTAIVol").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.LVTAIVol} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Apnea Int" name="LVTApneaInt" error={props.checkFieldValidity(1, "LVTApneaInt").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.LVTApneaInt} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="HP Delay" name="LVTHPDelay" error={props.checkFieldValidity(1, "LVTHPDelay").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.LVTHPDelay} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="LPP Alarm" name="LVTLPPAlarm" error={props.checkFieldValidity(1, "LVTLPPAlarm").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.LVTLPPAlarm} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Hi F" name="LVTHiF" error={props.checkFieldValidity(1, "LVTHiF").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.LVTHiF} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Hi PEEP" name="LVTHiPEEP" error={props.checkFieldValidity(1, "LVTHiPEEP").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.LVTHiPEEP} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Lo PEEP" name="LVTLoPEEP" error={props.checkFieldValidity(1, "LVTLoPEEP").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.LVTLoPEEP} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="PNT Assist" name="LVTPNTAssist" error={props.checkFieldValidity(1, "LVTPNTAssist").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.LVTPNTAssist} onChange={(e) => props.modifyForm(1, e)} /></Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} style={{display: (props.patient.FormData[1].requiredFields.ventilatorModel == "LTV 950 Ventilator" || props.patient.FormData[1].requiredFields.ventilatorModel == "LTV 1150 Ventilator" ? "" : "none")}}>
        <Card className={classes.fullHeightCard}>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
            Extended Ventilator OP Settings
            </Typography>
              <Grid container spacing={3}>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Rise Time" name="LVTRiseTime" error={props.checkFieldValidity(1, "LVTRiseTime").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.LVTRiseTime} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Flow Term" name="LVTFlowTerm" error={props.checkFieldValidity(1, "LVTFlowTerm").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.LVTFlowTerm} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Time Term" name="LVTTimeTerm" error={props.checkFieldValidity(1, "LVTTimeTerm").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.LVTTimeTerm} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="PC Flow Term" name="LVTPCFlowTerm" error={props.checkFieldValidity(1, "LVTPCFlowTerm").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.LVTPCFlowTerm} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Leak Comp" name="LVTLeakComp" error={props.checkFieldValidity(1, "LVTLeakComp").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.LVTLeakComp} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="NPPV Mode" name="LVTNPPVMode" error={props.checkFieldValidity(1, "LVTNPPVMode").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.LVTNPPVMode} onChange={(e) => props.modifyForm(1, e)} /></Grid>
                <Grid item xs={6} md={2}><TextField fullWidth margin="normal" variant="outlined" label="Ctrl Unlock" name="LVTCtrlUnlock" error={props.checkFieldValidity(1, "LVTCtrlUnlock").valid && props.highestStep > 1}
                value={props.patient.FormData[1].requiredFields.LVTCtrlUnlock} onChange={(e) => props.modifyForm(1, e)} /></Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>


      </Grid>
    </div>
  );

}

export default VentilatorInfo;
