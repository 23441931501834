import React, {useState, useEffect} from 'react';
import './App.css';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

function Login(props) {
  const [state, setState] = useState({
    username: "",
    password: "",
    errored: false
  })

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  }

  const tryLogin = async () => {
    var res = await props.sendLogin(state.username, state.password)
    if(res){
      props.history.push('/dashboard');
    }
    else{
      console.log("Alert invalid login")
      setState({...state, errored: true})
    }
  }

  useEffect(() => {
    if(props.isAuthed){
      props.history.push('/dashboard');
    }

    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        tryLogin();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [props.isAuthed]);


    return (
      <div className="formBack" style={{alignItems: "center", display: "flex", justifyContent: "center"}}>
        <div style={{width: "300px", marginTop: "-10%", textAlign: "center"}}>
          <Card>
            <CardContent>
              <form noValidate autoComplete="off">
              <h2 style={{fontWeight: 300, color: "rgba(0, 0, 0, 0.54)", margin: "2px 0px 12px 0px", textAlign: "lef333t"}}>Login</h2>
              <TextField error={state.errored} fullWidth margin="normal" id="standard-basic" variant="outlined" label="Username" value={state.username} onChange={handleChange} name="username" />
              <TextField error={state.errored} fullWidth margin="normal" id="standard-basic" variant="outlined" type="password" autoComplete="current-password" label="Password" value={state.password} onChange={handleChange} name="password"/>
              <Button variant="contained" color="primary" onClick={tryLogin}>LOGIN</Button>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    );

}

export default Login;
