import React, {useEffect} from 'react';
import { useHistory } from "react-router-dom";
import moment from "moment"
import './App.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import MediaQuery from 'react-responsive'

const columns = [
  { id: 'filename', align: 'left', label: 'File', minWidth: 170 },
  { id: 'patient', align: 'left', label: 'Patient', minWidth: 170 },
  { id: 'created', align: 'left', label: 'Created', format: (value) => moment(value).format("MM/DD/YY"), minWidth: 170 },
  { id: 'modified', align: 'left', label: 'Modified', format: (value) => moment(value).format("MM/DD/YY"), minWidth: 100 },
  {
    id: 'createdBy',
    label: 'Created By',
    minWidth: 170,
    align: 'left',
  },
];

const mobileColumns = [
  { id: 'filename', align: 'left', label: 'File'}
];

function Dashboard(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [list, setList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(-1);
  const history = useHistory();

  useEffect(() => {
    async function getListings(){
      var CMNList = await props.fetchCMNList()
      if(typeof CMNList == "object")
      setList(CMNList)
    }
    getListings()
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const tryEditCMN = async (filename) => {
    var success = await props.fetchCMN(filename);
    if(!success.status){
      history.push("/form");
    }
  }

  const tryViewCMN = async (filename, index) => {
    setIsLoading(index)
    var success = await props.generatePDF(filename);
    if(!success.status){
      console.log(success)
    }
    setIsLoading(-1)
  }

    return (
      <div className="formBack" style={{padding: "20px", height: "calc(100vh - 154px)"}}>
      <MediaQuery minDeviceWidth={960}>
      <Paper style={{padding: "10px"}}>
      <h1>Medical Resources Inc.</h1>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
      </p>
      <br/>
      </Paper>
      <br/>
      <Paper>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell
                key={"density"}
                align='right'
                style={{ minWidth: 100 }}
              >
              <Link to="/form"><Button size="small" variant="contained" color="primary">Create New</Button></Link>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                  <TableCell key={5} align="right">
                    <Button size="small" variant="contained" color="primary" onClick={() => tryEditCMN(row.filename)}>Edit</Button>
                    <span style={{margin: "0 5px"}}/>
                    {isLoading != row._id ? <Button size="small" disabled={!row.isPrintReady} variant="contained" color="primary" onClick={() => tryViewCMN(row.filename, row._id)}>View</Button> :
                    <Button size="small" disabled={!row.isPrintReady} variant="contained" color="primary"><CircularProgress color="#e3f2fd" size={24} /></Button>}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={Math.floor(list.length/rowsPerPage)}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      </Paper>
      </MediaQuery>
      <MediaQuery maxDeviceWidth={959}>
      <Paper style={{padding: "10px"}}>
      <h1>Medical Resources Inc.</h1>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
      </p>
      <br/>
      </Paper>
      <br/>
      <Paper>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {mobileColumns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell
                key={"density"}
                align='right'
                style={{ minWidth: 100 }}
              >
              <Link to="/form"><Button size="small" variant="contained" color="primary">Create New</Button></Link>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                  {mobileColumns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                  <TableCell key={5} align="right">
                    <Button size="small" variant="contained" color="primary" onClick={() => tryEditCMN(row.filename)}>Edit</Button>
                    <span style={{margin: "0 5px"}}/>
                    {isLoading != row._id ? <Button size="small" disabled={!row.isPrintReady} variant="contained" color="primary" onClick={() => tryViewCMN(row.filename, row._id)}>View</Button> :
                    <Button size="small" disabled={!row.isPrintReady} variant="contained" color="primary"><CircularProgress color="#e3f2fd" size={24} /></Button>}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={Math.floor(list.length/rowsPerPage)}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      </Paper>
      </MediaQuery>
      </div>
    );

}

export default Dashboard;
