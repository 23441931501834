import moment from 'moment'

export var getAge = function(birthDate){
  var today = new Date();
  if(typeof birthDate.getMonth !== 'function'){
    birthDate = new Date(birthDate)
  }
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
  }
  return age;
}

export var unCamelCase = function(str){
    return str
        // insert a space between lower & upper
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        // space before last upper in a sequence followed by lower
        .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
        // uppercase the first character
        .replace(/^./, function(str){ return str.toUpperCase(); })
}

export var resolve = function(path, obj=window.self, separator='.') {
    var properties = Array.isArray(path) ? path : path.split(separator)
    return properties.reduce((prev, curr) => prev && prev[curr], obj)
}


export var romanize = function(num) {
    if (isNaN(num))
        return NaN;
    var digits = String(+num).split(""),
        key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
               "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
               "","I","II","III","IV","V","VI","VII","VIII","IX"],
        roman = "",
        i = 3;
    while (i--)
        roman = (key[+digits.pop() + (i * 10)] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
}

export var practicionerList = [
  {name: "Manual Doctor Entry", address: "", npi: "", phone: "", fax: ""},
  {name: "Black, Allison M.D.", address: "1600 7th Avenue South,\nBirmingham, AL, 35233-1711", npi: "1568685535", phone: "2059396980", fax: "2059399977"},
  {name: "Buchan, Kathryn M.D.", address: "1700 6th Avenue South, Suite 9380 \nBirmingham, AL, 35233", npi: "1184658221", phone: "2059344948", fax: ""},
  {name: "Carlo, Waldemar F. M.D.", address: "619 19th Street South, 176F Suite 9100 \nBirmingham, AL, 35249", npi: "1548583883", phone: "2059343460", fax: "2059756291"},
  {name: "Carlo, Waldemar A. M.D.", address: "PO Box 55823,\nBirmingham, AL, 35255-5823", npi: "1225072770", phone: "2059962244", fax: "2059962254"},
  {name: "Coghill, Carl M.D.", address: "1700 6th Avenue South, Suite 9380 \nBirmingham, AL, 35233", npi: "1902857899", phone: "2059962244", fax: "2059962254"},
  {name: "Cunningham, Vicki CRNP", address: "1940 Elmer J Bissell Road,\nBirmingham, AL, 35243-2941", npi: "1205104536", phone: "2059395293", fax: "2059395329"},
  {name: "Dabal, Robert M.D.", address: "619 19th Street South,\nBirmingham, AL, 35249", npi: "1497728547", phone: "2059342419", fax: "2059966551"},
  {name: "EL-Ferzli, George M.D", address: "1600 7Th Avenue South,\nBirmingham, AL, 35233", npi: "1568671907", phone: "2059343795", fax: "2059343100"},
  {name: "Gallizzi, Gina M.D", address: "1600 7th Avenue South,\nBirmingham, AL, 35249-1900", npi: "1306049705", phone: "2059343795", fax: ""},
  {name: "Gutierrez, Hector M.D.", address: "1600 7th Avenue South, ACC Building 620 \nBirmingham, AL, 35233", npi: "1326035189", phone: "2059343574", fax: "2059755983"},
  {name: "Hains, Coralie M.D.", address: "1600 7th Avenue South, Acc 614 \nBirmingham, AL, 35233", npi: "1427092246", phone: "2059399072", fax: "2059757080"},
  {name: "Harris, William M.D.", address: "1600 7th Avenue South,\nBirmingham, AL, 35233", npi: "1538374335", phone: "2059399584", fax: "2059399583"},
  {name: "Hays III, William M.D.", address: "2660 10th Avenue South, Suite 528 \nBirmingham, AL, 35205", npi: "1265473268", phone: "2059339258", fax: "2059336504"},
  {name: "Hill, Jimmy M.D.", address: "1940 Elmer J Bissell Road,\nBirmingham, AL, 35243", npi: "1508952631", phone: "2058244949", fax: "2059395329"},
  {name: "Hoover, Wynton M.D.", address: "1600 7th Avenue South,\nBirmingham, AL, 35233", npi: "1104020106", phone: "2059399583", fax: "2059755983"},
  {name: "Hutto, Susan M.D.", address: "1600 7th Avenue South, CHB 304 \nBirmingham, AL, 35233-1711", npi: "1518071968", phone: "2059342441", fax: "2059756549"},
  {name: "Karle, Virginia M.D.", address: "PO Box 55823,\nBirmingham, AL, 35255", npi: "1629013412", phone: "2059962244", fax: "2059962254"},
  {name: "Lozano, David M.D.", address: "1600 7th Avenue South,\nBirmingham, AL, 35233", npi: "1518915305", phone: "2059399583", fax: "2059755983"},
  {name: "Magruder, Teresa M.D.", address: "1600 7th Avenue South, ACC 620 \nBirmingham, AL, 35233", npi: "1366460404", phone: "2059395476", fax: "2059755983"},
  // CHRISTOPHER MAKRIS  FLORIDA?!?! {name: "Mena, Wahib M.D.", address: "1600 7th Avenue South, ACC Building 620 \nBirmingham, AL, 35233", npi: "1659489565", phone: "2059399583", fax: "2059755983"},
  {name: "Mena, Wahib M.D.", address: "2010 Brookwood Medical Center Drive,\nBirmingham, AL, 35209", npi: "1417932377", phone: "2058775381", fax: "2058775395"},
  {name: "Oliver, Rees M.D.", address: "1600 7th Avenue South,\nBirmingham, AL, 35233", npi: "1801830310", phone: "2059962244", fax: "2059962254"},
  {name: "Pass, Robert M.D.", address: "1600 7th Avenue South,\nBirmingham, AL, 35233", npi: "1508804063", phone: "2059399590", fax: "2051111111"},
  {name: "Philips, Joseph M.D.", address: "1700 6TH Avenue South, Suite 9380 \nBirmingham, AL, 35233", npi: "1235175662", phone: "2059344680", fax: "2059962254"},
  {name: "Randolph, David M.D.", address: "1700 6TH Avenue South, Suite 9380 \nBirmingham, AL, 35233", npi: "1114074051", phone: "2059344680", fax: "2059343100"},
  {name: "Romp, Robb M.D.", address: "UAB Hospital, 620 20th Street South \nBirmingham, AL, 35233", npi: "1194769448", phone: "2059343460", fax: "2059756291"},
  {name: "Scalici Jr., Paul M.D.", address: "1600 7th Avenue South,\nBirmingham, AL, 35233", npi: "1912115973", phone: "2059399922", fax: "2059347273"},
  {name: "Shirley, William M.D.", address: "1940 Elmer J Bissell Road,\nBirmingham, AL, 35243", npi: "1568556280", phone: "2058244949", fax: "2059395329"},
  {name: "Soltau, Thomas M.D.", address: "619 20Th Avenue South, NHB 525 \nBirmingham, AL, 35205", npi: "1275583858", phone: "2059344948", fax: "2059758991"},
  {name: "Toms, Rune M.D.", address: "619 20th Avenue South,\nBirmingham, AL, 35205", npi: "1639126006", phone: "2059344948", fax: "2059758991"},
  {name: "Troxler, Robert M.D.", address: "1600 7th Avenue South,\nBirmingham, AL, 35233", npi: "1558584540", phone: "2059399583", fax: "2059755983"},
  {name: "Walley, Susan M.D.", address: "1600 7th Avenue South, ACC 8 \nBirmingham, AL, 35233", npi: "1598874463", phone: "2059342441", fax: "2059347273"},
  {name: "Wiatrak, Brian M.D.", address: "1940 Elmer J Bissell Road,\nBirmingham, AL, 35243", npi: "1427143015", phone: "2058244949", fax: "2058244983"},
  {name: "Woolley, Audie M.D.", address: "1940 Elmer J Bissell Road,\nBirmingham, AL, 35243", npi: "1427143825", phone: "2058244949", fax: "2059395329"},
  {name: "Turner, Brett M.D.", address: "1600 7th Ave South,\nBirmingham, AL, 35233", npi: "1427376649", phone: "2059399583", fax: "2056382850"},
  {name: "Pearce, Bennett M.D.", address: "1700 6th Ave S,\nWIC Suite 9100,\nBirmingham, AL, 35249", npi: "1285670430", phone: "2059343460", fax: "2059756291"},
  {name: "Virella-Lowell, Isabel M.D.", address: "1600 7th Ave S,\nBirmingham, AL, 35233", npi: "1457469314", phone: "2059399583", fax: "2056382850"},
  {name: "Saadoon, Ammar M.D.", address: "1600 7th Ave S,\nBirmingham, AL 35233", npi: "1073885653", phone: "2059399583", fax: "2056382850"},
  {name: "Davis, Richard M.D.", address: "1600 7th Ave S,\nAcc 406\nBirmingham, AL 35233", npi: "1063503175", phone: "2056389790", fax: "2056389793"},
  {name: "Mendoza, Paola M.D.", address: "1600 7th Ave S,\nBirmingham, AL 35233", npi: "1295922425", phone: "2059399583", fax: "2059395983"},
  {name: "Swanson, Eric M.D.", address: "1600 7th Ave S,\nLowder Bldg, Suite 510\nBirmingham, AL 35294-0001", npi: "1407172661", phone: "2056389790", fax: "2056389793"},
  {name: "Brown, Jessica M.D.", address: "1600 7th Ave S,\nBIRMINGHAM, AL 35233", npi: "1821171760", phone: "2056385796", fax: "2059755983"},
  {name: "Kreth, Matthew M.D.", address: "619 19TH ST S,\nBirmingham, AL 35249", npi: "1750609939", phone: "2059345004", fax: "2059755983"},
  {name: "Berger, Stephanie M.D.", address: "1600 7th Ave S,\nBirmingham AL 35233", npi: "1013125459", phone: "2059343795", fax: "2059347273"},
  {name: "Harsanyi-Jilling, Krisztina M.D.", address: "1600 7TH Ave S,\nBirmingham, AL 35233", npi: "1922168376", phone: "2059343795", fax: "2059967867"},
  {name: "Hodges, Elizabeth M.D.", address: "3300 Cahaba Rd,\nSuite 102\nBirmingham, AL 35233", npi: "1093733461", phone: "2058707292", fax: "2056389996"},
  {name: "Kankirawatana, Suthida M.D.", address: "1600 7TH Ave S,\nCPP Ste. 210\nBirmingham, AL 35233-1711", npi: "1134239148", phone: "2056389586", fax: "2059757080"},
  {name: "Ramani, Manimaran M.D.", address: "1700 6Th Ave S,\nBirmingham, AL 35249", npi: "1497834311", phone: "2059344944", fax: "2059758991"},
  {name: "Law, Mark M.D.", address: "619 S 19th St,\n320 NHB,\nBirmingham AL 35249", npi: "1780858225", phone: "2059343460", fax: "2059756291"},
  {name: "Winter, Lindy M.D.", address: "1700 6th Ave S,\nBirmingham AL 35233", npi: "1457301582", phone: "2059341856", fax: "2059343100"},
  {name: "Gentle, Samuel M.D.", address: "1931 20th Ave S,\nBirmingham, AL 35209", npi: "1275808396", phone: "2055412247", fax: "2056389422"},
]

export var clinicianList = [
  "Alicia Cheeley, RN, CPN",
  "Allison Wooldridge, CRNP",
  "Ann Stallworth, MSN, BSN, RN",
  "April Vaughn, RN",
  "Bailee Lambert, MSW, LGSW",
  "Brandon Weems, RRT",
  "Brenda Voulgarides, RNC",
  "Cathy Mims, RN, CPN",
  "Cathy Seripin BSN, RN, C",
  "Christie Dawson, RNC",
  "Christine Hodge",
  "Cindy Deerman, LCSW",
  "Claire Lanker, LCSW",
  "Dawn Sorrell, MSN, CRNP",
  "Donna Slayton, MSN, R, N",
  "Erin Kattus, LCSW",
  "Jane Condrey, RN",
  "Janet Allen, CRT",
  "Janet Johnston, RN, CRNP, AE-C",
  "Jeanna Jackson, MS, RD",
  "Johanna Kimbrough, R.P.T.",
  "Joy Evan, RN",
  "Julie McDougal, MAE, RRT",
  "Julie Stone, BSN, RN",
  "Kathy Bains, RN, CPN",
  "Mrs. Kimberly Bell",
  "La'Kesha Cephus, Secretary",
  "LaShonna Stodghill MAE, RRT, LRT",
  "Latona Kersh, Title Unknown",
  "LaTonya Dixon, RPSGT",
  "Laura Hauguel, RN BSN",
  "Laurel Williams BSN, RN, C",
  "Lynda Williams, MSW",
  "Marilyn Branch (Office Coordinator)",
  "Molly Desai, CRNP",
  "Natalie Bahakel, RN",
  "Paige Stephenson, RNC",
  "Pamela Melton, LGSW",
  "Rhonda Smith, MSN, CRNP",
  "Robbie Taylor, CRT",
  "Samantha Floyd, MSW, LGSW",
  "Sara Love, RN",
  "Sheila Gibson, RRT, RPFT",
  "Sherry Garlington, BSN, RN",
  "Stephanie Gamble, RRT, RCP",
  "Tammie Kerr, RN",
  "Tammy Long, LSW",
  "Tarria Craig, PSGT",
  "Valerie Taylor, RN MSN",
  "Vicki Cunningham, CRNP",
  "Yulonda Wallace, RRT"
]

export var defaultPatient = {
        BrightreeID: "",
        _id: null,
        FormData: [
          {index: 0, active: true, name: "Patient Info", description: "Patient information, healthcare provider and equipment checklist",
          requiredFields: {
            physicianIndex: 0,
            physicianName: "",
            physicianAddress: "",
            physicianNPI: "",
            physicianPhone: "",
            physicianFax: "",
            patientSuffix: "",
            patientLastName: "",
            patientFirstName: "",
            patientMiddleName: "",
            patientBirth: new Date(),
            patientAge: 0,
            patientMR: "",
            patientBTID: "",
            patientPhone: "",
            patientAddress1: "",
            patientAddress2: "",
            patientCity: "",
            patientCounty: "",
            patientState: "",
            patientCountry: "",
            patientPostalCode: "",
            payorPrimary: "",
            payorSecondary: "",
            payorCertification: "Initial",
            payorLengthOfNeed: "",
            payorCertFrom: moment().subtract(1, 'month').toDate(),
            payorCertTo: moment().add(11, 'month').toDate(),
            patientPrognosis: "Fair",
            patientDiagnosis: ["","","",""],
            otherEquipment: "",
            clinician: "",
          }},
          {index: 1, active: false, name: "Ventilator Info", description: "Ventilator equipment information and supplies", requiredFields: {
            ventilatorModel: "LTV 950 Ventilator",
            ventilatorMethod: "Invasive Ventilator (E0465)",
            ventilatorUsage: "Continuously",
            comments: [],
            trilogyDual: "No",
            trilogyMode: "",
            trilogyRate: "",
            trilogyVTPC: "",
            trilogyAVAPS: "No",
            trilogyIPAP: "",
            trilogyIPAPEPAP: "",
            trilogyITime: "",
            trilogyPressureSupport: "",
            trilogyPEEP: "",
            trilogyFlowAuto: "",
            trilogyRiseTime: "",
            trilogySensitivity: "",
            trilogySigh: "",
            trilogyInspPressure: "",
            trilogyFlowTrigger: "",
            trilogyFlowCycle: "",
            trilogyFlex: "No",
            trilogyRamp: "No",
            trilogyApnea: "Off",
            trilogyApneaRate: "",
            trilogyDisconnect: "",
            trilogyAlarmVol: "",
            trilogyHiVT: "",
            trilogyLoVT: "",
            trilogyHiVE: "",
            trilogyLoVE: "",
            trilogyHiRR: "",
            trilogyLoRR: "",
            LVTBRate: "",
            LVTTidalVol: "",
            LVTPressCont: "",
            LVTInsTime: "",
            LVTPressSupport: "",
            LVTSens: "",
            LVTPEEP: "",
            LVTVolPressure: "",
            LVTAsstSIMV: "",
            LVTNPPV: "Off",
            LVTInsp: "Off",
            LVTCtrlLock: "Off",
            LVTHiPressure: "",
            LVTLoPressure: "",
            LVTLoMinVol: "",
            LVTAIVol: "",
            LVTApneaInt: "",
            LVTHPDelay: "",
            LVTLPPAlarm: "",
            LVTHiF: "",
            LVTHiPEEP: "",
            LVTLoPEEP: "",
            LVTPNTAssist: "",
            LVTRiseTime: "",
            LVTFlowTerm: "",
            LVTTimeTerm: "",
            LVTPCFlowTerm: "",
            LVTLeakComp: "",
            LVTNPPVMode: "",
            LVTCtrlUnlock: ""
          }},
          {index: 2, active: false, name: "Tracheostomy Info", description: "Tracheostomy equipment information and supplies", requiredFields: {
            heatedConfig: false,
            useMepilex: false,
            meplexQuantity: 0,
            comments: [],
            catheterSize: "",
            useSecondary: false,
            primaryTubeType: 0,
            secondaryTubeType: 0,
            bivonaTubes: 0,
            orderNumberPrimary: "",
            orderNumberSecondary: "",
            tubes: [
              {
                //orderNumber: "",
                application: "",
                cuff: "",
                fenestration: "",
                specialLength: "",
                size: "",
                flange: "",
                cannula: "",
                distal: "",
                model: ""
              },
              {
                //orderNumber: "",
                application: "",
                cuff: "",
                fenestration: "",
                specialLength: "",
                size: "",
                flange: "",
                cannula: "",
                distal: "",
                model: ""
              }
            ]
          }},
          {index: 3, active: false, name: "Suction Machine Info", description: "Suction Machine equipment information and supplies", requiredFields: {
            mobility: "Independently powered by internal battery",
            catheterSize: "",
            justifications: {
              "Throat Cancer or Surgery": false,
              "Swallowing Muscle Paralysis": false,
              "Tracheostomy": false,
              "Semi or Fully Comatose Condition": false,
              "Other": ""
            },
            comments: []
          }},
          {index: 4, active: false, name: "Nebulizer Info", description: "Nebulizer equipment information and supplies", requiredFields: {
            justifications: {
              "Cystic Fibrosis": false,
              "Bronchiectasis": false,
              "Reactive Airway Disease": false,
              "Bronchospasm": false,
              "Asthma": false,
              "Other": ""
            },
            comments: []
          }},
          {index: 5, active: false, name: "Enteral Info", description: "Enteral equipment information and supplies", requiredFields: {
            nutritionEquipment: "Pump",
            methodOfAdministration: "Abdominal (G-Tube - B4088)",
            formula: "Breast Milk",
            syringeInfoCC: 0,
            syringeInfoTimes: 0,
            pumpInfoCC: 0,
            pumpInfoTimes: 0,
            calorieNeeds: 0,
            totalThroughEnteral: 0,
            enteralCalorieIntake: 0,
            comments: []
          }},
          {index: 6, active: false, name: "Apnea Monitor Info", description: "Apnea Monitor equipment information and supplies", requiredFields: {
            apneaUsage: "Continuously",
            hiAlarm: 130,
            loAlarm: 50,
            delay: 0,
            comments: ["Test Comment","Test Comment","Test Comment"]
          }},
          {index: 7, active: false, name: "Oxygen Info", description: "Oxygen equipment information and supplies", requiredFields: {
            oxygenMain: true,
            oxygenLitreFlow: "",
            oxygenMethod: "",
            oxygenUsage: "Continuously",
            oxygenDatePrescribed: new Date(),
            oxygenLastSeen: new Date(),
            oxygenPortable: true,
            oxygenProjections: [{reason: "1233", visitType: "", companyName: "", address: "", specialty: "", doctor: "", location: "334", mileage: 0, transportDuration: 0, nonTransportDuration: 0, delayDuration: 0, occurances: 0, subTotal: 0}],
            oxygenCylinderType: "",
            oxygenCylinderQuantity: 0,
            oxygenTotal: 0,
            oxygen360: true,
            oxygenEval: true,
            oxygenInitial: true,
            comment: "The above diagnosis/medical condition listed above is a chronic debilitating medical condition. Alternative forms of treatment have been tried and were unsuccessful, therefore continuous oxygen therapy is required.",
            evalComments: "",
            oxygenClinician: "",
            oxygenDiagnosis: "",
            oxygenCaregiver: "",
            contactInfo: [{phone: "", changes: ""}],
            appointments: [{known: "", additional: ""}],
            evalLastSeen: new Date(),
            evalLastSleep: new Date(),
            oxygenVentDep: false,
            oxygenDateABG: new Date(),
            oxygenABGType: {
              "Room Air": false,
              "Oxygen": false,
              "Asleep": false
            },
            oxygenDateSaturation: new Date(),
            oxygenSaturationType: {
              "Room Air": false,
              "Oxygen": false,
              "Asleep": false
            }
          }},
          {index: 8, active: false, name: "Oximeter/Probes Info", description: "Oximeter equipment information and supplies", requiredFields: {
            spO2HiAlarm: 100,
            spO2LoAlarm: 90,
            heartRateHiAlarm: 115,
            heartRateLoAlarm: 45,
            oximeterUsage: "Continuously",
            effectiveDates1: "",
            effectiveDates2: "",
            effectiveDates3: "",
            comments: []
          }},
          {index: 9, active: false, name: "Cough Assist Info", description: "Cough Assist equipment information and supplies", requiredFields: {
            inspDuration: 0,
            inspPressure: 0,
            expDuration: 0,
            expPressure: 0,
            pauseDuration: 0,
            cycleBreathCount: 4,
            cycleQuantity: 4,
            usageFrequency: "Every 12 Hours as Needed for Increased Secretions/Chest Congestion.",
            justifications: {
              "Cystic Fibrosis": false,
              "Chronic Inspiratory Muscle Failure": false,
              "Spinal Chord Injury": false,
              "Neuromuscular Disease": false,
              "Secretion Retention": false,
              "Other": ""
            },
            comments: []
          }},
          {index: 10, active: false, name: "CPAP Info", description: "CPAP equipment information and supplies", requiredFields: {
            cpapUsage: "Continuously",
            cpapPressure: 0,
            cpapMaskStyle: "Nasal",
            cpapMaskSize: "Medium",
            cpapRampDuration: 5,
            cpapBleedInOxygen: "No",
            cpapRequiresHumidity: false,
            cpapRequiresHumidityConfig: false,
            comments: []
          }},
          {index: 11, active: false, name: "BIPAP Info", description: "BIPAP equipment information and supplies", requiredFields: {
            bipapType: "BiPaP S (E0470)",
            bipapQualifyingDx: "Upper Airway Resistance Syndrome",
            bipapUsage: "Continuously",
            bipapInspSetting: "",
            bipapExpSetting: "",
            bipapMaskStyle: "Nasal",
            bipapMaskSize: "Medium",
            bipapRampDuration: 5,
            bipapBleedInOxygen: "No",
            bipapRequiresHumidity: false,
            bipapRequiresHumidityConfig: false,
            bipapBackupRate: "",
            comments: []
          }},
          {index: 12, active: false, name: "Other Info", description: "Other equipment information and supplies", requiredFields: {
            comment: ""
          }},
        ]
      }

export var validator = [
  {
    physicianName: {regex: "^(?!\s*$).+", message: "Physician Name"},
    physicianAddress: {regex: "^(?!\s*$).+", message: "Physician Address"},
    physicianNPI: {regex: "^(?!\s*$).+", message: "Physician NPI"},
    physicianPhone: {regex: "^(?!\s*$).+", message: "Physician Phone"},
    patientSuffix: {regex: "^(?!\s*$).+", message: "Suffix"},
    patientLastName: {regex: "^(?!\s*$).+", message: "Last Name"},
    patientFirstName: {regex: "^(?!\s*$).+", message: "First Name"},
    patientMiddleName: {regex: "^(?!\s*$).+", message: "Middle Name"},
    patientBirth: {regex: "^(?!\s*$).+", message: "Birth"},
    patientMR: {regex: "^(?!\s*$).+", message: "MR"},
    patientBTID: {regex: "^(?!\s*$).+", message: "BTID"},
    patientPhone: {regex: "^(?!\s*$).+", message: "Phone"},
    patientAddress1: {regex: "^(?!\s*$).+", message: "Address"},
    patientCity: {regex: "^(?!\s*$).+", message: "City"},
    patientCounty: {regex: "^(?!\s*$).+", message: "County"},
    patientState: {regex: "^(?!\s*$).+", message: "State"},
    patientCountry: {regex: "^(?!\s*$).+", message: "County"},
    patientPostalCode: {regex: "^(?!\s*$).+", message: "Postal Code"},
    payorPrimary: {regex: "^(?!\s*$).+", message: "Primary Payor"},
    payorCertification: {regex: "^(?!\s*$).+", message: "Cert"},
    payorLengthOfNeed: {regex: "^(?!\s*$).+", message: "Length of Need"},
    payorCertFrom: {regex: "^(?!\s*$).+", message: "Cert From"},
    payorCertTo: {regex: "^(?!\s*$).+", message: "Cert To"},
  },
  {
    ventilatorModel: {regex: "^(?!\s*$).+", message: "Model"},
    ventilatorMethod: {regex: "^(?!\s*$).+", message: "Method"},
    ventilatorUsage: {regex: "^(?!\s*$).+", message: "Usage"},
    trilogyDual: {regex: "^(true|false)", message: "Dual Prescription", orCondition: ["ventilatorModel", "T100 Ventilator"]},
    trilogyMode: {regex: "^(?!\s*$).+", message: "Mode", orCondition: ["ventilatorModel", "T100 Ventilator"]},
    trilogyRate: {regex: "^(?!\s*$).+", message: "Rate", orCondition: ["ventilatorModel", "T100 Ventilator"]},
    trilogyVTPC: {regex: "^(?!\s*$).+", message: "VT/PC", orCondition: ["ventilatorModel", "T100 Ventilator"]},
    trilogyAVAPS: {regex: "^(true|false)", message: "AVAPS", orCondition: ["ventilatorModel", "T100 Ventilator"]},
    trilogyIPAP: {regex: "^(?!\s*$).+", message: "IPAP", orCondition: ["ventilatorModel", "T100 Ventilator"]},
    trilogyIPAPEPAP: {regex: "^(?!\s*$).+", message: "IPAP/EPAP", orCondition: ["ventilatorModel", "T100 Ventilator"]},
    trilogyITime: {regex: "^(?!\s*$).+", message: "I Time", orCondition: ["ventilatorModel", "T100 Ventilator"]},
    trilogyPressureSupport: {regex: "^(?!\s*$).+", message: "Pressure Support", orCondition: ["ventilatorModel", "T100 Ventilator"]},
    trilogyPEEP: {regex: "^(?!\s*$).+", message: "PEEP", orCondition: ["ventilatorModel", "T100 Ventilator"]},
    trilogyFlowAuto: {regex: "^(?!\s*$).+", message: "Flow/Auto", orCondition: ["ventilatorModel", "T100 Ventilator"]},
    trilogyRiseTime: {regex: "^(?!\s*$).+", message: "Rise Time", orCondition: ["ventilatorModel", "T100 Ventilator"]},
    trilogySensitivity: {regex: "^(?!\s*$).+", message: "Sensitivity", orCondition: ["ventilatorModel", "T100 Ventilator"]},
    trilogySigh: {regex: "^(?!\s*$).+", message: "Sigh", orCondition: ["ventilatorModel", "T100 Ventilator"]},
    trilogyInspPressure: {regex: "^(?!\s*$).+", message: "Insp Pressure", orCondition: ["ventilatorModel", "T100 Ventilator"]},
    trilogyFlowTrigger: {regex: "^(?!\s*$).+", message: "Flow Trigger", orCondition: ["ventilatorModel", "T100 Ventilator"]},
    trilogyFlowCycle: {regex: "^(?!\s*$).+", message: "Flow Cycle", orCondition: ["ventilatorModel", "T100 Ventilator"]},
    trilogyFlex: {regex: "^(?!\s*$).+", message: "Flex", orCondition: ["ventilatorModel", "T100 Ventilator"]},
    trilogyRamp: {regex: "^(?!\s*$).+", message: "Ramp", orCondition: ["ventilatorModel", "T100 Ventilator"]},
    trilogyApnea: {regex: "^(?!\s*$).+", message: "Apnea", orCondition: ["ventilatorModel", "T100 Ventilator"]},
    trilogyApneaRate: {regex: "^(?!\s*$).+", message: "Apnea Rate", orCondition: ["ventilatorModel", "T100 Ventilator"]},
    trilogyDisconnect: {regex: "^(?!\s*$).+", message: "Disconnect", orCondition: ["ventilatorModel", "T100 Ventilator"]},
    trilogyAlarmVol: {regex: "^(?!\s*$).+", message: "Alarm Vol", orCondition: ["ventilatorModel", "T100 Ventilator"]},
    trilogyHiVT: {regex: "^(?!\s*$).+", message: "Hi VT", orCondition: ["ventilatorModel", "T100 Ventilator"]},
    trilogyLoVT: {regex: "^(?!\s*$).+", message: "Lo VT", orCondition: ["ventilatorModel", "T100 Ventilator"]},
    trilogyHiVE: {regex: "^(?!\s*$).+", message: "Hi VE", orCondition: ["ventilatorModel", "T100 Ventilator"]},
    trilogyLoVE: {regex: "^(?!\s*$).+", message: "Lo VE", orCondition: ["ventilatorModel", "T100 Ventilator"]},
    trilogyHiRR: {regex: "^(?!\s*$).+", message: "Hi RR", orCondition: ["ventilatorModel", "T100 Ventilator"]},
    trilogyLoRR: {regex: "^(?!\s*$).+", message: "Lo RR", orCondition: ["ventilatorModel", "T100 Ventilator"]},
    LVTBRate: {regex: "^(?!\s*$).+", message: "B Rate", orCondition: ["ventilatorModel", "LTV 950 Ventilator", "ventilatorModel", "LTV 1150 Ventilator"]},
    LVTTidalVol: {regex: "^(?!\s*$).+", message: "Tidal Vol", orCondition: ["ventilatorModel", "LTV 950 Ventilator", "ventilatorModel", "LTV 1150 Ventilator"]},
    LVTPressCont: {regex: "^(?!\s*$).+", message: "Press Cont", orCondition: ["ventilatorModel", "LTV 950 Ventilator", "ventilatorModel", "LTV 1150 Ventilator"]},
    LVTInsTime: {regex: "^(?!\s*$).+", message: "Ins Time", orCondition: ["ventilatorModel", "LTV 950 Ventilator", "ventilatorModel", "LTV 1150 Ventilator"]},
    LVTPressSupport: {regex: "^(?!\s*$).+", message: "Press Support", orCondition: ["ventilatorModel", "LTV 950 Ventilator", "ventilatorModel", "LTV 1150 Ventilator"]},
    LVTSens: {regex: "^(?!\s*$).+", message: "Sens", orCondition: ["ventilatorModel", "LTV 950 Ventilator", "ventilatorModel", "LTV 1150 Ventilator"]},
    LVTPEEP: {regex: "^(?!\s*$).+", message: "PEEP", orCondition: ["ventilatorModel", "LTV 950 Ventilator", "ventilatorModel", "LTV 1150 Ventilator"]},
    LVTVolPressure: {regex: "^(?!\s*$).+", message: "Vol Pressure", orCondition: ["ventilatorModel", "LTV 950 Ventilator", "ventilatorModel", "LTV 1150 Ventilator"]},
    LVTAsstSIMV: {regex: "^(?!\s*$).+", message: "Assist/SIMV", orCondition: ["ventilatorModel", "LTV 950 Ventilator", "ventilatorModel", "LTV 1150 Ventilator"]},
    LVTNPPV: {regex: "^(true|false)", message: "NPPV", orCondition: ["ventilatorModel", "LTV 950 Ventilator", "ventilatorModel", "LTV 1150 Ventilator"]},
    LVTInsp: {regex: "^(true|false)", message: "Insp", orCondition: ["ventilatorModel", "LTV 950 Ventilator", "ventilatorModel", "LTV 1150 Ventilator"]},
    LVTCtrlLock: {regex: "^(true|false)", message: "Ctrl Lock", orCondition: ["ventilatorModel", "LTV 950 Ventilator", "ventilatorModel", "LTV 1150 Ventilator"]},
    LVTHiPressure: {regex: "^(?!\s*$).+", message: "Hi Pressure", orCondition: ["ventilatorModel", "LTV 950 Ventilator", "ventilatorModel", "LTV 1150 Ventilator"]},
    LVTLoPressure: {regex: "^(?!\s*$).+", message: "Lo Pressure", orCondition: ["ventilatorModel", "LTV 950 Ventilator", "ventilatorModel", "LTV 1150 Ventilator"]},
    LVTLoMinVol: {regex: "^(?!\s*$).+", message: "Lo Min Vol", orCondition: ["ventilatorModel", "LTV 950 Ventilator", "ventilatorModel", "LTV 1150 Ventilator"]},
    LVTAIVol: {regex: "^(?!\s*$).+", message: "AI Vol", orCondition: ["ventilatorModel", "LTV 950 Ventilator", "ventilatorModel", "LTV 1150 Ventilator"]},
    LVTApneaInt: {regex: "^(?!\s*$).+", message: "Apnea Int", orCondition: ["ventilatorModel", "LTV 950 Ventilator", "ventilatorModel", "LTV 1150 Ventilator"]},
    LVTHPDelay: {regex: "^(?!\s*$).+", message: "HP Delay", orCondition: ["ventilatorModel", "LTV 950 Ventilator", "ventilatorModel", "LTV 1150 Ventilator"]},
    LVTLPPAlarm: {regex: "^(?!\s*$).+", message: "LPP Alarm", orCondition: ["ventilatorModel", "LTV 950 Ventilator", "ventilatorModel", "LTV 1150 Ventilator"]},
    LVTHiF: {regex: "^(?!\s*$).+", message: "Hi F", orCondition: ["ventilatorModel", "LTV 950 Ventilator", "ventilatorModel", "LTV 1150 Ventilator"]},
    LVTHiPEEP: {regex: "^(?!\s*$).+", message: "Hi PEEP", orCondition: ["ventilatorModel", "LTV 950 Ventilator", "ventilatorModel", "LTV 1150 Ventilator"]},
    LVTLoPEEP: {regex: "^(?!\s*$).+", message: "Lo PEEP", orCondition: ["ventilatorModel", "LTV 950 Ventilator", "ventilatorModel", "LTV 1150 Ventilator"]},
    LVTPNTAssist: {regex: "^(?!\s*$).+", message: "PNT Assist", orCondition: ["ventilatorModel", "LTV 950 Ventilator", "ventilatorModel", "LTV 1150 Ventilator"]},
    LVTRiseTime: {regex: "^(?!\s*$).+", message: "Rise Time", orCondition: ["ventilatorModel", "LTV 950 Ventilator", "ventilatorModel", "LTV 1150 Ventilator"]},
    LVTFlowTerm: {regex: "^(?!\s*$).+", message: "Flow Term", orCondition: ["ventilatorModel", "LTV 950 Ventilator", "ventilatorModel", "LTV 1150 Ventilator"]},
    LVTTimeTerm: {regex: "^(?!\s*$).+", message: "Time Term", orCondition: ["ventilatorModel", "LTV 950 Ventilator", "ventilatorModel", "LTV 1150 Ventilator"]},
    LVTPCFlowTerm: {regex: "^(?!\s*$).+", message: "PC Flow Term", orCondition: ["ventilatorModel", "LTV 950 Ventilator", "ventilatorModel", "LTV 1150 Ventilator"]},
    LVTLeakComp: {regex: "^(?!\s*$).+", message: "Leak Comp", orCondition: ["ventilatorModel", "LTV 950 Ventilator", "ventilatorModel", "LTV 1150 Ventilator"]},
    LVTNPPVMode: {regex: "^(?!\s*$).+", message: "NPPV Mode", orCondition: ["ventilatorModel", "LTV 950 Ventilator", "ventilatorModel", "LTV 1150 Ventilator"]},
    LVTCtrlUnlock: {regex: "^(?!\s*$).+", message: "Ctrl Unlock", orCondition: ["ventilatorModel", "LTV 950 Ventilator", "ventilatorModel", "LTV 1150 Ventilator"]}
  },
  {
    heatedConfig: {regex: "^(true|false)", message: "Heated Config"},
    useMepilex: {regex: "^(true|false)", message: "Use Mepilex"},
    meplexQuantity: {regex: "^[1-9][0-9]*", message: "Mepilex Quantity"},
    catheterSize: {regex: "^(?!\s*$).+", message: "Catheter Size"},
    useSecondary: {regex: "^(true|false)", message: "Use Secondary"},
    primaryTubeType: {regex: "^[1-9][0-9]*", message: "Primary Tube"},
    secondaryTubeType: {regex: "^[1-9][0-9]*", message: "Secondary Tube"},
    orderNumberPrimary: {regex: "^(?!\s*$).+", message: "Primary Model"},
    orderNumberSecondary: {regex: "^(?!\s*$).+", message: "Secondary Model", orCondition: ["useSecondary", "true"]},
  },
  {
    mobility: {regex: "^(?!\s*$).+", message: "Mobility"},
    catheterSize: {regex: "^(?!\s*$).+", message: "Catheter Size"}
  },
  {
  },
  {
    syringeInfoCC: {regex: "^(?!\s*$).+", message: "Syringe CC", orCondition: ["nutritionEquipment", "Syringe", "nutritionEquipment", "Pump and Syringe"]},
    syringeInfoTimes: {regex: "^(?!\s*$).+", message: "Syringe Times", orCondition: ["nutritionEquipment", "Syringe", "nutritionEquipment", "Pump and Syringe"]},
    pumpInfoCC: {regex: "^(?!\s*$).+", message: "Pump CC", orCondition: ["nutritionEquipment", "Pump", "nutritionEquipment", "Pump and Syringe"]},
    pumpInfoTimes: {regex: "^(?!\s*$).+", message: "Pump Times", orCondition: ["nutritionEquipment", "Pump", "nutritionEquipment", "Pump and Syringe"]},
    calorieNeeds: {regex: "^(?!\s*$).+", message: "Calorie Needs"},
    totalThroughEnteral: {regex: "^(?!\s*$).+", message: "Total Through Enteral"},
    enteralCalorieIntake: {regex: "^(?!\s*$).+", message: "Calorie Intake"}
  },
  {
    delay: {regex: "^(?!\s*$).+", message: "Delay"}
  },
  {
    oxygenLitreFlow: {regex: "^(?!\s*$).+", message: "Litre Flow"},
    oxygenMethod: {regex: "^(?!\s*$).+", message: "Method"},
    oxygenUsage: {regex: "^(?!\s*$).+", message: "Usage"},
    oxygenDatePrescribed: {regex: "^(?!\s*$).+", message: "Date Prescribed"},
    oxygenLastSeen: {regex: "^(?!\s*$).+", message: "Last Seen"},
    evalComments: {regex: "^(?!\s*$).+", message: "Mobility"},
    oxygenClinician: {regex: "^(?!\s*$).+", message: "Mobility"},
    oxygenCaregiver: {regex: "^(?!\s*$).+", message: "Mobility"},
    oxygenDiagnosis: {regex: "^(?!\s*$).+", message: "Mobility"},
    evalLastSeen: {regex: "^(?!\s*$).+", message: "Last Seen"},
    evalLastSleep: {regex: "^(?!\s*$).+", message: "Last Sleep"},
  },
  {
    effectiveDates1: {regex: "^(?!\s*$).+", message: "Effective Dates I"},
    effectiveDates2: {regex: "^(?!\s*$).+", message: "Effective Dates II"},
    effectiveDates3: {regex: "^(?!\s*$).+", message: "Effective Dates III"}
  },
  {
    inspDuration: {regex: "^(?!\s*$).+", message: "Inspiratory Duration"},
    inspPressure: {regex: "^(?!\s*$).+", message: "Inspiratory Pressure"},
    expDuration: {regex: "^(?!\s*$).+", message: "Expiratory Duration"},
    expPressure: {regex: "^(?!\s*$).+", message: "Expiratory Pressure"},
    pauseDuration: {regex: "^(?!\s*$).+", message: "Pause Duration"}
  },
  {
    cpapPressure: {regex: "^(?!\s*$).+", message: "Pressure"}
  },
  {
    bipapInspSetting: {regex: "^(?!\s*$).+", message: "Inspiratory Setting"},
    bipapExpSetting: {regex: "^(?!\s*$).+", message: "Expiratory Setting"},
    bipapBackupRate: {regex: "^(?!\s*$).+", message: "Backup Rate", orCondition: ["bipapType", "BiPaP S/T (E0471)"]}
  }

]
