import React, {useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
import moment from "moment"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

const columns = [
  { id: 'firstName', align: 'left', label: 'First Name', minWidth: 170 },
  { id: 'lastName', align: 'left', label: 'Last Name', minWidth: 170 },
  { id: 'created', align: 'left', label: 'Created', format: (value) => moment(value).format("MM/DD/YY"), minWidth: 170 },
  { id: 'company', align: 'left', label: 'Company', minWidth: 100 },
  {
    id: 'inviteId',
    label: 'Invite ID',
    minWidth: 170,
    align: 'left',
  },
  { id: 'createNew', align: 'right', label: 'Create New'}
];

function AdminDashboard(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [list, setList] = React.useState([]);
  const [state, setState] = React.useState({
    username: "",
    password: "",
    errored: false,
    editIndex: -1
  })

  useEffect(() => {
    async function getListings(){
      var users = await props.fetchUsers()
      setList(users.users)
    }
    getListings()
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const tryCreateUser = async () => {
    var res = await fetch('https://api.cmnsolutions.com/createUser', {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: props.username,
        token: props.token,
        firstName: state.firstName,
        lastName: state.lastName,
        email: state.email,
        company: state.company,
        address: state.address,
        btid: state.btid,
      })
  })
  var data = await res.json();
  if(data.status){
    var users = await props.fetchUsers()
    setList(users.users)
  }
  }

const trySubmitEdit = async () => {
	console.log("Submit");

}

  const editUser = (e) => {
    console.log(e);
    setState({...state, editIndex: e});
  }

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  }

  const handleChangeEdit = (e) => {
	var edit = state.editIndex;
	edit[e.target.name] = e.target.value;
	setState({...state, editIndex: edit})
}

  return (
      <div className="formBack" style={{padding: "20px", height: "calc(100vh - 154px)"}}>
      {state.editIndex == -1 ?
    <Paper style={{padding: "10px"}}>
      <h1>Medical Resources Inc.</h1>
      <p></p>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <TextField fullWidth margin="normal" id="standard-basic" variant="outlined" label="First Name" onChange={handleChange} value={state.firstName} name="firstName" />
        </Grid>
        <Grid item xs={3}>
          <TextField fullWidth margin="normal" id="standard-basic" variant="outlined" label="Last Name" onChange={handleChange} value={state.lastName} name="lastName" />
        </Grid>
          <Grid item xs={3}>
          <TextField fullWidth margin="normal" id="standard-basic" variant="outlined" label="Email" onChange={handleChange} value={state.email} name="email" />
        </Grid>
          <Grid item xs={3}>
          <TextField fullWidth margin="normal" id="standard-basic" variant="outlined" label="Company" onChange={handleChange} value={state.company} name="company" />
        </Grid>
          <Grid item xs={3}>
          <TextField fullWidth margin="normal" id="standard-basic" variant="outlined" label="Address" onChange={handleChange} value={state.address} name="address" />
        </Grid>
          <Grid item xs={3}>
          <TextField fullWidth margin="normal" id="standard-basic" variant="outlined" label="BTID" onChange={handleChange} value={state.btid} name="BrightreeID" />
        </Grid>
      </Grid>
      <Button size="small" variant="contained" color="primary" onClick={tryCreateUser}>Create New</Button>
      <br/>
      <div style={{position: "absolute", right: "10px", bottom: "7px"}}>

      </div>
      </Paper>
       :
    <Paper style={{padding: "10px"}}>
      <h1>Medical Resources Inc.</h1>
      <p></p>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <TextField fullWidth margin="normal" id="standard-basic" variant="outlined" label="First Name" onChange={handleChangeEdit} value={state.editIndex.firstName} name="firstName" />
        </Grid>
        <Grid item xs={3}>
          <TextField fullWidth margin="normal" id="standard-basic" variant="outlined" label="Last Name" onChange={handleChangeEdit} value={state.editIndex.lastName} name="lastName" />
        </Grid>
          <Grid item xs={3}>
          <TextField fullWidth margin="normal" id="standard-basic" variant="outlined" label="Email" onChange={handleChangeEdit} value={state.editIndex.email} name="email" />
        </Grid>
          <Grid item xs={3}>
          <TextField fullWidth margin="normal" id="standard-basic" variant="outlined" label="Company" onChange={handleChangeEdit} value={state.editIndex.company} name="company" />
        </Grid>
          <Grid item xs={3}>
          <TextField fullWidth margin="normal" id="standard-basic" variant="outlined" label="Address" onChange={handleChangeEdit} value={state.editIndex.address} name="address" />
        </Grid>
          <Grid item xs={3}>
          <TextField fullWidth margin="normal" id="standard-basic" variant="outlined" label="BTID" onChange={handleChangeEdit} value={state.editIndex.btid} name="BrightreeID" />
        </Grid>
      </Grid>
      <Button size="small" variant="contained" color="primary" onClick={trySubmitEdit}>Save</Button>
      <br/>
      <div style={{position: "absolute", right: "10px", bottom: "7px"}}>

      </div>
      </Paper>}
      <br/>
      <Paper>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell
                key={"density"}
                align='right'
                style={{ minWidth: 200 }}
              >
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                  <TableCell key={5} align="right">
                    <Button size="small" variant="contained" color="primary" onClick={() => editUser(row)}>Edit</Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={Math.floor(list.length/rowsPerPage)}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      </Paper>
      </div>
  );
}

export default AdminDashboard;
