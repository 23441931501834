import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import './App.css';

function About() {


    return (
      <div style={{
        backgroundImage: 'url("https://images.pexels.com/photos/40568/medical-appointment-doctor-healthcare-40568.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260")',
        backgroundSize: "cover", width: "100%", textAlign: "center", position: "fixed", height: "calc(100vh - 114px)", overflow: "auto"}}>
        <div style={{background: "", textAlign: "left", padding: "20px 10%", backgroundImage: 'url("https://mdrtest.nirakara.co.uk/doctor.jpg")', backgroundSize: "cover", backgroundPosition: "center"}}>
          <h2 style={{fontSize: "42px"}}><span className="blur">Dedicated. </span><span className="blur">Precise.</span></h2>
          <h4 style={{fontSize: "28px", fontWeight: "300", width: "50%"}} className="blurText">We are an accredited group of knowledgeable professionals dedicated to provision of prompt, accurate medical equipment and supply in an ethical and caring manner.</h4>
          <br/><br/><br/><br/><br/>
        </div>

        <div style={{background: "white", textAlign: "center", padding: "20px 10%"}}>
          <h3 style={{fontSize: "36px", margin: "10px"}}>About Us</h3>
          <p style={{fontSize: "28px", fontWeight: "300"}}>Our respiratory therapists and other on-call personnel remain available at all times (days, nights, weekends, and holidays) for troubleshooting equipment malfunctions.</p>
          <p style={{fontSize: "28px", fontWeight: "300"}}>Physician Prescription is usually required, and accepting assignment from Medicaid, Insurance, and private payment are all common and acceptable methods of payment for our services.</p>
        </div>

        <svg width="500" height="80" viewBox="0 0 500 80" preserveAspectRatio="none">
          <path d="M0,0 L0,40 Q250,80 500,40 L500,0 Z" fill="white" />
        </svg>

        <div style={{background: "", textAlign: "center", padding: "20px 10%"}}>
          <h3 style={{fontSize: "36px"}}>Our Services</h3>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}><Card className="equipInfo" style={{height: "200px"}}>
            <h4>Invasive/Non-Invasive Ventilation</h4>
            <p style={{textAlign: "left"}}>Invasive and Non-Invasive mechanical pediatric home ventilation program</p>
            <img style={{width: "50%", position: "absolute", right: "-10%", bottom: "-35%"}} src="https://mdrtest.nirakara.co.uk/ventilator.png"/>
            </Card></Grid>
            <Grid item md={3} xs={12}><Card className="equipInfo" style={{height: "200px", }}>
            <h4>Tracheostomy Tubes and supplies</h4>
            <p style={{textAlign: "left"}}>Tracheostomy tubes, supplies, humidification, suction equipment</p>
            <img style={{width: "50%", position: "absolute", right: "-10%", bottom: "-10%"}} src="https://mdrtest.nirakara.co.uk/trach.jpg"/>
            </Card></Grid>
            <Grid item md={3} xs={12}><Card className="equipInfo" style={{height: "200px"}}>
            <h4>Oxygen Therapy</h4>
            <p style={{textAlign: "left"}}>Stationary / portable oxygen concentrators and compressed oxygen gas cylinders</p>
            <img style={{width: "50%", position: "absolute", right: "-10%", bottom: "-10%"}} src="https://mdrtest.nirakara.co.uk/oxygen.jpg"/>
            </Card></Grid>
            <Grid item md={3} xs={12}><Card className="equipInfo" style={{height: "200px"}}>
            <h4>Pulse Oximetry</h4>
            <p style={{textAlign: "left"}}>Oximeter monitors and probes for monitoring oxygen saturations</p>
            <img style={{width: "50%", position: "absolute", right: "-10%", bottom: "-10%"}} src="https://mdrtest.nirakara.co.uk/oximeter.jpg"/>
            </Card></Grid>
            <Grid item md={3} xs={12}><Card className="equipInfo" style={{height: "200px"}}>
            <h4>Apnea Monitors</h4>
            <p style={{textAlign: "left"}}>Apnea and heart rate monitor for early detection of life-threating events</p>
            <img style={{width: "50%", position: "absolute", right: "-10%", bottom: "-0%"}} src="https://mdrtest.nirakara.co.uk/apnea.png"/>
            </Card></Grid>
            <Grid item md={3} xs={12}><Card className="equipInfo" style={{height: "200px"}}>
            <h4>Cough Assist Devices</h4>
            <p style={{textAlign: "left"}}>Cough Assist Devices for assistance with respiratory conditions</p>
            <img style={{width: "50%", position: "absolute", right: "-10%", bottom: "-10%"}} src="https://mdrtest.nirakara.co.uk/nebulizer.jpg"/>
            </Card></Grid>
            <Grid item md={3} xs={12}><Card className="equipInfo" style={{height: "200px"}}>
            <h4>CPAP Devices</h4>
            <p style={{textAlign: "left"}}>Continuous Positive Airway Pressure devices for sleep apnea therapy</p>
            <img style={{width: "65%", position: "absolute", right: "-10%", bottom: "0%"}} src="https://mdrtest.nirakara.co.uk/cipap.jpg"/>
            </Card></Grid>
            <Grid item md={3} xs={12}><Card className="equipInfo" style={{height: "200px"}}>
            <h4>BiPAP Devices</h4>
            <p style={{textAlign: "left"}}>Bilevel Positive Airway Pressure devices for advanced ventilation</p>
            <img style={{width: "50%", position: "absolute", right: "-10%", bottom: "-10%"}} src="https://mdrtest.nirakara.co.uk/bipap.jpg"/>
            </Card></Grid>
            <Grid item md={6} xs={12}><Card className="equipInfo" style={{height: "300px"}}>
            <h4>Registered Respiratory Therapists</h4>
            <p style={{textAlign: "left"}}>Skilled RRTs specilizing in pediatric respiratory.</p>
            </Card></Grid>
            <Grid item md={6} xs={12}><Card className="equipInfo" style={{height: "300px"}}>
            <h4>24/7/365 On-Call Staff</h4>
            <p style={{textAlign: "left"}}>+1 (866) 521-7337 </p>
            </Card></Grid>
          </Grid>
        </div>

      </div>
    );

}

export default About;
